import React from 'react';
import { message, Switch } from 'antd';
import { setBankAccountInEnabled } from 'modules/bankAccounts/store';
import { BankAccount } from 'modules/bankAccounts/types/bankAccount.interface';
import { useAppDispatch } from 'hooks/useAppDispatch.hook';
import { useUserPermissions } from 'policies/hooks/useUserPermissions';
import { permissions } from 'policies/permissions';

interface Props {
  account: BankAccount;
}

export const BankAccountsListPageInEnabledColumn: React.FC<Props> = ({ account }) => {
  const dispatch = useAppDispatch();

  const { userCan } = useUserPermissions();

  async function handleInEnabledAccountChange(account: BankAccount, currentStatus: boolean) {
    try {
      await dispatch(
        setBankAccountInEnabled({
          bankAccountId: account.bankAccountId,
          inEnabled: currentStatus,
        })
      );

      message.success({
        content: 'Входящие платежи успешно изменены!',
        duration: 1.5,
      });
    } catch {
      message.error('Ошибка изменения входящих платежей!');
    }
  }

  return (
    <Switch
      checked={account.inEnabled}
      onChange={(currentStatus) => handleInEnabledAccountChange(account, currentStatus)}
      disabled={!userCan(permissions.bankAccount.inOutEnabled.update)}
    />
  );
};
