import React, { useState } from 'react';

import { changePagination, fetchAgentsThunk, fetchMoreAgents, resetAgents } from 'modules/agents/store/agents';
import { IFetchAgentsParams } from 'modules/agents/store/agents/interfaces/fetchAgentsParams.interface';

import { UserAddOutlined } from '@ant-design/icons';
import { Button } from 'antd';

import { ProtectedComponent } from 'policies/components/ProtectedComponent';

import { Filters } from 'components/Filters';
import { AgentsPageEditAgentModal } from 'modules/agents/pages/list/components/EditAgentModal';
import { AgentsPageCreateAgentModal } from 'modules/agents/pages/list/components/CreateAgentModal';

import { IAgent } from 'modules/agents/types/IAgent';

import { filtersSchema } from 'modules/agents/pages/list/filters';
import { useMemoFilters } from 'hooks/useMemoFilters/useMemoFilters';
import { permissions } from 'policies/permissions';
import { useLazyTable } from 'hooks/useLazyTable.hook';
import { LazyTable } from 'components/LazyTable';
import { makeColumns } from 'modules/agents/pages/list/columns';
import { AgentReferralsTree } from 'modules/agents/pages/list/components/AgentReferralsTree';

export const AgentsPage: React.FC = () => {
  const { memoFilters, setMemoFilters } = useMemoFilters<IFetchAgentsParams>();

  const [isAddModalVisible, setIsAddModalVisible] = useState<boolean>(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState<boolean>(false);
  const [editModalValue, setEditModalValue] = useState<IAgent>({} as IAgent);

  const [props, { isLoading }] = useLazyTable({
    filters: memoFilters,
    fetchAction: fetchAgentsThunk,
    store: 'agents',
    dataField: 'data',
    loadMoreAction: fetchMoreAgents,
    changePageAction: changePagination,
    resetAction: resetAgents,
  });

  return (
    <>
      <AgentsPageCreateAgentModal isShown={isAddModalVisible} onCancel={() => setIsAddModalVisible(false)} />
      <AgentsPageEditAgentModal
        isShown={isEditModalVisible}
        agent={editModalValue}
        onCancel={() => {
          setIsEditModalVisible(false);
          setEditModalValue({} as IAgent);
        }}
        isLoading={isLoading}
      />
      <Filters
        headerTitle="Агенты"
        filters={filtersSchema}
        value={memoFilters}
        onSubmit={setMemoFilters}
        extraHeaderButtons={() => (
          <>
            <ProtectedComponent requiredPermissions={[permissions.agent.create]} fallbackComponent={<div />}>
              <Button
                type="primary"
                shape="round"
                icon={<UserAddOutlined />}
                onClick={() => setIsAddModalVisible(true)}
              >
                Добавить
              </Button>
            </ProtectedComponent>
          </>
        )}
      />
      <LazyTable
        columns={makeColumns({
          onEditPress: (agent) => {
            setEditModalValue(agent);
            setIsEditModalVisible(true);
          },
        })}
        rowKey={({ id }: IAgent) => id}
        rowExpandable={({ referralsCount }: IAgent) => referralsCount > 0}
        expandedRowRender={({ id }: IAgent) => <AgentReferralsTree agentId={id} />}
        {...props}
        isTableView
      />
    </>
  );
};
