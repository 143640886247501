import React, { useMemo } from 'react';
import { Button, Divider, Popconfirm } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { CloseOutlined, DownloadOutlined } from '@ant-design/icons';

import { IReport } from 'modules/reports/store/reports/types';

/**
 * Хук, генерирующий колонки для таблицы на странице отчетов
 *
 * @author Рябущиц Иван <telegram: @ivangeli0n>
 */
export function useReportsTableColumns(onDeletePress: (id: number) => void) {
  return useMemo<ColumnsType<IReport>>(() => {
    return [
      {
        dataIndex: 'type',
        title: 'Тип',
      },
      {
        dataIndex: 'createdAt',
        title: 'Дата формирования (МСК)',
      },
      {
        dataIndex: 'filename',
        title: 'Имя файла',
      },
      {
        title: 'Действия',
        render: (report: IReport) => (
          <>
            <Button href={report.url} type="primary" shape="round" icon={<DownloadOutlined />} size="middle" />
            <Divider type="vertical" />
            <Popconfirm
              cancelText="Отмена"
              title="Вы уверенны, что хотите удалить отчет?"
              onConfirm={() => onDeletePress(report.id)}
            >
              <Button type="primary" danger shape="circle" icon={<CloseOutlined />} />
            </Popconfirm>
          </>
        ),
      },
    ];
  }, []);
}
