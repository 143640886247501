import { IMetaPagination } from 'types/IMetaPagination';
import { ActiveOrder } from './interfaces/activeOrder.interface';
import { ReducerAction } from 'types/reducerAction.interface';

export interface GetActiveOrdersReducerActionPayload {
  orders: ActiveOrder[];
  pagination: IMetaPagination;
}

export interface ChangePaginationActiveOrdersReducerActionPayload {
  page: number;
  perPage: number;
}

export type GetActiveOrdersReducerAction = ReducerAction<
  GetActiveOrdersReducerActionPayload,
  ActiveOrdersActionTypes.GET_ACTIVE_ORDERS
>;

export type AddActiveOrderReducerAction = ReducerAction<
  { order: ActiveOrder; isTableView: boolean },
  ActiveOrdersActionTypes.ADD_ACTIVE_ORDER
>;

export type EditActiveOrderReducerAction = ReducerAction<ActiveOrder, ActiveOrdersActionTypes.EDIT_ACTIVE_ORDER>;

export type ChangeActiveOrderReducerAction = ReducerAction<number, ActiveOrdersActionTypes.CHANGE_ORDER_STATUS>;

export type SetLoadingReducerAction = ReducerAction<boolean, ActiveOrdersActionTypes.SET_LOADING>;

export type MarkAsUnknownOrderReducerAction = ReducerAction<number, ActiveOrdersActionTypes.MARK_AS_UNKNOWN_ORDER>;

export type BindOwnerToOrderReducerAction = ReducerAction<ActiveOrder, ActiveOrdersActionTypes.BIND_OWNER_TO_ORDER>;

export type UpdateOwnersBindingsToOrderReducerAction = ReducerAction<
  ActiveOrder,
  ActiveOrdersActionTypes.UPDATE_OWNERS_BINDINGS_TO_ORDER
>;

export type ChangeBufferingActiveOrdersReducerAction = ReducerAction<boolean, ActiveOrdersActionTypes.CHANGE_BUFFERING>;

export type ChangePaginationActiveOrdersReducerAction = ReducerAction<
  ChangePaginationActiveOrdersReducerActionPayload,
  ActiveOrdersActionTypes.CHANGE_PAGINATION
>;

export type DisableAutoAssignmentReducerAction = ReducerAction<
  ActiveOrder,
  ActiveOrdersActionTypes.DISABLE_AUTO_ASSIGNMENT
>;

export type BindOperatorReducerAction = ReducerAction<
  { operatorId?: number; orderId: number },
  ActiveOrdersActionTypes.BIND_OPERATOR
>;

export type ClearActiveOrdersReducerAction = ReducerAction<null, ActiveOrdersActionTypes.CLEAR_ACTIVE_ORDERS>;

export enum ActiveOrdersActionTypes {
  GET_ACTIVE_ORDERS = 'GET_ACTIVE_ORDERS',
  ADD_ACTIVE_ORDER = 'ADD_ACTIVE_ORDER',
  EDIT_ACTIVE_ORDER = 'EDIT_ACTIVE_ORDER',
  CLEAR_ACTIVE_ORDERS = 'CLEAR_ACTIVE_ORDERS',
  CHANGE_ORDER_STATUS = 'CHANGE_ORDER_STATUS',
  SET_LOADING = 'SET_LOADING',
  MARK_AS_UNKNOWN_ORDER = 'MARK_AS_UNKNOWN_ORDER',
  BIND_OWNER_TO_ORDER = 'BIND_OWNER_TO_ORDER',
  UPDATE_OWNERS_BINDINGS_TO_ORDER = 'UPDATE_OWNERS_BINDINGS_TO_ORDER',
  CHANGE_BUFFERING = 'CHANGE_BUFFERING',
  CHANGE_PAGINATION = 'CHANGE_PAGINATION',
  DISABLE_AUTO_ASSIGNMENT = 'DISABLE_AUTO_ASSIGNMENT',
  BIND_OPERATOR = 'BIND_OPERATOR',
}

export type ActiveOrdersReducerActions =
  | GetActiveOrdersReducerAction
  | AddActiveOrderReducerAction
  | EditActiveOrderReducerAction
  | ChangeActiveOrderReducerAction
  | SetLoadingReducerAction
  | MarkAsUnknownOrderReducerAction
  | BindOwnerToOrderReducerAction
  | UpdateOwnersBindingsToOrderReducerAction
  | ChangeBufferingActiveOrdersReducerAction
  | ChangePaginationActiveOrdersReducerAction
  | DisableAutoAssignmentReducerAction
  | BindOperatorReducerAction
  | ClearActiveOrdersReducerAction;
