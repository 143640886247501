import React, { useMemo } from 'react';
import { Navigate } from 'react-router-dom';

import { useUserPermissions } from 'policies/hooks/useUserPermissions';

import { privateRoutes } from 'navigation/routes/privateRoutes';

export const MainPage: React.FC = () => {
  const { userCan } = useUserPermissions();

  const firstAccessablePage = useMemo<string>(() => {
    for (const route of privateRoutes) {
      if (userCan(...route.permissions)) {
        return route.path;
      }

      for (const subroute of route.subroutes ?? []) {
        if (userCan(...subroute.permissions)) {
          return route.path + subroute.path;
        }
      }
    }

    return '/owners';
  }, []);

  return <Navigate to={firstAccessablePage} />;
};
