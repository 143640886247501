import { IReportsState, ReportsActionTypes, ReportsReducerActions } from 'modules/reports/store/reports/types';

const initialState: IReportsState = {
  reports: [],
  isLoading: false,
  newReportsCount: 0,
  pagination: {
    page: 1,
    perPage: 20,
    total: 0,
    pages: 1,
  },
};

export function reportsReducer(state = initialState, action: ReportsReducerActions): IReportsState {
  switch (action.type) {
    case ReportsActionTypes.GET_REPORTS: {
      const { reports, pagination } = action.payload;

      return { ...state, reports, pagination };
    }
    case ReportsActionTypes.DELETE_REPORT: {
      const reportToDeleteId = action.payload;

      const updatedReports = state.reports.filter((report) => {
        return report.id !== reportToDeleteId;
      });

      return { ...state, reports: updatedReports };
    }
    case ReportsActionTypes.ADD_REPORT: {
      const newReport = action.payload;

      return { ...state, reports: [newReport, ...state.reports] };
    }
    case ReportsActionTypes.SET_NEW_REPORTS_COUNT: {
      return { ...state, newReportsCount: action.payload };
    }
    case ReportsActionTypes.SET_IS_LOADING: {
      return { ...state, isLoading: action.payload };
    }
    case ReportsActionTypes.CHANGE_PAGINATION: {
      const { page, perPage } = action.payload;

      return { ...state, pagination: { ...state.pagination, page, perPage } };
    }
    default: {
      return state;
    }
  }
}
