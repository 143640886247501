import { Dispatch } from 'redux';

import {
  BanksActionsTypes,
  IFetchBanksParams,
  IUpdateBankParams,
  IFetchFoundBinBankParams,
  IToggleBankPayload,
  ISetPaginationReducerAction,
  IUpdateBankReducerAction,
  IFetchBanksReducerAction,
  IFetchBankByBinReducerAction,
  IToggleBankReducerAction,
} from 'modules/banks/store/banks/types';

import instance from 'utils/axios';

import { IBank } from 'modules/banks/types/IBank';
import { RequestParamsWithPagination } from 'types/requestParamsWithPagination.interface';

export function fetchBanks(params: IFetchBanksParams & RequestParamsWithPagination) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(setIsLoading(true));

      const {
        data: { data, meta },
      } = await instance.get('/banks', { params });

      dispatch<IFetchBanksReducerAction>({
        type: BanksActionsTypes.FETCH_BANKS,
        payload: {
          banks: data,
          pagination: meta,
        },
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  };
}

export function fetchBankByBin(params: IFetchFoundBinBankParams) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(setIsLoading(true));

      const {
        data: { data },
      } = await instance.post('/banks/create-bin', { ...params });

      dispatch<IFetchBankByBinReducerAction>({
        type: BanksActionsTypes.FETCH_BANK_BY_BIN,
        payload: data,
      });
    } catch (e) {
      dispatch(setBankByBinCloseDrawer());

      throw e;
    } finally {
      dispatch(setIsLoading(false));
    }
  };
}

export function addMainBankToRemoveDuplicate(params: IBank) {
  return {
    type: BanksActionsTypes.ADD_MAIN_BANK_TO_REMOVE_DUPLICATE,
    payload: params,
  };
}

export function removeMainBankToRemoveDuplicate() {
  return {
    type: BanksActionsTypes.ADD_MAIN_BANK_TO_REMOVE_DUPLICATE,
    payload: null,
  };
}

export function addMergeBanksToRemoveDuplicate(params: IBank) {
  return {
    type: BanksActionsTypes.ADD_MERGE_BANKS_TO_REMOVE_DUPLICATE,
    payload: params,
  };
}

export function removeMergeBanksToRemoveDuplicate(params: IBank) {
  return {
    type: BanksActionsTypes.DELETE_MERGE_BANKS_TO_REMOVE_DUPLICATE,
    payload: params,
  };
}

interface IMergeBanksParams {
  ids: number[];
}

export function mergeBanks(params: IMergeBanksParams) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(setIsLoading(true));

      await instance.post('/banks/combine', { ...params });
    } finally {
      dispatch(setIsLoading(false));
    }
  };
}

export function clearMainAndMergeBanks() {
  return {
    type: BanksActionsTypes.CLEAR_MAIN_AND_MERGE_BANK,
    payload: null,
  };
}

export function updateCheckedMainBank(params: IBank) {
  return {
    type: BanksActionsTypes.UPDATE_BANK,
    payload: params,
  };
}

export function disabledCheckedMainBank(params: IBank) {
  return {
    type: BanksActionsTypes.DISABLED_CHECKED_MAIN_BANK,
    payload: params,
  };
}

export function disabledCheckedMergeBank(params: IBank) {
  return {
    type: BanksActionsTypes.DISABLED_CHECKED_MERGE_BANK,
    payload: params,
  };
}

export function updateCheckedMergeBank(params: IBank) {
  return {
    type: BanksActionsTypes.UPDATE_BANK,
    payload: params,
  };
}

export function clearCheckedMainBank() {
  return {
    type: BanksActionsTypes.CLEAR_CHECKED_MAIN_BANK,
    payload: null,
  };
}

export function clearCheckedMergeBanks() {
  return {
    type: BanksActionsTypes.CLEAR_CHECKED_MERGE_BANK,
    payload: null,
  };
}

export function clearDisabledCheckedBanks() {
  return {
    type: BanksActionsTypes.CLEAR_DISABLED_CHECKED_BANKS,
    payload: null,
  };
}

export function setBankByBinCloseDrawer() {
  return {
    type: BanksActionsTypes.DELETE_BANK_BY_BIN,
    payload: null,
  };
}

export function toggleBank(params: IToggleBankPayload) {
  const { id, enabled } = params;

  return async (dispatch: Dispatch) => {
    try {
      dispatch(setIsLoading(true));

      await instance.post(`/banks/${enabled ? 'disable' : 'enable'}`, { id });

      dispatch<IToggleBankReducerAction>({
        type: BanksActionsTypes.TOGGLE_BANK,
        payload: { id: id, enabled: !enabled },
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  };
}

export function updateBank(params: IUpdateBankParams) {
  return async (dispatch: Dispatch) => {
    const {
      data: { data },
    } = await instance.post('/banks/update', {
      ...params,
    });

    dispatch<IUpdateBankReducerAction>({
      type: BanksActionsTypes.UPDATE_BANK,
      payload: data,
    });
  };
}

export function setIsLoading(value: boolean) {
  return {
    type: BanksActionsTypes.SET_IS_LOADING,
    payload: value,
  };
}

export function setPagination(page: number, perPage: number) {
  return (dispatch: Dispatch) => {
    dispatch<ISetPaginationReducerAction>({
      type: BanksActionsTypes.SET_PAGINATION,
      payload: { page, perPage },
    });
  };
}
