import { Dispatch } from 'redux';

import api from 'utils/axios';
import {
  OwnersCardsActionsTypes,
  IFetchOwnersCardsParams,
  IFetchOwnersCardsReducerAction,
  ISetLoadingReducerAction,
  IChangePageOwnersCardsReducerAction,
  CreateOwnerCardParams,
  CreateOwnerCardReducerAction,
  IOwnerCard,
  EditOwnerCardReducerAction,
  DeleteOwnerCardReducerAction,
  DeleteOwnerCardParams,
} from 'modules/owners/store/ownersCards/types';
import { sanitizeRequestParams } from 'utils/sanitizeRequestParams';

export function fetchCardsOwners(params: IFetchOwnersCardsParams = {}) {
  return async (dispatch: Dispatch<any>) => {
    try {
      dispatch(setLoading());

      const {
        data: { data, meta },
      } = await api.get('/cards', { params: sanitizeRequestParams(params) });

      dispatch<IFetchOwnersCardsReducerAction>({
        type: OwnersCardsActionsTypes.FETCH_OWNERS_CARDS,
        payload: { cards: data, pagination: meta },
      });
    } catch {
      dispatch(setLoading(false));
    } finally {
      dispatch(setLoading(false));
    }
  };
}

export function createOwnerCard(params: CreateOwnerCardParams = {} as CreateOwnerCardParams) {
  return async (dispatch: Dispatch<any>) => {
    try {
      dispatch(setLoading());

      const {
        data: { data },
      } = await api.post('/cards/create', { ...sanitizeRequestParams(params) });

      dispatch<CreateOwnerCardReducerAction>({
        type: OwnersCardsActionsTypes.CREATE_OWNER_CARD,
        payload: data as IOwnerCard,
      });
    } finally {
      dispatch(setLoading(false));
    }
  };
}

export function deleteOwnerCard(params: DeleteOwnerCardParams) {
  return async (dispatch: Dispatch<any>) => {
    try {
      dispatch(setLoading());

      await api.post('/cards/delete', { ...sanitizeRequestParams(params) });

      dispatch<DeleteOwnerCardReducerAction>({
        type: OwnersCardsActionsTypes.DELETE_OWNER_CARD,
        payload: params.id,
      });
    } finally {
      dispatch(setLoading(false));
    }
  };
}

export function disableOwnerCard(cardId: number) {
  return async (dispatch: Dispatch<any>) => {
    try {
      dispatch(setLoading());

      const {
        data: { data },
      } = await api.post('/cards/disable', { cardId });

      dispatch<EditOwnerCardReducerAction>({
        type: OwnersCardsActionsTypes.EDIT_OWNER_CARD,
        payload: data as IOwnerCard,
      });
    } finally {
      dispatch(setLoading(false));
    }
  };
}

export function enableOwnerCard(cardId: number) {
  return async (dispatch: Dispatch<any>) => {
    try {
      dispatch(setLoading());

      const {
        data: { data },
      } = await api.post('/cards/enable', { cardId });

      dispatch<EditOwnerCardReducerAction>({
        type: OwnersCardsActionsTypes.EDIT_OWNER_CARD,
        payload: data as IOwnerCard,
      });
    } finally {
      dispatch(setLoading(false));
    }
  };
}

export function setLoading(value: boolean = true) {
  return (dispatch: Dispatch) => {
    dispatch<ISetLoadingReducerAction>({
      type: OwnersCardsActionsTypes.SET_LOADING,
      payload: value,
    });
  };
}

export function changePage(page: number, perPage: number) {
  return (dispatch: Dispatch) => {
    dispatch<IChangePageOwnersCardsReducerAction>({
      type: OwnersCardsActionsTypes.CHANGE_PAGE,
      payload: { page, perPage },
    });
  };
}
