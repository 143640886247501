import { asyncThunkCreator, buildCreateSlice } from '@reduxjs/toolkit';
import { PaymentsState } from './types/paymentsState.interface';
import instance from 'utils/axios';
import { FetchPaymentsParams } from './types/fetchPaymentsParams.interface';
import { sanitizeRequestParams } from 'utils/sanitizeRequestParams';
import { RequestParamsWithPagination } from 'types/requestParamsWithPagination.interface';

function cleanEmptyArrays(params: any): Record<string, any> {
  return Object.fromEntries(
    Object.entries(params).filter(([_, value]) => !(Array.isArray(value) && value.length === 0))
  );
}

const createSlice = buildCreateSlice({ creators: { asyncThunk: asyncThunkCreator } });

const paymentsSlice = createSlice({
  name: 'payments',
  initialState: {
    payments: [],
    isLoading: false,
    pagination: {
      page: 1,
      pages: 0,
      perPage: 20,
      total: 0,
    },
  } as PaymentsState,
  reducers: (create) => ({
    fetchPayments: create.asyncThunk(
      async (params: FetchPaymentsParams & RequestParamsWithPagination) => {
        const cleanedParams = cleanEmptyArrays(params);

        const { data } = await instance.get('payment', { params: sanitizeRequestParams(cleanedParams) });

        return data;
      },
      {
        fulfilled: (state, action) => {
          state.isLoading = false;
          state.payments = action.payload.data;
          state.pagination = action.payload.meta;
        },
        rejected: (state) => {
          state.isLoading = false;
        },
        pending: (state) => {
          state.isLoading = true;
        },
      }
    ),
    setIsLoadingPayments: create.reducer<boolean>((state, action) => {
      state.isLoading = action.payload;
    }),
  }),
});

export const paymentsReducer = paymentsSlice.reducer;

export const { fetchPayments, setIsLoadingPayments } = paymentsSlice.actions;
