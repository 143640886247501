import {
  useMerchantsFromServer,
  useOperatorsFromServer,
  useBanksFromServer,
  useOrderStatusesFromServer,
} from 'hooks/useDataFromServer';
import { OrderTypes } from 'modules/orders/enums/orderTypes.enum';
import { IFilterSchema } from 'components/Filters/types/filterSchema.interface';

export const filtersSchema: IFilterSchema[] = [
  {
    title: 'ID заявки',
    name: 'id',
    type: 'input-number',
  },
  {
    title: 'ID клиента',
    name: 'remoteOrderId',
    type: 'input',
    allowClear: true,
  },
  {
    title: 'ID владельца',
    name: 'ownerId',
    type: 'input-number',
  },
  {
    title: 'Клиент',
    name: 'merchantsIds',
    type: 'lazy-select',
    allowClear: true,
    useDataHook: useMerchantsFromServer,
    cacheKey: 'customers-filter',
    mode: 'multiple',
    width: 300,
  },
  {
    title: 'Исключить клиентов',
    name: 'excludeMerchantsIds',
    type: 'lazy-select',
    allowClear: true,
    useDataHook: useMerchantsFromServer,
    cacheKey: 'customers-filter',
    mode: 'multiple',
    width: 200,
  },
  {
    title: 'Статус',
    name: 'statuses',
    type: 'lazy-select',
    allowClear: true,
    mode: 'multiple',
    useDataHook: useOrderStatusesFromServer,
    cacheKey: 'order-status',
    width: 200,
  },
  {
    title: 'Провайдер',
    name: 'provider',
    type: 'lazy-select',
    allowClear: true,
    useDataHook: useBanksFromServer,
    cacheKey: 'providers-filter',
  },
  {
    title: 'Тип транзакции',
    name: 'type',
    type: 'select',
    allowClear: true,
    width: 142,
    options: [
      {
        label: 'Любой',
        value: '',
      },
      {
        label: 'Входящие',
        value: OrderTypes.INCOMING,
      },
      {
        label: 'Исходящие',
        value: OrderTypes.OUTCOMING,
      },
    ],
  },
  {
    title: 'Операторы',
    name: 'operatorsIds',
    type: 'lazy-select',
    allowClear: true,
    useDataHook: useOperatorsFromServer,
    cacheKey: 'operators-filter',
    mode: 'multiple',
    width: 200,
  },
  {
    title: 'Даты',
    name: 'creationDate',
    type: 'date-picker-showtime',
    allowClear: true,
    rangeFields: ['from', 'to'],
  },
  {
    title: 'Тип даты',
    name: 'filterByTimeExecuteOrder',
    type: 'select',
    allowClear: true,
    options: [
      {
        label: 'Дата создания',
        value: 0,
      },
      {
        label: 'Дата выполнения',
        value: 1,
      },
    ],
  },
  {
    title: 'Тип клиента',
    name: 'isHighRiskMerchant',
    type: 'select',
    allowClear: true,
    options: [
      {
        label: 'Любой',
        value: '',
      },
      {
        label: 'Обменник',
        value: 0,
      },
      {
        label: 'High risk',
        value: 1,
      },
    ],
  },
];
