import React, { FC, useEffect, useState } from 'react';
import { Button, App, Row, Col } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { IBank } from 'modules/banks/types/IBank';
import { Filters } from 'components/Filters';
import { useMemoFilters } from 'hooks/useMemoFilters/useMemoFilters';
import { ProtectedComponent } from 'policies/components/ProtectedComponent';

import { filtersSchema } from './filters';

import { BanksPageCreateBankContainer } from './components/CreateBankContainer';
import { BanksPageEditBankContainer } from './components/EditBankContainer';
import { BanksPageMergeBanks } from './components/MergeBanks';
import { BanksPageTable } from './components/Table';

import { fetchBanks, setBankByBinCloseDrawer, setPagination } from 'modules/banks/store/banks/actions';
import { IFetchBanksParams } from 'modules/banks/store/banks/types';

import { permissions } from 'policies/permissions';
import { Pagination } from 'components/Pagination';
import { useAppDispatch } from 'hooks/useAppDispatch.hook';
import { useAppSelector } from 'hooks/useAppSelector.hook';

export const BanksPage: FC = () => {
  const { memoFilters, setMemoFilters } = useMemoFilters<IFetchBanksParams>();

  const { message } = App.useApp();

  const [bankToEdit, setBankToEdit] = useState<IBank>();
  const [isCreateBankShown, setIsCreateBankShown] = useState<boolean>(false);
  const [isEditBankShown, setIsEditBankShown] = useState<boolean>(false);
  const [isMergeBanksShown, setIsMergeBanksShown] = useState<boolean>(false);
  const [isSelectColumnShown, setIsSelectColumnShown] = useState(false);

  const dispatch = useAppDispatch();

  const {
    pagination,
    pagination: { perPage, page },
    isLoading,
  } = useAppSelector((state) => state.banks);

  useEffect(() => {
    fetch();
  }, [memoFilters, page, perPage]);

  async function fetch() {
    try {
      await dispatch(fetchBanks({ ...memoFilters, page, perPage }));
    } catch {
      message.error('Не удалось получить список банков');
    }
  }

  function handlePaginationChange(page: number, perPage: number) {
    dispatch(setPagination(page, perPage));
  }

  function handleBanksEditPress(bank: IBank): void {
    setBankToEdit(bank);
    setIsEditBankShown(true);
  }

  function handleCreateBankClose(): void {
    dispatch(setBankByBinCloseDrawer());
    setIsCreateBankShown(false);
  }

  function handleMergeBanksClose(): void {
    setIsMergeBanksShown(false);
  }

  function handleMergeBanks() {
    setIsMergeBanksShown(true);
  }

  function handleShowColumnSelectBanks() {
    setIsSelectColumnShown(!isSelectColumnShown);
  }

  return (
    <>
      {isEditBankShown && bankToEdit !== undefined && (
        <BanksPageEditBankContainer
          isShown={isEditBankShown}
          bank={bankToEdit}
          onClose={() => setIsEditBankShown(false)}
        />
      )}
      {isCreateBankShown && <BanksPageCreateBankContainer onClose={handleCreateBankClose} />}
      {isMergeBanksShown && <BanksPageMergeBanks onClose={handleMergeBanksClose} />}

      <Filters
        headerTitle="Банки"
        filters={filtersSchema}
        value={memoFilters}
        onSubmit={setMemoFilters}
        extraHeaderButtons={() => (
          <ProtectedComponent requiredPermissions={[permissions.bank.create]} fallbackComponent={<div />}>
            <Button icon={<PlusOutlined />} shape="round" type="primary" onClick={() => setIsCreateBankShown(true)}>
              Добавить
            </Button>
          </ProtectedComponent>
        )}
      />
      <Row gutter={8} style={{ marginTop: '-17px' }}>
        <Col>
          <ProtectedComponent requiredPermissions={[permissions.bank.combine]}>
            <Button type="primary" onClick={handleShowColumnSelectBanks}>
              Выбрать банки
            </Button>
          </ProtectedComponent>
        </Col>
        <Col>
          <ProtectedComponent requiredPermissions={[permissions.bank.combine]}>
            <Button type="primary" onClick={handleMergeBanks}>
              Объединить банки
            </Button>
          </ProtectedComponent>
        </Col>
      </Row>
      <BanksPageTable onBankEditPress={handleBanksEditPress} isSelectColumnShown={isSelectColumnShown} />
      <Pagination pagination={pagination} isLoading={isLoading} onPaginationChange={handlePaginationChange} />
    </>
  );
};
