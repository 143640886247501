import {
  IOwnersCardsState,
  OwnersCardsActionsTypes,
  OwnersCardsReducersActions,
  IFetchOwnersCardsPayload,
  IOwnerCard,
} from 'modules/owners/store/ownersCards/types';

const initialState: IOwnersCardsState = {
  cards: [],
  isLoading: false,
  pagination: {
    page: 1,
    perPage: 20,
    pages: 0,
    total: 0,
  },
};

export function ownersCardsReducer(
  state = initialState,
  { type, payload }: OwnersCardsReducersActions
): IOwnersCardsState {
  switch (type) {
    case OwnersCardsActionsTypes.FETCH_OWNERS_CARDS: {
      const { cards, pagination } = payload as IFetchOwnersCardsPayload;

      return { ...state, cards, pagination, isLoading: false };
    }

    case OwnersCardsActionsTypes.CREATE_OWNER_CARD: {
      const card = payload as IOwnerCard;

      return { ...state, cards: [card, ...state.cards] };
    }

    case OwnersCardsActionsTypes.DELETE_OWNER_CARD: {
      const cardId = payload as number;
      return {
        ...state,
        cards: state.cards.filter((card) => card.id !== cardId),
      };
    }

    case OwnersCardsActionsTypes.EDIT_OWNER_CARD: {
      const editedCard = payload as IOwnerCard;

      return {
        ...state,
        cards: state.cards.map((card) => {
          return card.id === editedCard.id ? editedCard : card;
        }),
      };
    }

    case OwnersCardsActionsTypes.SET_LOADING: {
      const isLoading = payload as boolean;

      return { ...state, isLoading };
    }
    case OwnersCardsActionsTypes.CHANGE_PAGE: {
      const { page, perPage } = payload as { page: number; perPage: number };

      return { ...state, pagination: { ...state.pagination, page, perPage } };
    }
    default:
      return state;
  }
}
