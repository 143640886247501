import React from 'react';
import { createBrowserRouter, type RouteObject, RouterProvider } from 'react-router-dom';

import { NotFoundPage } from 'modules/auth/pages/notFound';
import { MainPage } from './components/MainPage';
import { LoginPage } from 'modules/auth/pages/login';

import { INavigationRoute } from './types';
import { privateRoutes } from './routes/privateRoutes';

import { ProtectedPage } from 'policies/components/ProtectedPage';
import { Layout } from 'components/Layout';

const appRoutes: RouteObject[] = privateRoutes
  .map((route) => ({
    path: route.path,
    element: <ProtectedPage requiredPermissions={route.permissions} pageComponent={route.component} />,
  }))
  .concat(
    privateRoutes
      .map(
        (route) =>
          route.subroutes?.map((subroute) => ({
            ...subroute,
            parentPath: route.path,
          }))
      )
      .flat()
      .filter((route): route is INavigationRoute & { parentPath: string } => !!route)
      .map((route) => ({
        path: route.parentPath + route.path,
        element: <ProtectedPage requiredPermissions={route.permissions} pageComponent={route.component} />,
      }))
  );

export const appRouter = createBrowserRouter([
  {
    path: '/login',
    element: <LoginPage />,
  },
  {
    path: '/',
    element: <MainPage />,
  },
  {
    element: <Layout />,
    children: appRoutes,
  },
  {
    path: '*',
    element: <NotFoundPage />,
  },
]);

export function AppRouter() {
  return <RouterProvider router={appRouter} />;
}
