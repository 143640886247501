import React, { useEffect } from 'react';
import { Button, Drawer } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

import { IOwner } from 'modules/owners/types/IOwner';
import { OwnerCardsModalContent } from './components/Content';

interface Props {
  /**
   * Открыт ли дравер
   */
  isShown: boolean;
  /**
   * Стейт значений инпутов
   */
  params: IOwner;
  /**
   * Колбэк, вызываемый при изменении значений инпутов
   */
  onChange: (newValue: IOwner) => void;
  /**
   * Колбэк, вызываемый при закрытии модала
   */
  onCancel: () => void;
}

/**
 * Модал с карт владельца с возможностью редактирования карт и тд.
 *
 * @author Михаил <telegram: @misha_programmer>
 */
export const OwnerCardsModal: React.FC<Props> = ({ isShown, params, onCancel, onChange }) => {
  useEffect(() => {
    onChange(params);
  }, []);

  if (Object.keys(params).length === 0) return null;

  return (
    <Drawer
      width={650}
      title={'Счета и карты владельца ' + params.fio}
      open={isShown}
      onClose={onCancel}
      closable={false}
      extra={<Button icon={<CloseOutlined />} type="text" onClick={onCancel} />}
    >
      <OwnerCardsModalContent ownerId={params.ownerId} />
    </Drawer>
  );
};
