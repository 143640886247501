import { useCallback, useMemo } from 'react';

import { haveAccessTo } from 'policies/methods/haveAccessTo';

import { useAppSelector } from 'hooks/useAppSelector.hook';

export function useUserPermissions() {
  const { user, isAuth } = useAppSelector((state) => state.auth);

  const userCan = useCallback(
    (...permissions: string[]) => {
      if (!user) return false;

      return haveAccessTo(permissions, user);
    },
    [user]
  );

  const userPermissions = useMemo(() => user?.permissions ?? [], [user]);

  return { userCan, isUserAuthenticated: isAuth, userPermissions };
}
