import { Dispatch, PayloadAction, createSlice } from '@reduxjs/toolkit';

import api from 'utils/axios';

import { IAgentsState } from 'modules/agents/store/agents/interfaces/agentsState.interface';
import { IFetchAgentsParams } from 'modules/agents/store/agents/interfaces/fetchAgentsParams.interface';
import { ICreateAgentParams } from 'modules/agents/store/agents/interfaces/createAgentParams.interface';
import { IUpdateAgentParams } from 'modules/agents/store/agents/interfaces/updateAgentParams.interface';
import { IAgent } from 'modules/agents/types/IAgent';
import { GetState } from 'store';
import { IFetchAgentsPayload } from 'modules/agents/store/agents/interfaces/fetchAgentsPayload.interface';
import { BackendResponse } from 'types/BackendResponse';
import { RequestParamsWithPagination } from 'types/requestParamsWithPagination.interface';

const agentsSlice = createSlice({
  name: 'agents',
  initialState: {
    data: [],
    pagination: {
      page: 1,
      pages: 0,
      perPage: 20,
      total: 0,
    },
    isLoading: false,
  } as IAgentsState,
  reducers: {
    changePagination: (state, action: PayloadAction<{ page: number; perPage: number }>) => {
      const { page, perPage } = action.payload;

      state.pagination = { ...state.pagination, page, perPage };
    },
    resetAgents: (state) => {
      state.data = [];
      state.pagination = {
        ...state.pagination,
        page: 1,
        pages: 0,
        total: 0,
      };
    },
    fetchAgents: (state, action: PayloadAction<IFetchAgentsPayload>) => {
      const { agents, pagination } = action.payload;

      state.data = agents;
      state.pagination = pagination;
      state.isLoading = false;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    editAgent: (state, action: PayloadAction<IAgent>) => {
      state.data = state.data.map((agent) => (agent.id === action.payload.id ? action.payload : agent));
      state.isLoading = false;
    },
    addAgent: (state, action: PayloadAction<IAgent>) => {
      state.data = [action.payload, ...state.data];
      state.isLoading = false;
    },
  },
});

export function fetchAgentsThunk(params: IFetchAgentsParams & RequestParamsWithPagination) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch<any>(setLoading(true));

      const {
        data: { data, meta },
      } = await api.get<BackendResponse<IAgent[]>>('/agents', { params });

      dispatch(fetchAgents({ agents: data, pagination: meta }));
    } catch {
      dispatch<any>(setLoading(false));
    }
  };
}

export function fetchMoreAgents(params: IFetchAgentsParams) {
  return async (dispatch: Dispatch, getState: GetState) => {
    try {
      dispatch<any>(setLoading(true));

      const {
        data: agents,
        pagination: { page },
      } = getState().agents;

      const {
        data: { data, meta },
      } = await api.get<BackendResponse<IAgent[]>>('/agents', {
        params: {
          ...params,
          page: page + 1,
        },
      });

      dispatch(fetchAgents({ agents: [...agents, ...data], pagination: meta }));
    } catch {
      dispatch<any>(setLoading(false));
    }
  };
}

export function updateAgentThunk(params: IUpdateAgentParams) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch<any>(setLoading(true));

      const {
        data: { data },
      } = await api.post<BackendResponse<IAgent>>('/agents/update', { ...params });

      dispatch(editAgent(data));

      return true;
    } catch {
      dispatch<any>(setLoading(false));

      return false;
    }
  };
}

export function createAgentThunk(params: ICreateAgentParams) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch<any>(setLoading(true));

      const {
        data: { data },
      } = await api.post<BackendResponse<IAgent>>('/agents/create', { ...params });

      dispatch(addAgent(data));

      return true;
    } catch {
      dispatch<any>(setLoading(false));

      return false;
    }
  };
}

export const { changePagination, resetAgents, fetchAgents, setLoading, editAgent, addAgent } = agentsSlice.actions;
export const agentsReducer = agentsSlice.reducer;
