import React, { useMemo } from 'react';
import { ColumnType } from 'antd/lib/table';
import { Tag, Space, Button, Tooltip } from 'antd';
import { EditFilled } from '@ant-design/icons';

import { IUser } from 'modules/users/types/IUser';
import { ProtectedComponent } from 'policies/components/ProtectedComponent';
import { permissions } from 'policies/permissions';
import { IUserRole } from 'modules/users/types/IUserRole';

interface Config {
  onUserEditPress: (user: IUser) => void;
}

export function useTableColumns({ onUserEditPress }: Config) {
  return useMemo<ColumnType<IUser>[]>(
    () => [
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
      },
      {
        title: 'Имя',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
      },
      {
        title: 'Роли',
        key: 'roles',
        render: (_: any, user) => (
          <>
            {user.roles.map((role: IUserRole) => (
              <Tag key={role.id}>{role.name}</Tag>
            ))}
          </>
        ),
      },
      {
        key: 'actions',
        render: (_: any, user) => (
          <Space>
            <ProtectedComponent requiredPermissions={[permissions.user.update]}>
              <Tooltip title="Редактировать пользователя">
                <Button type="primary" shape="circle" onClick={() => onUserEditPress(user)}>
                  <EditFilled />
                </Button>
              </Tooltip>
            </ProtectedComponent>
          </Space>
        ),
      },
    ],
    []
  );
}
