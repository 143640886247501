import React, { FC } from 'react';
import { Drawer } from 'antd';

import { IBank } from 'modules/banks/types/IBank';
import { BanksPageEditBankForm } from '../EditBankForm';

interface Props {
  bank: IBank;
  isShown: boolean;
  onClose: () => void;
}

export const BanksPageEditBankContainer: FC<Props> = ({ isShown, bank, onClose }) => {
  return (
    <Drawer open={isShown} title="Действие: Редактирование банка" onClose={onClose} size="large">
      <BanksPageEditBankForm bank={bank} onClose={onClose} />
    </Drawer>
  );
};
