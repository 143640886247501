import React, { useMemo } from 'react';
import { Popover, Progress } from 'antd';
import { OrderTypes } from 'modules/orders/enums/orderTypes.enum';
import { OrderPayment, OrderPaymentStatus, orderPaymentStatusTitles } from 'modules/orders/enums/order.interface';

interface Props {
  type: OrderTypes;
  sumTransferred?: OrderPayment[];
  orderSum: number;
}

export const ActiveOrdersPageColumnsViewTransferredSum: React.FC<Props> = ({ type, sumTransferred, orderSum }) => {
  if (OrderTypes.INCOMING === type || !sumTransferred) {
    return <>- - -</>;
  }

  const totalTransferredSum = useMemo(
    () =>
      sumTransferred.reduce((p, n) => {
        const sum = n.status === OrderPaymentStatus.COMPLETED ? n.sum : 0;

        return p + sum;
      }, 0),
    [sumTransferred]
  );
  const totalReceivedSumPercentage = useMemo(
    () => ((totalTransferredSum / orderSum) * 100).toFixed(0),
    [totalTransferredSum, orderSum]
  );

  const noContent = <div>Средства еще не переведены</div>;
  const someContent = (
    <>
      {sumTransferred.map((item, index) => {
        const status = orderPaymentStatusTitles[item.status];

        return (
          <div key={index}>
            Владелец №{item.ownerId} - {status} {item.sum ? ' - ' + item.sum.toLocaleString('ru-RU') : ''}
          </div>
        );
      })}
      Всего:&nbsp;
      {totalTransferredSum.toLocaleString('ru-RU')} из {orderSum.toLocaleString('ru-RU')}
    </>
  );

  const content = sumTransferred?.length ? someContent : noContent;
  return (
    <Popover content={content}>
      <Progress trailColor="#d1d5db" type="line" percent={Number(totalReceivedSumPercentage)} />
      <div style={{ backgroundColor: 'transparent' }} className="mt-3 bg-">
        Осталось: <span className="decoration-solid">{(orderSum - totalTransferredSum).toLocaleString('ru-RU')}</span>
      </div>
    </Popover>
  );
};
