import React from 'react';
import { Pagination, Table } from 'antd';

import { useTheme } from 'contexts';

import { useReportsTableColumns } from 'modules/reports/pages/list/components/Table/columns';
import { IReport } from 'modules/reports/store/reports/types';
import { paginationLocale } from 'constants/locale';

interface Props {
  data: IReport[];
  isLoading: boolean;
  page: number;
  perPage: number;
  total: number;
  onDeletePress: (id: number) => void;
  onPaginationChange: (page: number) => void;
}

export const ReportsPageTable: React.FC<Props> = ({
  data,
  isLoading,
  perPage,
  page,
  total,
  onDeletePress,
  onPaginationChange,
}) => {
  const { choose } = useTheme();
  const columns = useReportsTableColumns(onDeletePress);

  return (
    <>
      <Table
        dataSource={data}
        columns={columns}
        loading={isLoading}
        pagination={false}
        rowClassName={(record) => (record.new ? 'animate-pulse ' + choose('bg-green-900', 'bg-green-100') : '')}
        rowKey={(record) => record.id}
      />
      <Pagination
        current={page}
        total={total}
        pageSize={perPage}
        locale={paginationLocale}
        onChange={(page: number) => {
          onPaginationChange(page);
        }}
        disabled={isLoading}
      />
    </>
  );
};
