import React from 'react';
import { message, Switch } from 'antd';
import { setBankAccountOutEnabled } from 'modules/bankAccounts/store';
import { BankAccount } from 'modules/bankAccounts/types/bankAccount.interface';
import { useAppDispatch } from 'hooks/useAppDispatch.hook';
import { useUserPermissions } from 'policies/hooks/useUserPermissions';
import { permissions } from 'policies/permissions';

interface Props {
  account: BankAccount;
}

export const BankAccountsListPageOutEnabled: React.FC<Props> = ({ account }) => {
  const dispatch = useAppDispatch();

  const { userCan } = useUserPermissions();

  async function handleOutEnabledAccountChange(account: BankAccount, outEnabled: boolean) {
    try {
      await dispatch(
        setBankAccountOutEnabled({
          bankAccountId: account.bankAccountId,
          outEnabled,
        })
      );

      message.success({
        content: 'Исходящие платежи успешно изменены!',
        duration: 1.5,
      });
    } catch {
      message.error('Ошибка изменения Исходящих платежей!');
    }
  }

  return (
    <Switch
      checked={account.outEnabled}
      onChange={(currentStatus) => handleOutEnabledAccountChange(account, currentStatus)}
      disabled={!userCan(permissions.bankAccount.inOutEnabled.update)}
    />
  );
};
