import { Dispatch } from 'redux';

import instance from 'utils/axios';

import {
  IAddReportReducerAction,
  IChangePaginationReportsReducerActions,
  IDeleteReportReducerAction,
  IGetReportParams,
  IGetReportsReducerAction,
  IReport,
  ISetIsLoadingReportsReducerAction,
  ISetNewReportsCountReducerAction,
  ReportsActionTypes,
} from 'modules/reports/store/reports/types';

export function getReports(params: IGetReportParams) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch<any>(setIsLoading(true));

      const {
        data: { data, meta },
      } = await instance.get('/reports', { params });

      dispatch<IGetReportsReducerAction>({
        type: ReportsActionTypes.GET_REPORTS,
        payload: { reports: data, pagination: meta },
      });
    } finally {
      dispatch<any>(setIsLoading(false));
    }
  };
}

export function getNewReportsCount() {
  return async (dispatch: Dispatch) => {
    const {
      data: { data },
    } = await instance.get('/reports/new_count');

    dispatch<ISetNewReportsCountReducerAction>({
      type: ReportsActionTypes.SET_NEW_REPORTS_COUNT,
      payload: data as number,
    });
  };
}

export function deleteReport(id: number) {
  return async (dispatch: Dispatch) => {
    const {
      data: { data },
    } = await instance.delete('/reports/' + id);

    dispatch<IDeleteReportReducerAction>({
      type: ReportsActionTypes.DELETE_REPORT,
      payload: data as number,
    });
  };
}

export function setReportAsSeen(ids: number[]) {
  return async (dispatch: Dispatch) => {
    await instance.post('/reports/set_seen', { ids });

    dispatch<ISetNewReportsCountReducerAction>({
      type: ReportsActionTypes.SET_NEW_REPORTS_COUNT,
      payload: 0,
    });
  };
}

export function addNewReport(newReport: IReport) {
  return (dispatch: Dispatch) => {
    dispatch<IAddReportReducerAction>({
      type: ReportsActionTypes.ADD_REPORT,
      payload: newReport,
    });
  };
}

export function changePagination(page: number, perPage: number) {
  return (dispatch: Dispatch) => {
    dispatch<IChangePaginationReportsReducerActions>({
      type: ReportsActionTypes.CHANGE_PAGINATION,
      payload: { page, perPage },
    });
  };
}

export function setIsLoading(payload: boolean) {
  return (dispatch: Dispatch) => {
    dispatch<ISetIsLoadingReportsReducerAction>({
      type: ReportsActionTypes.SET_IS_LOADING,
      payload,
    });
  };
}
