import React, { useMemo } from 'react';
import { Space, Drawer, Button, App } from 'antd';

import { EditOwnerCardParams } from 'modules/owners/store/ownersCards/types';

import { LazySelect } from 'components/LazySelect';
import { InputNumber } from 'components/Input';

import { useBanksFromServer, useCardStatusesFromServer } from 'hooks/useDataFromServer';

interface Props {
  isShown: boolean;
  params: EditOwnerCardParams;
  onChange: (newValue: EditOwnerCardParams) => void;
  onSubmit: () => void;
  onCancel: () => void;
  isOwnerIdHidden: boolean;
}

export const SharedOwnerCardEditDrawer: React.FC<Props> = ({
  isShown,
  params,
  onCancel,
  onSubmit,
  onChange,
  isOwnerIdHidden,
}) => {
  const { ownerId, status, bank } = params;

  const { message } = App.useApp();

  const currentStatus = useMemo(() => {
    if (status === undefined) {
      return undefined;
    }

    return { value: Boolean(status), label: status ? 'Активная' : 'Неактивная' };
  }, [status]);

  function handleSubmitPress() {
    if (!validate()) return;

    onSubmit();
  }

  function validate() {
    for (const [field, value] of Object.entries(params as EditOwnerCardParams)) {
      if (field === 'ownerId' && !value) {
        message.error('Необходимо ФИО владельца');
        return false;
      }
      if (field === 'card' && !value) {
        message.error('Необходимо заполнить карту');
        return false;
      }
    }

    return true;
  }

  const onValueChange = (key: keyof EditOwnerCardParams, value: null | number | string | boolean) => {
    if (null === value || undefined === value) {
      delete params[key];
      onChange({ ...params });
    } else {
      onChange({ ...params, [key]: value });
    }
  };
  return (
    <Drawer title="Редактировать карту" open={isShown} onClose={onCancel}>
      <Space direction="vertical" size="large">
        <InputNumber
          addonBefore={'ID Владельца'}
          placeholder={'ID Владельца'}
          type="text"
          value={ownerId}
          onChange={(value) => onValueChange('ownerId', value)}
          style={{ display: isOwnerIdHidden ? 'none' : 'block' }}
        />
        <LazySelect
          allowClear
          style={{ minWidth: 120 }}
          placeholder="Статус карты"
          useDataHook={useCardStatusesFromServer}
          cacheKey="card-status"
          defaultValue={currentStatus}
          value={currentStatus}
          onChange={(value: boolean) => onValueChange('status', value)}
        />
        <LazySelect
          allowClear
          placeholder="Провайдер"
          style={{ minWidth: 120 }}
          value={bank}
          onChange={(value: number) => onValueChange('bank', value)}
          useDataHook={useBanksFromServer}
          cacheKey="provider-select"
        />
        <Button onClick={handleSubmitPress} className="w-full" type="primary">
          Сохранить
        </Button>
      </Space>
    </Drawer>
  );
};
