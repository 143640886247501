import { Dispatch } from 'redux';

import { BackendResponse } from 'types/BackendResponse';

import {
  ActiveOrdersActionTypes,
  UpdateOwnersBindingsToOrderReducerAction,
  ChangeBufferingActiveOrdersReducerAction,
  ChangePaginationActiveOrdersReducerAction,
  GetActiveOrdersReducerAction,
  DisableAutoAssignmentReducerAction,
  BindOperatorReducerAction,
  AddActiveOrderReducerAction,
} from './types';

import { sanitizeRequestParams } from 'utils/sanitizeRequestParams';
import api from 'utils/axios';

import { GetState } from 'store';
import { ActiveOrder } from './interfaces/activeOrder.interface';
import { FetchActiveOrdersParams } from './interfaces/fetchActiveOrdersParams.interface';
import { ChangeStatusOfActiveOrderParams } from './interfaces/changeStatusOfActiveOrderParams.interface';
import { ChangeStatusOfActiveOrderStatusesEnum } from 'modules/orders/enums/changeStatusOfActiveOrderStatuses.enum';
import { RebindOwnersToOrderParams } from 'modules/orders/store/orders/interfaces/rebindOwnersToOrderParams.interface';
import { Order } from 'modules/orders/enums/order.interface';
import { RequestParamsWithPagination } from 'types/requestParamsWithPagination.interface';
import { EXPIRED_ORDER_TIME } from 'constants/dates';

export function getActiveOrders(params: FetchActiveOrdersParams & RequestParamsWithPagination) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch<any>(setLoading());

      const {
        data: { data, meta },
      } = await api.get<BackendResponse<Order[]>>('/orders/processing', {
        params: sanitizeRequestParams(params),
      });

      dispatch<GetActiveOrdersReducerAction>({
        type: ActiveOrdersActionTypes.GET_ACTIVE_ORDERS,
        payload: {
          orders: data,
          pagination: { ...meta, page: meta.page || 1 },
        },
      });
    } catch (err) {
      dispatch<any>(setLoading(false));
    }
  };
}

export function fetchMoreProcessingOrders(params: FetchActiveOrdersParams) {
  return async (dispatch: Dispatch, getState: GetState) => {
    try {
      dispatch<any>(setLoading());

      const { pagination, orders } = getState().activeOrders;

      const {
        data: { data, meta },
      } = await api.get<BackendResponse<Order[]>>('/orders/processing', {
        params: { ...sanitizeRequestParams(params), page: pagination.page + 1 },
      });

      const uniqueOrders = data.filter((el) => !orders.find((value) => el.id === value.id));

      dispatch<GetActiveOrdersReducerAction>({
        type: ActiveOrdersActionTypes.GET_ACTIVE_ORDERS,
        payload: {
          orders: [...orders, ...uniqueOrders],
          pagination: { ...meta, page: meta.page || 1 },
        },
      });
    } catch (err) {
      dispatch<any>(setLoading(false));
    }
  };
}

export function addNewActiveOrder(newOrder: ActiveOrder, filters: FetchActiveOrdersParams, isTableView: boolean) {
  return (dispatch: Dispatch) => {
    if (
      filters?.customersIds &&
      filters?.customersIds.length > 0 &&
      !filters.customersIds.includes(newOrder.customer.id)
    )
      return;

    if (
      filters?.providersIds &&
      filters?.providersIds.length > 0 &&
      !filters.providersIds.includes(newOrder.provider?.id ?? -1)
    )
      return;

    if (filters.excludeProvidersIds?.includes(newOrder.provider?.id ?? -1)) return;

    if (filters.isHighRiskMerchant && !newOrder.customer.isHighRisk) return;

    if (filters?.transactionId) return;

    if (filters?.customerTransactionId) return;

    if (filters?.type && filters.type !== newOrder.type) return;

    if (
      filters?.onlyExpired &&
      Date.now() - Date.parse(newOrder.createdAt) < EXPIRED_ORDER_TIME &&
      newOrder.owners.length > 0
    )
      return;

    dispatch<AddActiveOrderReducerAction>({
      type: ActiveOrdersActionTypes.ADD_ACTIVE_ORDER,
      payload: {
        order: { ...newOrder, new: true },
        isTableView,
      },
    });
  };
}

export function editActiveOrder(editedOrder: ActiveOrder) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: ActiveOrdersActionTypes.EDIT_ACTIVE_ORDER,
      payload: editedOrder,
    });
  };
}

export function changeStatusOfActiveOrder(params: ChangeStatusOfActiveOrderParams) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch<any>(setLoading());

      const { status, orderId, ownersProviderCommissions, transferred } = params;

      if (status === ChangeStatusOfActiveOrderStatusesEnum.DONE) {
        await api.post('/orders/done', { id: orderId, ownersProviderCommissions });
      } else if (status === ChangeStatusOfActiveOrderStatusesEnum.DONE_PARTLY) {
        await api.post('/orders/done-partly', { id: orderId, transferred });
      } else {
        await api.post(`/orders/${orderId}/${status}`);
      }

      dispatch({
        type: ActiveOrdersActionTypes.CHANGE_ORDER_STATUS,
        payload: orderId,
      });
    } catch (err) {
      dispatch<any>(setLoading(false));
    }
  };
}

export function markAsUnknownOrder(id: number, updatedValues: { amount: number; customerName?: string }) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch<any>(setLoading());

      await api.post(`/orders/${id}/unknown`, updatedValues);

      dispatch({
        type: ActiveOrdersActionTypes.MARK_AS_UNKNOWN_ORDER,
        payload: id,
      });
    } catch (err) {
      dispatch<any>(setLoading(false));
    }
  };
}

export function setLoading(payload: boolean = true) {
  return (dispatch: Dispatch) => {
    dispatch({ type: ActiveOrdersActionTypes.SET_LOADING, payload });
  };
}

export function bindOwnersToOrder(id: number, owners: { ownerId: number; sum: number }[]) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch<any>(setLoading());

      const {
        data: { data },
      } = await api.post('/orders/bind-owners-to-order', {
        transactionId: id,
        owners,
      });

      dispatch<any>({
        type: ActiveOrdersActionTypes.BIND_OWNER_TO_ORDER,
        payload: data,
      });
    } catch (err) {
      dispatch<any>(setLoading(false));

      throw err;
    }
  };
}

export function updateOwnersBindingsToOrder(
  order: Order,
  owners: {
    ownerId: number;
    amount: number;
    bankAccountId: number;
  }[]
) {
  return async (dispatch: Dispatch) => {
    try {
      const params: RebindOwnersToOrderParams = {
        id: order.id,
        owners,
      };

      const {
        data: { data },
      } = await api.post('/orders/rebind-owners-to-order', params);

      dispatch<UpdateOwnersBindingsToOrderReducerAction>({
        type: ActiveOrdersActionTypes.UPDATE_OWNERS_BINDINGS_TO_ORDER,
        payload: data[0],
      });
    } catch (e) {
      dispatch<any>(setLoading(false));

      throw e;
    }
  };
}

export function changeBuffering(payload: boolean) {
  return (dispatch: Dispatch) => {
    dispatch<ChangeBufferingActiveOrdersReducerAction>({
      type: ActiveOrdersActionTypes.CHANGE_BUFFERING,
      payload,
    });
  };
}

export function changePagination({ page, perPage }: { page: number; perPage: number }) {
  return (dispatch: Dispatch) => {
    dispatch<ChangePaginationActiveOrdersReducerAction>({
      type: ActiveOrdersActionTypes.CHANGE_PAGINATION,
      payload: { page, perPage },
    });
  };
}

export function disableAutoAssignment(orderId: number) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch<any>(setLoading(true));

      const {
        data: { data },
      } = await api.post('/orders/' + orderId + '/disable-auto-assignment');

      dispatch<DisableAutoAssignmentReducerAction>({
        type: ActiveOrdersActionTypes.DISABLE_AUTO_ASSIGNMENT,
        payload: data,
      });
    } catch (e) {
      dispatch<any>(setLoading(false));

      throw e;
    }
  };
}

export function bindOperator(orderId: number) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch<any>(setLoading(true));

      const {
        data: { data },
      } = await api.post('/orders/' + orderId + '/bind-operator-to-order');
      const operatorId = data;

      dispatch<BindOperatorReducerAction>({
        type: ActiveOrdersActionTypes.BIND_OPERATOR,
        payload: { operatorId, orderId },
      });
    } catch (e) {
      dispatch<any>(setLoading(false));

      throw e;
    }
  };
}

export function changeBindStatus(orderId: number, operatorId?: number) {
  return async (dispatch: Dispatch) => {
    dispatch<BindOperatorReducerAction>({
      type: ActiveOrdersActionTypes.BIND_OPERATOR,
      payload: { operatorId, orderId },
    });
  };
}
