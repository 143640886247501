import { IFilterSchema } from 'components/Filters/types/filterSchema.interface';
import { useBanksFromServer, useCardStatusesFromServer } from 'hooks/useDataFromServer';

export const filtersSchema: IFilterSchema[] = [
  {
    title: 'Номер карты',
    name: 'cardNumber',
    type: 'input',
    allowClear: true,
  },
  {
    title: 'ID владельца',
    name: 'ownerId',
    type: 'input-number',
  },
  {
    title: 'ФИО владельца',
    name: 'ownerFio',
    type: 'input',
    allowClear: true,
    width: 300,
  },
  {
    title: 'Провайдер',
    name: 'bankId',
    type: 'lazy-select',
    allowClear: true,
    useDataHook: useBanksFromServer,
    cacheKey: 'provider-filter',
  },
  {
    title: 'Статус карты',
    name: 'status',
    type: 'lazy-select',
    allowClear: true,
    useDataHook: useCardStatusesFromServer,
    cacheKey: 'card-status',
  },
];
