import React, { FC, useState } from 'react';
import { App, Button, Col, Drawer, Row, Spin, Table, Typography } from 'antd';

import { useTableColumns } from './columns';

import { IBank } from 'modules/banks/types/IBank';

import {
  clearCheckedMainBank,
  clearCheckedMergeBanks,
  clearDisabledCheckedBanks,
  clearMainAndMergeBanks,
  fetchBanks,
  mergeBanks,
} from 'modules/banks/store/banks/actions';
import { useAppDispatch } from 'hooks/useAppDispatch.hook';
import { useAppSelector } from 'hooks/useAppSelector.hook';

interface Props {
  onClose: () => void;
}

export const BanksPageMergeBanks: FC<Props> = ({ onClose }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { message } = App.useApp();

  const columns = useTableColumns();

  const bankMainSelected = useAppSelector((state) => state.banks.duplicates.mainBank);

  const bankMain: IBank[] = bankMainSelected ? [bankMainSelected] : [];
  const banksMerge: IBank[] = useAppSelector((state) => state.banks.duplicates.mergeBanks) ?? [];
  const {
    pagination: { perPage, page },
  } = useAppSelector((state) => state.banks);

  const dispatch = useAppDispatch();

  async function handleMerge(): Promise<void> {
    const arrayMainId = bankMain.map((bank) => bank.id);

    if (!arrayMainId.length) {
      message.error('Не выбран основный банк');
      return;
    }

    const arrayMergeIds = banksMerge.map((bank) => bank.id);

    if (!arrayMergeIds.length) {
      message.error('Не выбраны банки для объединения');
      return;
    }

    setIsLoading(true);

    const params = {
      ids: [...arrayMainId, ...arrayMergeIds],
    };

    try {
      await dispatch(mergeBanks(params));

      message.success('Банки объединены');

      setIsLoading(false);

      await dispatch(fetchBanks({ page, perPage }));
      dispatch(clearMainAndMergeBanks());
      dispatch(clearCheckedMainBank());
      dispatch(clearCheckedMergeBanks());
      dispatch(clearDisabledCheckedBanks());
    } catch {
      message.error('Не удалось объединить банки');
    }

    onClose();
  }

  async function handleClearList(): Promise<void> {
    dispatch(clearMainAndMergeBanks());
    dispatch(clearCheckedMainBank());
    dispatch(clearCheckedMergeBanks());
    dispatch(clearDisabledCheckedBanks());
  }

  return (
    <Drawer open={true} title="Действие: Объединение банков" onClose={onClose} size="large">
      <Spin spinning={isLoading}>
        <Row gutter={8} className="mb-7">
          <Col className="w-full">
            <Typography.Title level={5}>Основной банк</Typography.Title>
            {
              <Table
                columns={columns}
                dataSource={bankMain}
                loading={isLoading}
                pagination={false}
                rowKey={(record) => '1' + record.id}
              />
            }
          </Col>
        </Row>
        <Row gutter={8} className="mb-7">
          <Col className="w-full">
            <Typography.Title level={5}>Вливаемые банки</Typography.Title>
            {banksMerge && (
              <Table
                columns={columns}
                dataSource={banksMerge}
                loading={isLoading}
                pagination={false}
                rowKey={(record) => '2' + record.id}
              />
            )}
          </Col>
        </Row>
        <Row gutter={8} className="mb-3 mt-3 justify-between">
          <Col>
            <Button onClick={handleClearList}>Очистить список</Button>
          </Col>
          <Col>
            <Button type="primary" onClick={handleMerge}>
              Объединить
            </Button>
          </Col>
        </Row>
      </Spin>
    </Drawer>
  );
};
