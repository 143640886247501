import { Dispatch } from 'redux';

import api from 'utils/axios';

import { IMetaPagination } from 'types/IMetaPagination';
import { OrdersCreateParams } from './interfaces/ordersCreateParams.interface';
import { OrderUpdateParams } from './interfaces/orderUpdateParams.interface';
import { FetchOrdersParams } from './interfaces/fetchOrdersParams.interface';
import {
  AddOrderReducerAction,
  EditOrderReducerAction,
  GetOrdersReducerAction,
  OrdersActionsTypes,
  SetIsLoadingOrdersReducerAction,
} from 'modules/orders/store/orders/types';
import { Order } from 'modules/orders/enums/order.interface';

export function fetchOrders(params: FetchOrdersParams) {
  return async (dispatch: Dispatch<any>) => {
    try {
      dispatch(setIsLoadingOrders(true));

      const {
        data: { data, meta },
      } = await api.get('/orders', { params });

      dispatch<GetOrdersReducerAction>({
        type: OrdersActionsTypes.GET_ORDERS,
        payload: {
          orders: data as Order[],
          pagination: meta as IMetaPagination,
        },
      });
    } finally {
      dispatch(setIsLoadingOrders());
    }
  };
}

export function createOrder(params: OrdersCreateParams) {
  return async (dispatch: Dispatch<any>) => {
    try {
      dispatch(setIsLoadingOrders(true));

      const {
        data: { data: order },
      } = await api.post('/orders/create', { ...params });

      dispatch<AddOrderReducerAction>({
        type: OrdersActionsTypes.ADD_ORDER,
        payload: order[0] as Order,
      });
    } finally {
      dispatch(setIsLoadingOrders());
    }
  };
}

export function editOrder(params: OrderUpdateParams) {
  return async (dispatch: Dispatch<any>) => {
    try {
      dispatch(setIsLoadingOrders(true));

      const {
        data: { data },
      } = await api.post('/orders/update', params);

      dispatch<EditOrderReducerAction>({
        type: OrdersActionsTypes.EDIT_ORDER,
        payload: data[0] as Order,
      });
    } finally {
      dispatch(setIsLoadingOrders());
    }
  };
}

export function setIsLoadingOrders(payload: boolean = false) {
  return (dispatch: Dispatch<any>) => {
    dispatch<SetIsLoadingOrdersReducerAction>({
      type: OrdersActionsTypes.SET_IS_LOADING,
      payload,
    });
  };
}
