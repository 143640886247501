import React, { useEffect, type PropsWithChildren, useMemo } from 'react';

import { useTheme } from 'contexts';
import { ConfigProvider, theme } from 'antd';

export const ThemeProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { isDarkMode, currentTheme } = useTheme();

  // const [animationActive, setAnimationActive] = useState(false);

  useEffect(() => {
    document.body.style.color = currentTheme.token.colorTextBase;
    document.body.style.backgroundColor = currentTheme.token.colorBgBody;

    const htmlElement = document.querySelector('html');

    if (isDarkMode) {
      document.body.classList.add('dark-theme');
      document.body.classList.remove('light-theme');
      htmlElement?.classList.add('dark-theme');
      htmlElement?.classList.remove('light-theme');
      document.getElementById('root')?.classList.add('dark-mode');
      document.getElementById('root')?.classList.remove('light-mode');
      // setAnimationActive(true);
    } else {
      document.body.classList.add('light-theme');
      document.body.classList.remove('dark-theme');
      htmlElement?.classList.add('light-theme');
      htmlElement?.classList.remove('dark-theme');
      document.getElementById('root')?.classList.add('light-mode');
      document.getElementById('root')?.classList.remove('dark-mode');
      // setAnimationActive(false);
    }
  }, [currentTheme, isDarkMode]);

  const { defaultAlgorithm, darkAlgorithm } = theme;

  const currentThemeConfig = useMemo(() => {
    return {
      algorithm: isDarkMode ? darkAlgorithm : defaultAlgorithm,
      hashed: false,
      ...currentTheme,
    };
  }, [currentTheme]);

  return (
    <ConfigProvider theme={currentThemeConfig}>
      {children}
      {/* // TODO: разобраться с анимацией, потом удалить тут и строки выше комментарии
       <div
        className={`change-theme-animation w-250vw h-250vw absolute top-0 left-0 
        right-0 bottom-0 bg-red-500 z-10 rounded-full transition duration-3000 
        ease-in-out transform scale-0 flex flex-nowrap ${animationActive ? 'active' : ''}`}
      ></div> */}
    </ConfigProvider>
  );
};
