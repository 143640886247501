import React, { useEffect, useState } from 'react';
import { Image, Space, Spin } from 'antd';

import { OwnerLinkedByEnum } from 'modules/owners/types/IOwner';
import { downloadFileAsBase64 } from 'utils/downloadFileAsBase64';
import { PdfPreview } from 'components/PdfPreview';
import { useTheme } from 'contexts';
import { OrderOwnerDescriptionRequisites } from 'modules/orders/components/OrderOwnerDescription/components/OrderOwnerDescriptionRequisites';
import { utcToMoscowDateString } from 'utils/utcToMoscowDateString.util';
import { OrderOwner, ReceiptMatchStatus } from 'modules/orders/enums/order.interface';
import { OrderTypes } from 'modules/orders/enums/orderTypes.enum';

interface Props {
  owner: OrderOwner;
  orderType: OrderTypes;
  orderId: number;
  paymentStatus?: string;
}

export const OrderOwnerDescription: React.FC<Props> = ({ owner, orderType, orderId, paymentStatus }) => {
  const {
    ownerId,
    fio,
    amount,
    linkedBy,
    providerCommission,
    cardNumber,
    bankAccountNumber,
    bic,
    paidAt,
    checkStatuses,
  } = owner;

  const [receiptsBase64, setReceipsBase64] = useState<string[]>([]);

  const {
    currentTheme: {
      token: { colorBorderInput },
    },
  } = useTheme();

  useEffect(() => {
    prepareReceipts();
  }, []);

  async function prepareReceipts() {
    if (!owner.receiptUrls) {
      return;
    }

    const promises = owner.receiptUrls.map((url) => downloadFileAsBase64(url));

    setReceipsBase64((await Promise.all(promises)) as string[]);
  }

  function getReceiptStatus(receiptStatus: ReceiptMatchStatus): string {
    switch (receiptStatus) {
      case ReceiptMatchStatus.MATCHED:
        return '✅';
      case ReceiptMatchStatus.NOT_MATCHED:
      case ReceiptMatchStatus.UNRECOGNIZED:
        return '❌';
      default:
        return 'В ожидании';
    }
  }

  return (
    <div className="flex flex-row w-full border rounded-sm p-2" style={{ borderColor: colorBorderInput }}>
      <div className="flex flex-col w-full">
        <div className="w-full p-6 py-2 text-base font-medium">Владелец №{ownerId}</div>
        <div className="flex flex-1 px-6 py-2 items-center">
          <ul>
            {linkedBy === OwnerLinkedByEnum.OUT_AUTO_ASSIGMENT && (
              <li>
                <b>Привязан автоматически</b>
              </li>
            )}
            <li>ФИО: {fio}</li>
            {OrderTypes.INCOMING === orderType && (
              <OrderOwnerDescriptionRequisites
                cardNumber={cardNumber}
                bankAccountNumber={bankAccountNumber}
                bic={bic}
              />
            )}
            {!!providerCommission && <li>Комиссия банка: {providerCommission.toLocaleString('ru-RU')}</li>}
            <li>
              Сумма для владельца: <span className="font-semibold">{Number(amount).toLocaleString('ru-RU')}</span>
            </li>
            {paymentStatus && (
              <li>
                Статус платежа: <span className="font-semibold">{paymentStatus}</span>
              </li>
            )}
            {paidAt && <li>Дата и время выполнения (МСК): {utcToMoscowDateString(paidAt)}</li>}
            {OrderTypes.OUTCOMING === orderType && (
              <>
                <li className="flex items-center">
                  <span className="mr-1">Подтверждение от банка:</span>
                  {checkStatuses.sms ? (
                    <a
                      className="flex items-center justify-center w-5 h-5 rounded-full white bg-green-600 border-green-600 hover:bg-green-500 font-bold"
                      style={{ color: 'white', fontSize: '8px' }}
                      href={`/owners/sms?orderId=${orderId}`}
                    >
                      СМС
                    </a>
                  ) : (
                    'В ожидании'
                  )}
                </li>
                <li>
                  <span className="mr-1">Чек проверен: </span>
                  {getReceiptStatus(checkStatuses.receipt)}
                </li>
              </>
            )}
          </ul>
        </div>
      </div>
      <Space>
        {(owner.receiptUrls?.length ?? 0) > 0 && receiptsBase64.length === 0 && <Spin />}
        {receiptsBase64.map((base64, index) => (
          <Space direction="vertical" align="center" key={index}>
            <div>Чек №{index + 1}</div>
            {base64.includes('image') ? (
              <Image src={base64} preview height={100} width={100} />
            ) : (
              <PdfPreview src={base64} width={100} height={100} />
            )}
          </Space>
        ))}
      </Space>
    </div>
  );
};
