import { MerchantReducerActions, MerchantsActionTypes, IChangePagePayload } from './types';

import { MerchantsState } from './interfaces/merchantsState.interface';
import { FetchMerchantsPayload } from './interfaces/fetchMerchantsPayload.interface';
import { Merchant } from 'modules/merchants/types/merchant.interface';

const initialState: MerchantsState = {
  merchants: [],
  isLoading: false,
  pagination: {
    page: 1,
    pages: 1,
    perPage: 20,
    total: 0,
  },
};

export function merchantsReducer(state = initialState, { type, payload }: MerchantReducerActions): MerchantsState {
  switch (type) {
    case MerchantsActionTypes.FETCH_MERCHANTS: {
      const { merchants, pagination } = payload as FetchMerchantsPayload;

      return { ...state, merchants, pagination };
    }
    case MerchantsActionTypes.CREATE_MERCHANT: {
      const merchant = payload as Merchant;

      return { ...state, merchants: [merchant, ...state.merchants] };
    }
    case MerchantsActionTypes.DELETE_MERCHANT: {
      const deletedMerchantId = payload as number;

      const filteredMerchants = state.merchants.filter((merchant) => {
        return merchant.id !== deletedMerchantId;
      });

      return { ...state, merchants: [...filteredMerchants] };
    }
    case MerchantsActionTypes.SET_LOADING:
      return { ...state, isLoading: payload as boolean };
    case MerchantsActionTypes.CHANGE_PAGE: {
      const { page, perPage } = payload as IChangePagePayload;

      return {
        ...state,
        pagination: {
          ...state.pagination,
          page: page,
          perPage,
        },
      };
    }
    default:
      return state;
  }
}
