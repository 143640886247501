import React, { FC, useState } from 'react';
import { Form, Button, Spin, Select, Space, Tooltip, Row, App } from 'antd';
import { useForm } from 'antd/lib/form/Form';

import { BanksPageCreateBankTable } from './components/CreateBankTable';
import { Input } from 'components/Input';

import { ICreateBankParams } from 'modules/banks/store/banks/types';
import { fetchBankByBin } from 'modules/banks/store/banks/actions';

import { ProtectedComponent } from 'policies/components/ProtectedComponent';

import { selectedBinProviders } from './constants';
import { permissions } from 'policies/permissions';
import { useAppSelector } from 'hooks/useAppSelector.hook';
import { useAppDispatch } from 'hooks/useAppDispatch.hook';

interface Props {
  onClose: () => void;
  onEdit: () => void;
}

export const BanksPageCreateBankForm: FC<Props> = ({ onEdit }) => {
  const [form] = useForm<ICreateBankParams>();

  const { message } = App.useApp();

  const [isLoading, setIsLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState(selectedBinProviders[0].value);

  const foundBinBankModal = useAppSelector((state) => state.banks.foundBinBank);

  const foundBinBankId = foundBinBankModal?.id;

  const foundBinBank = useAppSelector(
    (state) => state.banks.banks.filter((bank) => bank.id === foundBinBankId)[0] || null
  );

  const dispatch = useAppDispatch();

  async function handleSearch(): Promise<void> {
    try {
      setIsLoading(true);

      const filteredNumbers = [form.getFieldValue('bin1'), form.getFieldValue('bin2'), form.getFieldValue('bin3')]
        .filter((item) => !isNaN(item) && item)
        .map((item) => Number(item));

      const values = {
        bins: filteredNumbers,
        source: selectedOption,
      };

      await dispatch(fetchBankByBin(values));

      message.success('Банк успешно добавлен!');
    } catch (error) {
      message.error('Произошла ошибка');
    } finally {
      setIsLoading(false);
    }
  }

  function handleBinSourceChange(value: React.SetStateAction<string>) {
    setSelectedOption(value);
  }

  return (
    <Spin spinning={isLoading}>
      <Form form={form}>
        <Form.Item
          name="bin1"
          rules={[
            {
              pattern: /^[0-9]{6}$/,
              message: 'BIN должен состоять из 6 цифр',
            },
          ]}
        >
          <Input className="mb-2" addonBefore="BIN 1 банка" required />
        </Form.Item>
        <Form.Item
          name="bin2"
          rules={[
            {
              pattern: /^[0-9]{6}$/,
              message: 'BIN должен состоять из 6 цифр>',
            },
          ]}
        >
          <Input className="mb-2" addonBefore="BIN 2 банка" required={false} />
        </Form.Item>
        <Form.Item
          name="bin3"
          rules={[
            {
              pattern: /^[0-9]{6}$/,
              message: 'BIN должен состоять из 6 цифр>',
            },
          ]}
        >
          <Input className="mb-2" addonBefore="BIN 3 банка" required={false} />
        </Form.Item>
        <div className="flex items-center mb-2 ml-3">
          <label className="w-48">Источник</label>
          <Select
            className="w-full"
            value={selectedOption}
            options={selectedBinProviders}
            allowClear
            onChange={handleBinSourceChange}
          />
        </div>
        <Button className="mt-5 mb-5" type="primary" onClick={handleSearch}>
          Создать
        </Button>
      </Form>

      {foundBinBank && (
        <>
          <BanksPageCreateBankTable bank={foundBinBank} />

          <Row justify="space-between">
            <Space>
              <ProtectedComponent requiredPermissions={[permissions.bank.update]}>
                <Tooltip title="Редактировать банк">
                  <Button type="primary" onClick={() => onEdit()}>
                    Редактировать
                  </Button>
                </Tooltip>
              </ProtectedComponent>
            </Space>
          </Row>
        </>
      )}
    </Spin>
  );
};
