import React, { useState } from 'react';
import { Drawer, Form, Button, Spin, App } from 'antd';
import { useForm } from 'antd/lib/form/Form';

import { ICreateUserParams } from 'modules/users/store/users/types';
import { createUser } from 'modules/users/store/users/actions';

import { useRolesFromServer } from 'hooks/useDataFromServer';
import { useAppDispatch } from 'hooks/useAppDispatch.hook';
import { Input } from 'components/Input';
import { LazySelect } from 'components/LazySelect';

interface Props {
  isShown: boolean;
  onClose: (isSubmitted?: boolean) => void;
  isRolesEditable?: boolean;
  agentId?: number;
}

export const UsersPageCreateDrawer: React.FC<Props> = ({ isShown, onClose, isRolesEditable = true, agentId }) => {
  const [form] = useForm<ICreateUserParams>();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { message } = App.useApp();

  const dispatch = useAppDispatch();

  async function handleSubmit(): Promise<void> {
    setIsLoading(true);
    let values = form.getFieldsValue();

    if (agentId) {
      values = { ...values, agentId };
    }

    try {
      await dispatch(createUser(values));

      message.success('Пользователь успешно создан!');

      onClose(true);
    } catch {
      message.error('Не удалось создать пользователя');
    } finally {
      setIsLoading(false);
    }
  }

  function handleClose() {
    form.resetFields();
    onClose();
  }

  return (
    <Drawer open={isShown} title="Создание пользователя" onClose={handleClose}>
      <Spin spinning={isLoading}>
        <Form form={form}>
          <Input className="mb-2" addonBefore="Имя" name="name" />
          <Input className="mb-2" addonBefore="Email" name="email" />
          <Input className="mb-2" addonBefore="Пароль" name="password" />
          <Input className="mb-2" addonBefore="Ник Telegram" name="telegramUsername" />
          {isRolesEditable && (
            <Form.Item name="roles" label="Роли">
              <LazySelect mode="multiple" cacheKey="roles-select" useDataHook={useRolesFromServer} />
            </Form.Item>
          )}
          <Button type="primary" onClick={handleSubmit}>
            Создать
          </Button>
        </Form>
      </Spin>
    </Drawer>
  );
};
