import { Button, Flex, Form, Row, Space, Typography } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import dayjs, { Dayjs } from 'dayjs';
import React, { ReactElement, useEffect, useMemo, useState } from 'react';

import { FiltersItem } from './components/Item';

import { CustomButtonsFunction } from './types/customButtonsFunction.type';
import { IFilterSchema } from './types/filterSchema.interface';
import { FiltersValue } from './types/filtersValue.type';

import { EnterPressHandlerProvider } from 'components/Filters/components/EnterPressHandlerProvider/EnterPressHandlerProvider';
import { DEFAULT_DATETIME_STRING_FORMAT } from 'constants/dates';

interface Props {
  headerTitle: string;
  filters: IFilterSchema[];
  customFilters?: ReactElement | CustomButtonsFunction;
  value: FiltersValue;
  onSubmit: (values: FiltersValue) => void;
  customButtons?: ReactElement | CustomButtonsFunction;
  extraHeaderButtons?: ReactElement | CustomButtonsFunction;
  extraHeaderViewButtons?: ReactElement | CustomButtonsFunction;
  pauseButton?: ReactElement | CustomButtonsFunction;
  submitButtonText?: string;
  hideHeader?: boolean;
  onClear?: () => void;
}

export const Filters: React.FC<Props> = ({
  value: externalValue,
  onSubmit,
  filters,
  customFilters,
  customButtons,
  extraHeaderButtons,
  extraHeaderViewButtons,
  headerTitle,
  pauseButton,
  submitButtonText,
  hideHeader,
  onClear,
}) => {
  const [form] = useForm<FiltersValue>();

  const [isFiltersShown, setIsFiltersShown] = useState<boolean>(false);

  const classToggle = isFiltersShown ? 'hidden' : '';

  useEffect(() => {
    form.setFieldsValue(denormalizeFilters(externalValue));
  }, [externalValue]);

  const preparedToRenderExtraHeaderButtons = useMemo(() => {
    if (!(extraHeaderButtons as CustomButtonsFunction | undefined)?.call) {
      return extraHeaderButtons as ReactElement | undefined;
    }

    return (extraHeaderButtons as CustomButtonsFunction)(() => normalizeFilters(form.getFieldsValue()));
  }, [extraHeaderButtons]);

  const preparedToRenderExtraHeaderViewButtons = useMemo(() => {
    if (!(extraHeaderViewButtons as CustomButtonsFunction | undefined)?.call) {
      return extraHeaderViewButtons as ReactElement | undefined;
    }

    return (extraHeaderViewButtons as CustomButtonsFunction)(() => normalizeFilters(form.getFieldsValue()));
  }, [extraHeaderViewButtons]);

  const preparedToRenderCustomFilters = useMemo(() => {
    if (!(customFilters as CustomButtonsFunction | undefined)?.call) {
      return customFilters as ReactElement | undefined;
    }

    return (customFilters as CustomButtonsFunction)(() => normalizeFilters(form.getFieldsValue()));
  }, [customFilters]);

  const preparedToRenderButtons = useMemo(() => {
    if (!(customButtons as CustomButtonsFunction | undefined)?.call) {
      return customButtons as ReactElement | undefined;
    }

    return (customButtons as CustomButtonsFunction)(() => normalizeFilters(form.getFieldsValue()));
  }, [customButtons]);

  const preparedToRenderPauseButton = useMemo(() => {
    if (!(pauseButton as CustomButtonsFunction | undefined)?.call) {
      return pauseButton as ReactElement | undefined;
    }

    return (pauseButton as CustomButtonsFunction)(() => normalizeFilters(form.getFieldsValue()));
  }, [pauseButton]);

  function denormalizeFilters(value: FiltersValue): FiltersValue {
    const denormalized = { ...value };

    filters.forEach((schema) => {
      const { type, name } = schema;

      if (type === 'date-picker' || type == 'date-picker-showtime') {
        const [fromKey, toKey] = schema.rangeFields;

        const fromRaw = value[fromKey];
        const toRaw = value[toKey];

        const format = schema.dateFormat ?? DEFAULT_DATETIME_STRING_FORMAT;

        if (fromRaw && toRaw) {
          const from = dayjs(fromRaw as string, format);
          const to = dayjs(toRaw as string, format);

          denormalized[name] = [from, to];
        }

        delete denormalized[fromKey];
        delete denormalized[toKey];
      }
    });

    return denormalized;
  }

  function normalizeFilters(value: FiltersValue): FiltersValue {
    const normalized = { ...value };

    filters.forEach((schema) => {
      const { type, name } = schema;

      if (type === 'date-picker' || type === 'date-picker-showtime') {
        const { rangeFields, dateFormat } = schema;

        const [from, to] = (value[name] ?? []) as [Dayjs, Dayjs];
        const [fromKey, toKey] = rangeFields;

        const format = dateFormat ?? DEFAULT_DATETIME_STRING_FORMAT;

        if (from && to) {
          if (type === 'date-picker') {
            normalized[fromKey] = from.startOf('day').format(format);
            normalized[toKey] = to.endOf('day').format(format);
          } else {
            normalized[fromKey] = from.format(format);
            normalized[toKey] = to.format(format);
          }
        }

        delete normalized[name];
      }
    });

    return normalized;
  }

  function handleSubmit(): void {
    const values = form.getFieldsValue();
    onSubmit(normalizeFilters(values));
  }

  function handleClear(): void {
    form.resetFields();
    onClear?.();
  }

  return (
    <>
      <Space className="block mb-7">
        <Flex justify="space-between" align="baseline" className={`mb-6 ${hideHeader && 'hidden'}`}>
          <Flex justify="start" align="center">
            <Typography.Title className="mr-4">{headerTitle}</Typography.Title>
            {preparedToRenderExtraHeaderButtons}
          </Flex>
          <Flex justify="start" align="center">
            {preparedToRenderExtraHeaderViewButtons}
            <Button
              type="default"
              icon={isFiltersShown ? <CaretDownOutlined /> : <CaretUpOutlined />}
              onClick={() => setIsFiltersShown(!isFiltersShown)}
            >
              Фильтры
            </Button>
          </Flex>
        </Flex>
        <div className={classToggle}>
          <Form form={form}>
            <EnterPressHandlerProvider onEnterPress={handleSubmit}>
              <Row className="mb-4">
                <Space direction="horizontal" wrap>
                  {filters.map((filter) => (
                    <FiltersItem schema={filter} key={filter.name} defaultValue={externalValue[filter.name]} />
                  ))}
                  {preparedToRenderCustomFilters}
                </Space>
              </Row>
            </EnterPressHandlerProvider>
            <Row className="mb-4">
              <Space direction="horizontal">
                <Button type="primary" onClick={handleSubmit}>
                  {submitButtonText || 'Применить'}
                </Button>
                <Button onClick={handleClear}>Сбросить</Button>
                {preparedToRenderPauseButton}
              </Space>
            </Row>
            <Row className="mb-4">
              <Space direction="horizontal" wrap>
                {preparedToRenderButtons}
              </Space>
            </Row>
          </Form>
        </div>
      </Space>
    </>
  );
};
