import React, { FC, useEffect, useState } from 'react';
import { Drawer } from 'antd';

import { BanksPageCreateBankForm } from '../CreateBankForm';
import { BanksPageEditBankForm } from '../EditBankForm';
import { useAppSelector } from 'hooks/useAppSelector.hook';

interface Props {
  onClose: () => void;
}

export const BanksPageCreateBankContainer: FC<Props> = ({ onClose }) => {
  const [isShowCreateBank, setIsShowCreateBank] = useState(true);
  const [isEditCreateBank, setIsEditCreateBank] = useState(false);
  const [drawerSize, setDrawerSize] = useState<'default' | 'large' | undefined>('default');

  const foundBinBank = useAppSelector((state) => {
    return state.banks.foundBinBank;
  });

  function onEdit() {
    setIsShowCreateBank(false);
    setIsEditCreateBank(true);
  }

  useEffect(() => {
    if (isShowCreateBank) {
      setDrawerSize('default');
    } else if (isEditCreateBank) {
      setDrawerSize('large');
    }
  }, [isShowCreateBank, isEditCreateBank]);

  return (
    <Drawer title="Действие: Cоздание банка" open={true} onClose={onClose} size={drawerSize}>
      {isShowCreateBank && <BanksPageCreateBankForm onClose={onClose} onEdit={onEdit} />}
      {isEditCreateBank && foundBinBank !== null && <BanksPageEditBankForm bank={foundBinBank} onClose={onClose} />}
    </Drawer>
  );
};
