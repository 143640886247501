import React, { useMemo } from 'react';
import { ColumnType } from 'antd/lib/table';
import { Space, Button, Tooltip, Image, Checkbox, App } from 'antd';
import { CheckOutlined, CloseOutlined, EditFilled } from '@ant-design/icons';

import { IBank } from 'modules/banks/types/IBank';
import { ProtectedComponent } from 'policies/components/ProtectedComponent';

import { BanksPageTableCheckSwitchColumn } from './components/CheckSwitchColumn';

import {
  addMainBankToRemoveDuplicate,
  addMergeBanksToRemoveDuplicate,
  clearCheckedMainBank,
  disabledCheckedMainBank,
  disabledCheckedMergeBank,
  removeMainBankToRemoveDuplicate,
  removeMergeBanksToRemoveDuplicate,
  updateCheckedMainBank,
  updateCheckedMergeBank,
} from 'modules/banks/store/banks/actions';
import { permissions } from 'policies/permissions';
import { useAppDispatch } from 'hooks/useAppDispatch.hook';

interface Config {
  onBankEditPress: (bank: IBank) => void;
  isSelectColumnShown: boolean;
}

export function useTableColumns({ onBankEditPress, isSelectColumnShown }: Config) {
  const dispatch = useAppDispatch();

  const { message } = App.useApp();

  function handleAddMainBank(record: IBank) {
    if (record.checkedMain) {
      dispatch(clearCheckedMainBank());

      dispatch(disabledCheckedMergeBank(record));
      dispatch(updateCheckedMergeBank(record));

      dispatch(addMainBankToRemoveDuplicate(record));
      dispatch(updateCheckedMainBank(record));
    } else {
      dispatch(removeMainBankToRemoveDuplicate());
      dispatch(updateCheckedMainBank(record));
    }
  }

  function handleAddMergeBank(record: IBank) {
    if (record.internal) {
      message.error('Внутренний банк не может быть добавлен вторым в список для слияния!');
    } else {
      if (record.checkedMerge) {
        dispatch(disabledCheckedMainBank(record));
        dispatch(updateCheckedMainBank(record));

        dispatch(addMergeBanksToRemoveDuplicate(record));
        dispatch(updateCheckedMainBank(record));
      } else {
        dispatch(removeMergeBanksToRemoveDuplicate(record));
        dispatch(updateCheckedMergeBank(record));
      }
    }
  }

  return useMemo<ColumnType<IBank>[]>(() => {
    const columns: ColumnType<IBank>[] = [
      {
        title: 'Id банка',
        dataIndex: 'id',
        key: 'id',
      },
      {
        title: 'Название банка',
        dataIndex: 'name',
        key: 'name',
        render: (_, { name, nameEn }) => name || nameEn,
      },
      {
        title: 'Внутренний/внешний банк',
        dataIndex: 'internal',
        key: 'internal',
        align: 'center',
        render: (_, { internal }) => {
          if (internal) {
            return (
              <Tooltip title="Внутренний банк">
                <CheckOutlined className="text-green-700" />
              </Tooltip>
            );
          }

          return (
            <Tooltip title="Внешний банк">
              <CloseOutlined className="text-red-700" />
            </Tooltip>
          );
        },
      },
      {
        title: 'Включение/отключение банка',
        dataIndex: 'enabled',
        key: 'enabled',
        align: 'center',
        render: (_, bank: IBank) => (
          <ProtectedComponent requiredPermissions={[permissions.bank.update]}>
            <BanksPageTableCheckSwitchColumn bank={bank} />
          </ProtectedComponent>
        ),
      },
      {
        title: 'Код валюты',
        dataIndex: 'currencyCode',
        key: 'currencyCode',
        align: 'center',
      },
      {
        title: 'Список картинок',
        dataIndex: 'imageUrl',
        key: 'imageUrl',
        render: (_, { imageUrl, name }) => imageUrl && <Image src={imageUrl} width={60} preview={false} alt={name} />,
        width: 100,
      },
      {
        title: 'Действия',
        key: 'actions',
        render: (_, bank) => (
          <Space>
            <ProtectedComponent requiredPermissions={[permissions.bank.update]}>
              <Tooltip title="Редактировать банк">
                <Button type="primary" shape="circle" onClick={() => onBankEditPress(bank)}>
                  <EditFilled />
                </Button>
              </Tooltip>
            </ProtectedComponent>
          </Space>
        ),
      },
    ];

    if (isSelectColumnShown) {
      columns.unshift(
        {
          title: 'Главный банк',
          dataIndex: 'checkedMain',
          key: 'checkedMain',
          align: 'center',
          render: (_, record) => (
            <Space>
              <Tooltip title="Выбрать основной банк">
                <Checkbox
                  checked={record.checkedMain || false}
                  disabled={record.disabledMain || false}
                  onChange={() =>
                    handleAddMainBank({
                      ...record,
                      checkedMain: !record.checkedMain,
                      disabledMerge: !record.disabledMerge,
                    })
                  }
                ></Checkbox>
              </Tooltip>
            </Space>
          ),
        },
        {
          title: 'Дополнительные банки',
          dataIndex: 'checkedMerge',
          key: 'checkedMerge',
          align: 'center',
          render: (_, record) => (
            <Space>
              <Tooltip title="Выбрать дополнительный банк">
                <Checkbox
                  checked={record.checkedMerge || false}
                  disabled={record.disabledMerge || false}
                  onChange={() =>
                    handleAddMergeBank({
                      ...record,
                      checkedMerge: !record.checkedMerge,
                      disabledMain: !record.disabledMain,
                    })
                  }
                ></Checkbox>
              </Tooltip>
            </Space>
          ),
        }
      );
    }

    return columns;
  }, [isSelectColumnShown]);
}
