import { IMetaPagination } from 'types/IMetaPagination';
import { ReducerAction } from 'types/reducerAction.interface';

export interface IOwnerCard {
  id: number;
  ownerId: number;
  cardNumber: string;
  bank: IOwnerCardBank;
  status: boolean;
  ownerFio: string;
  isDeleted: boolean;
  bankAccountId: number;
}

export interface EditOwnerCardParams {
  id: number;
  ownerId: number;
  bank: number;
  status: boolean;
  card?: string;
}

export interface CreateOwnerCardParams {
  accountId: number;
  cardNumber: string;
}

export interface DeleteOwnerCardParams {
  id: number;
}

export interface IOwnerCardBank {
  id: number;
  images: {
    image: string;
    logoBigSize: string;
    logoSmallSize: string;
    logoInvertBigSize: string;
    logoInvertSmallSize: string;
    backgroundColors: string[];
  };
  name: string;
}

export interface IFetchOwnersCardsParams {
  ownerId?: number;
  ownerFio?: string;
  cardNumber?: string;
  status?: number;
  bank?: number;
  page?: number;
  perPage?: number;
}

export interface IFetchOwnersCardsPayload {
  cards: IOwnerCard[];
  pagination: IMetaPagination;
}

export interface IOwnersCardsState {
  cards: IOwnerCard[];
  isLoading: boolean;
  pagination: IMetaPagination;
}

export type ISetLoadingReducerAction = ReducerAction<boolean, OwnersCardsActionsTypes.SET_LOADING>;

export type IFetchOwnersCardsReducerAction = ReducerAction<
  IFetchOwnersCardsPayload,
  OwnersCardsActionsTypes.FETCH_OWNERS_CARDS
>;

export type IChangePageOwnersCardsReducerActionPayload = {
  page: number;
  perPage: number;
};

export type IChangePageOwnersCardsReducerAction = ReducerAction<
  IChangePageOwnersCardsReducerActionPayload,
  OwnersCardsActionsTypes.CHANGE_PAGE
>;

export type CreateOwnerCardReducerAction = ReducerAction<IOwnerCard, OwnersCardsActionsTypes.CREATE_OWNER_CARD>;

export type DeleteOwnerCardReducerAction = ReducerAction<number, OwnersCardsActionsTypes.DELETE_OWNER_CARD>;

export type EditOwnerCardReducerAction = ReducerAction<IOwnerCard, OwnersCardsActionsTypes.EDIT_OWNER_CARD>;

export type OwnersCardsReducersActions =
  | ISetLoadingReducerAction
  | DeleteOwnerCardReducerAction
  | IFetchOwnersCardsReducerAction
  | CreateOwnerCardReducerAction
  | IChangePageOwnersCardsReducerAction
  | EditOwnerCardReducerAction;

export enum OwnersCardsActionsTypes {
  FETCH_OWNERS_CARDS = 'FETCH_OWNERS_CARDS',
  CREATE_OWNER_CARD = 'CREATE_OWNER_CARD',
  DELETE_OWNER_CARD = 'DELETE_OWNER_CARD',
  EDIT_OWNER_CARD = 'EDIT_OWNER_CARD',
  SET_LOADING = 'SET_LOADING',
  CHANGE_PAGE = 'CHANGE_PAGE',
}
