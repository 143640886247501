import React, { useMemo } from 'react';
import { ColumnType } from 'antd/lib/table';
import { Tooltip } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

import { IBank } from 'modules/banks/types/IBank';

export function useTableColumns() {
  return useMemo<ColumnType<IBank>[]>(
    () => [
      {
        title: 'Id банка',
        dataIndex: 'id',
        key: 'id',
      },
      {
        title: 'Название банка',
        dataIndex: 'name',
        key: 'name',
        render: (_, { name, nameEn }) => name || nameEn,
      },
      {
        title: 'Внутренний/внешний банк',
        dataIndex: 'internal',
        key: 'internal',
        align: 'center',
        render: (_, { internal }) => {
          if (internal) {
            return (
              <Tooltip title="Внутренний банк">
                <CheckOutlined className="text-green-700" />
              </Tooltip>
            );
          }

          return (
            <Tooltip title="Внешний банк">
              <CloseOutlined className="text-red-700" />
            </Tooltip>
          );
        },
      },
      {
        title: 'Код валюты',
        dataIndex: 'currencyCode',
        key: 'currencyCode',
        align: 'center',
      },
    ],
    []
  );
}
