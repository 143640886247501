import React, { useState } from 'react';

import { App, Button, Form, Input } from 'antd';
import { useForm } from 'antd/lib/form/Form';

import { useAppDispatch } from 'hooks/useAppDispatch.hook';
import { updateCardCount } from 'modules/bankAccounts/store';

import instance from 'utils/axios';
import { CreateBankAccountCardParams } from 'modules/bankAccounts/pages/list/components/CardsList/components/AddCard/types/createBankAccountCard.interface';

interface Props {
  bankAccountId: number;
  onSubmit: () => void;
}

export const BankAccountListPageCardsListAddCard: React.FC<Props> = ({ bankAccountId, onSubmit }) => {
  const [showAddCard, setShowAddCard] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [form] = useForm<CreateBankAccountCardParams>();

  const { message } = App.useApp();

  const dispatch = useAppDispatch();

  async function handleAddCard() {
    const cardNumber = form.getFieldValue('cardNumber');

    try {
      setIsLoading(true);

      await instance.post('/cards/create', {
        accountId: bankAccountId,
        cardNumber,
      });

      message.success('Карта добавлена');

      form.resetFields();

      onSubmit();
      dispatch(updateCardCount({ bankAccountId, type: 'increment' }));
    } catch {
      message.error('Не удалось добавить карту');
    } finally {
      handleCancel();
    }
  }

  function handleCancel() {
    form.resetFields();
    setShowAddCard(false);
    setIsLoading(false);
  }

  return (
    <div>
      {!showAddCard && (
        <Button type="default" onClick={() => setShowAddCard(true)}>
          + Добавить карту
        </Button>
      )}
      {showAddCard && (
        <Form form={form}>
          <div className="flex">
            <Form.Item
              className="mb-0 mr-6"
              name="cardNumber"
              rules={[{ required: true, message: 'Пожалуйста, введите номер карты!' }]}
            >
              <Input
                placeholder="0000 0000 0000 0000"
                addonBefore="Номер карты"
                className="w-full leading-6"
                disabled={isLoading}
              />
            </Form.Item>
            <Button type="default" className="mr-2" onClick={handleAddCard} loading={isLoading}>
              Сохранить
            </Button>
            <Button type="default" onClick={handleCancel} disabled={isLoading}>
              Отмена
            </Button>
          </div>
        </Form>
      )}
    </div>
  );
};
