import { IUser } from 'modules/users/types/IUser';
import { IMetaPagination } from 'types/IMetaPagination';
import { ReducerAction } from 'types/reducerAction.interface';

export interface IFetchUsersParams {
  id?: number;
  name?: string;
}

export interface IFetchUsersPayload {
  users: IUser[];
  pagination: IMetaPagination;
}

export interface ISetPaginationPayload {
  page: number;
  perPage: number;
}

export interface IUsersState {
  users: IUser[];
  isLoading: boolean;
  pagination: IMetaPagination;
}

export interface IUpdateUserParams {
  name: string;
  email: string;
  newPassword?: string;
  roles: number[];
  telegramUsername?: string;
}

export interface ICreateUserParams {
  name: string;
  email: string;
  password: string;
  roles: number[];
  telegramUsername?: string;
  agentId?: number;
}

export type IUpdateUsersReducerAction = ReducerAction<IUser, UsersActionsTypes.UPDATE_USER>;

export type IFetchUsersReducerAction = ReducerAction<IFetchUsersPayload, UsersActionsTypes.FETCH_USERS>;

export type ISetIsLoadingReducerAction = ReducerAction<boolean, UsersActionsTypes.SET_IS_LOADING>;

export type ISetPaginationReducerAction = ReducerAction<ISetPaginationPayload, UsersActionsTypes.SET_PAGINATION>;

export type ICreateUserReducerAction = ReducerAction<IUser, UsersActionsTypes.CREATE_USER>;

export type UsersReducerActions =
  | IFetchUsersReducerAction
  | ISetIsLoadingReducerAction
  | ISetPaginationReducerAction
  | IUpdateUsersReducerAction
  | ICreateUserReducerAction;

export enum UsersActionsTypes {
  FETCH_USERS = 'FETCH_USERS',
  SET_IS_LOADING = 'SET_IS_LOADING',
  SET_PAGINATION = 'SET_PAGINATION',
  UPDATE_USER = 'UPDATE_USER',
  CREATE_USER = 'CREATE_USER',
}
