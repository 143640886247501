export const selectedBinProviders: { label: string; value: string }[] = [
  {
    label: 'Binking',
    value: 'binking',
  },
  {
    label: 'Bincheck',
    value: 'bincheck',
  },
  {
    label: 'Finanso',
    value: 'finanso',
  },
];
