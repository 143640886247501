import React from 'react';
import { message, Select } from 'antd';
import { BankAccount } from 'modules/bankAccounts/types/bankAccount.interface';
import { useAppDispatch } from 'hooks/useAppDispatch.hook';
import { useUserPermissions } from 'policies/hooks/useUserPermissions';
import { permissions } from 'policies/permissions';
import { setBankAccountStatus } from 'modules/bankAccounts/store';
import { BankAccountStatusEnum } from 'modules/bankAccounts/enums/bankAccountStatus.enum';

interface Props {
  account: BankAccount;
}

export const BankAccountsListPageStatusColumn: React.FC<Props> = ({ account }) => {
  const dispatch = useAppDispatch();

  const { userCan } = useUserPermissions();

  async function handleBankAccountStatusChange(account: BankAccount, currentStatus: string) {
    try {
      await dispatch(
        setBankAccountStatus({
          bankAccountId: account.bankAccountId,
          status: currentStatus,
        })
      );

      message.success({
        content: 'Статус счёта успешно изменён!',
        duration: 1.5,
      });
    } catch {
      message.error('Ошибка изменения статуса счёта!');
    }
  }

  return (
    <>
      <Select
        options={[
          { value: BankAccountStatusEnum.ACTIVE, label: 'Активный' },
          { value: BankAccountStatusEnum.BLOCKED, label: 'Заблокирован' },
        ]}
        disabled={!userCan(permissions.bankAccount.block)}
        value={account.status}
        onChange={(value: string) => handleBankAccountStatusChange(account, value as BankAccountStatusEnum)}
        style={{ minWidth: 150 }}
      />
    </>
  );
};
