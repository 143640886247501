import React, { useEffect } from 'react';
import { Drawer, Form, Button, App, Radio } from 'antd';
import { useForm } from 'antd/lib/form/Form';

import { useAppDispatch } from 'hooks/useAppDispatch.hook';
import { Input } from 'components/Input';
import { LazySelect } from 'components/LazySelect';
import { updateBankAccount } from 'modules/bankAccounts/store';
import { useBankAccountsTypesFromServer, useBanksFromServer } from 'hooks/useDataFromServer';
import { BankAccount } from 'modules/bankAccounts/types/bankAccount.interface';
import { UpdateBankAccountParams } from 'modules/bankAccounts/store/types/updateBankAccountParams.interface';

interface Props {
  bankAccount?: BankAccount;
  onClose: () => void;
}

export const BankAccountsListUpdateDrawer: React.FC<Props> = ({ bankAccount, onClose }) => {
  const [form] = useForm<UpdateBankAccountParams>();

  const [accountTypes] = useBankAccountsTypesFromServer();

  const { message } = App.useApp();

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (bankAccount) {
      form.setFieldsValue({
        bic: bankAccount.bic,
        bankAccountId: bankAccount.bankAccountId,
        accountNumber: bankAccount.accountNumber,
        accountType: bankAccount.accountType.id,
        bankId: bankAccount.bank.id,
      });

      return;
    }

    form.resetFields();
  }, [bankAccount]);

  async function handleSubmitPress() {
    try {
      await dispatch(updateBankAccount(form.getFieldsValue())).unwrap();

      message.success('Счет владельца успешно обновлен');
      onClose();
    } catch (error) {
      message.error('Не удалось обновить счет владельца.');
    }
  }

  return (
    <Drawer open={!!bankAccount} title="Редактирование счёта" onClose={onClose}>
      <Form form={form} onFinish={handleSubmitPress} className="h-full">
        <Form.Item hidden name="bankAccountId" />
        <div className="flex flex-col justify-end h-full">
          <div>
            <div className="border-b border-gray-100 pb-6 mb-10">
              <Form.Item name="bankId">
                <LazySelect
                  className="w-full"
                  allowClear
                  placeholder="Банк"
                  useDataHook={useBanksFromServer}
                  cacheKey="provider-select"
                />
              </Form.Item>
              <Form.Item
                name="accountNumber"
                rules={[
                  {
                    pattern: /^[a-zA-Z0-9]+$/,
                    message: 'Номер счёта может содержать только буквы латинского алфавита и цифры',
                  },
                ]}
              >
                <Input addonBefore="Номер счета" placeholder="00000000000000000000" type="text" />
              </Form.Item>
              <Form.Item
                name="bic"
                rules={[
                  {
                    pattern: /^[0-9]+$/,
                    message: 'BIC должен состоять из цифр',
                  },
                ]}
              >
                <Input addonBefore="БИК" placeholder="000000000" type="text" />
              </Form.Item>
              <div className="flex items-center justify-between gap-2">
                <p className="text-sm">Тип счета</p>
                <Form.Item name="accountType" className=" mb-0">
                  <Radio.Group
                    options={accountTypes.map((item) => ({
                      label: item.name,
                      value: item.id,
                    }))}
                    className="flex whitespace-nowrap"
                    optionType="button"
                  />
                </Form.Item>
              </div>
            </div>
          </div>
          <div className="mt-auto">
            <Button htmlType="submit" className="w-full" type="primary">
              Сохранить
            </Button>
          </div>
        </div>
      </Form>
    </Drawer>
  );
};
