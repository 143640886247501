import React from 'react';
import { App, Switch } from 'antd';
import { useAppSelector } from 'hooks/useAppSelector.hook';
import { useAppDispatch } from 'hooks/useAppDispatch.hook';
import { useUserPermissions } from 'policies/hooks/useUserPermissions';
import { permissions } from 'policies/permissions';
import { Partner } from 'modules/partners/types/partner.interface';
import { disablePartner, enablePartner } from 'modules/partners/store';

interface Props {
  partner?: Partner;
}

export const PartnersPageEnabledSwitch: React.FC<Props> = ({ partner }) => {
  const { isLoading } = useAppSelector((state) => state.partners);
  const { userCan } = useUserPermissions();

  const dispatch = useAppDispatch();

  const { message } = App.useApp();

  async function handleEnabledSwitchToggle(value: boolean) {
    try {
      const id = partner!.id;

      await dispatch(value ? enablePartner(id) : disablePartner(id)).unwrap();

      message.success(`Партнер успешно ${value ? 'включен' : 'выключен'}`);
    } catch {
      message.error(`Не удалось ${value ? 'включить' : 'выключить'} партнера`);
    }
  }

  return (
    <Switch
      loading={isLoading}
      value={partner?.enabled}
      onChange={handleEnabledSwitchToggle}
      disabled={!userCan(permissions.partner.disable, permissions.partner.enable)}
    />
  );
};
