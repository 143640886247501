import React, { useEffect } from 'react';
import { App, Typography } from 'antd';

import { ReportsPageFilters } from 'modules/reports/pages/list/components/Filters';
import { ReportsPageTable } from 'modules/reports/pages/list/components/Table';

import { IReport } from 'modules/reports/store/reports/types';
import {
  addNewReport,
  changePagination,
  deleteReport,
  getReports,
  setReportAsSeen,
} from 'modules/reports/store/reports/actions';
import { useAppDispatch } from 'hooks/useAppDispatch.hook';
import { useAppSelector } from 'hooks/useAppSelector.hook';
import { useChannel } from 'hooks/useChannel.hook';

interface Props {}

export const ReportsPage: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const { message } = App.useApp();

  const {
    reports = [],
    pagination: { page, perPage, total },
    isLoading,
  } = useAppSelector((state) => state.reports);

  const { user } = useAppSelector((state) => state.auth);

  useEffect(() => {
    fetch();
  }, [page, perPage]);

  useEffect(() => {
    markAsSeen();
  }, [reports]);

  useChannel(
    'private-reports',
    {
      '.created': (newReport: IReport) => {
        if (user && user.id === newReport.requestUserId) {
          // фильтрация по пользователю
          newReport.new = true;

          dispatch<any>(addNewReport(newReport));
        }
      },
    },
    [user]
  );

  async function fetch() {
    try {
      await dispatch(getReports({ page, perPage }));
    } catch {
      message.error('Не удалось получить список отчетов');
    }
  }

  async function markAsSeen() {
    try {
      const ids = reports.filter((report) => !report.isSeen).map((report) => report.id);

      if (ids.length === 0) return;

      await dispatch(setReportAsSeen(ids));
    } catch {
      message.error('Не удалось выполнить запрос');
    }
  }

  async function onDeletePress(id: number) {
    try {
      await dispatch(deleteReport(id));

      message.success('Отчет успешно удален!');
    } catch {
      message.error('Не удалось удалить отчет');
    }
  }

  return (
    <>
      <Typography.Title>Отчёты</Typography.Title>
      <ReportsPageFilters />
      <ReportsPageTable
        isLoading={isLoading}
        data={reports}
        page={page}
        perPage={perPage}
        total={total}
        onDeletePress={onDeletePress}
        onPaginationChange={(newPage) => {
          dispatch<any>(changePagination(newPage, perPage));
        }}
      />
    </>
  );
};
