import React, { useEffect, useState } from 'react';

import { useForm } from 'antd/lib/form/Form';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { App, Button, Drawer, Form, Space, Spin } from 'antd';

import { Input } from 'components/Input';
import { LazySelect } from 'components/LazySelect';

import { createAgentThunk } from 'modules/agents/store/agents';
import { ICreateAgentParams } from 'modules/agents/store/agents/interfaces/createAgentParams.interface';
import { ICreateAgentUsersParams } from 'modules/agents/store/agents/interfaces/createAgentUsersParams.interface';

import { emptyUserRow } from 'modules/agents/pages/list/components/CreateAgentModal/constants';

import { useAgentsFromServer } from 'hooks/useDataFromServer';
import { useTheme } from 'contexts';
import { useAppDispatch } from 'hooks/useAppDispatch.hook';

interface Props {
  isShown: boolean;
  onCancel: () => void;
}

export const AgentsPageCreateAgentModal: React.FC<Props> = ({ isShown, onCancel }) => {
  const [isCreatingInProgress, setIsCreatingInProgress] = useState<boolean>(false);
  const [agentUsers, setAgentUser] = useState([emptyUserRow] as ICreateAgentUsersParams[]);

  const [params, setParams] = useState<ICreateAgentParams>({} as ICreateAgentParams);

  const [form] = useForm<ICreateAgentParams>();

  const dispatch = useAppDispatch();

  const { message } = App.useApp();

  const {
    currentTheme: {
      token: { colorBgLabelInput, colorBorderInput, colorTextLight },
    },
  } = useTheme();

  useEffect(() => {
    clearFormValues();
  }, [isShown]);

  function clearFormValues(): void {
    setAgentUser([emptyUserRow]);
    if (isShown) {
      form.resetFields();
    }
  }

  function addNewRow() {
    setAgentUser([...agentUsers, emptyUserRow]);
  }

  async function handleSumbitPress() {
    setIsCreatingInProgress(true);

    const isSuccess = await dispatch(createAgentThunk({ ...params, users: agentUsers }));

    setIsCreatingInProgress(false);

    if (isSuccess) {
      message.success('Агент успешно создан');

      onCancel();
      return;
    }

    message.error('Не удалось создать агента.');
  }

  function removeRowByIndex(index: number) {
    if (agentUsers.length > 1) {
      const updatedUsers = [...agentUsers];
      updatedUsers.splice(index, 1);

      setAgentUser(updatedUsers);

      form.setFieldsValue({ users: updatedUsers });
    } else {
      message.error('Невозможно удалить последний элемент.');
    }
  }

  return (
    <>
      <Drawer title="Создать нового агента" open={isShown} onClose={onCancel}>
        <Spin tip="Идет создание агента..." spinning={isCreatingInProgress} size="large">
          <Form
            form={form}
            component={false}
            onValuesChange={(_, values) => {
              setParams(values);
              setAgentUser(values.users);
            }}
          >
            <Space className="w-full" direction="vertical" size="middle">
              <div className="flex items-center">
                <div
                  className="px-2 py-1 border border-solid whitespace-nowrap rounded-l-md"
                  style={{
                    backgroundColor: colorBgLabelInput,
                    borderColor: colorBorderInput,
                    color: colorTextLight,
                  }}
                >
                  Реферал
                </div>
                <Form.Item name="parentId" noStyle>
                  <LazySelect
                    allowClear
                    placeholder="Имя агента"
                    className="w-full"
                    useDataHook={useAgentsFromServer}
                    cacheKey="agents-select"
                  />
                </Form.Item>
              </div>
              <Input placeholder="Имя" addonBefore="Имя" className="w-full" name="name" />
              <div className="py-2 text-center text-lg font-semibold">Пользователи</div>
              {agentUsers.map((_, index) => (
                <div
                  key={index}
                  className="flex flex-row rounded-xl shadow-lg border-t-2 border-green-500 p-4 gap-x-2 pr-2 items-center"
                >
                  <div className="flex flex-col">
                    <Form.Item className="mb-2" name={['users', index, 'name']}>
                      <Input placeholder="Имя" addonBefore="Имя" className="w-full" />
                    </Form.Item>
                    <Form.Item className="mb-2" name={['users', index, 'email']}>
                      <Input placeholder="Email" addonBefore="Email" className="w-full" />
                    </Form.Item>
                    <Form.Item className="mb-2" name={['users', index, 'password']}>
                      <Input placeholder="Пароль" addonBefore="Пароль" className="w-full" />
                    </Form.Item>
                    <Form.Item className="mb-0" name={['users', index, 'telegramUsername']}>
                      <Input placeholder="Ник Telegram" addonBefore="Ник Telegram" className="w-full" />
                    </Form.Item>
                  </div>
                  <div className="text-right">
                    <Button
                      danger
                      type="text"
                      icon={<DeleteOutlined />}
                      className="mt-2"
                      onClick={() => {
                        removeRowByIndex(index);
                      }}
                    />
                  </div>
                </div>
              ))}
              <div className="w-full flex justify-center">
                <Button type="dashed" onClick={addNewRow} style={{ width: '100%' }} icon={<PlusOutlined />}>
                  Добавить пользователя
                </Button>
              </div>
              <Button htmlType="submit" onClick={handleSumbitPress} className="w-full" type="primary">
                Создать
              </Button>
            </Space>
          </Form>
        </Spin>
      </Drawer>
    </>
  );
};
