import { App, Button, Popconfirm } from 'antd';
import React, { useEffect, useState } from 'react';
import { useAppDispatch } from 'hooks/useAppDispatch.hook';
import { Order } from 'modules/orders/enums/order.interface';
import { OrderUpdateParams } from 'modules/orders/store/orders/interfaces/orderUpdateParams.interface';
import { editOrder } from 'modules/orders/store/orders/actions';
import { CommonFields } from 'modules/orders/pages/list/components/EditModal/OrdersPageEditModal';
import { SelectedOwner } from 'modules/orders/components/AssignOwnersToOrderForm/interfaces/selectedOwner.interface';
import { AssignOwnersToOrderForm } from 'modules/orders/components/AssignOwnersToOrderForm/AssignOwnersToOrderForm';
import { InputNumber } from 'components/Input';

interface Props {
  order?: Order;
  onClose: () => void;
  onSubmit: () => CommonFields;
  isAllowedToEditCommissions: boolean;
}

export const OrdersPageEditModalOutOrder: React.FC<Props> = ({
  order,
  onClose,
  onSubmit,
  isAllowedToEditCommissions,
}) => {
  const [providerCommissions, setProviderCommissions] = useState<{ [key: number]: number }>({});
  const [selectedOwners, setSelectedOwners] = useState<SelectedOwner[]>([]);
  const [assignFormErrors, setAssignFormErrors] = useState<string[]>([]);

  const dispatch = useAppDispatch();
  const { message } = App.useApp();

  useEffect(() => {
    if (!order) {
      setSelectedOwners([]);
      setAssignFormErrors([]);

      return;
    }

    setProviderCommissions({});

    const owners: SelectedOwner[] = order.owners.map((owner) => {
      return {
        ownerId: owner.ownerId,
        amount: owner.amount,
        fio: owner.fio,
        bankId: owner.bankId,
        bankAccountId: owner.bankAccountId!,
        bankAccountNumber: owner.bankAccountNumber,
      };
    });

    setSelectedOwners(owners);

    setProviderCommissions((prevState) =>
      order.owners.reduce(
        (acc, owner) => {
          acc[owner.ownerId] = owner.providerCommission ?? 0;

          return acc;
        },
        { ...prevState }
      )
    );
  }, [order]);

  async function handleSubmit() {
    if (!order) return;

    if (assignFormErrors.length !== 0) {
      assignFormErrors.forEach((error) => message.error(error));

      return;
    }

    const params: OrderUpdateParams = {
      ...onSubmit(),
      owners: selectedOwners.map(({ ownerId, amount, bankAccountId }) => ({
        ownerId,
        amount: amount ?? order.amount,
        providerCommission: providerCommissions[ownerId] ?? 0,
        bankAccountId,
      })),
    };

    try {
      await dispatch(editOrder(params));

      message.success('Транзакция успешно изменена!');
    } catch {
      message.error('Ошибка при сохранении изменений в транзакции');
    } finally {
      onClose();
    }
  }

  return (
    <>
      <div className="mb-2">Привязанные владельцы</div>
      <AssignOwnersToOrderForm
        order={order}
        onChangeErrors={setAssignFormErrors}
        onChangeSelectedOwners={(owners) => setSelectedOwners(owners)}
        selectedOwners={selectedOwners}
      />
      {isAllowedToEditCommissions &&
        selectedOwners.map(({ ownerId }) => {
          return (
            <InputNumber
              key={ownerId}
              className="mb-4"
              value={providerCommissions[ownerId]}
              isAmountInput
              addonBefore={'№' + ownerId + ' - комиссия'}
              onChange={(value) =>
                setProviderCommissions((prevState) => ({ ...prevState, [ownerId]: value as number }))
              }
            />
          );
        })}
      <Popconfirm cancelText="Отмена" title="Вы уверенны, что хотите сохранить изменения?" onConfirm={handleSubmit}>
        <Button className="w-full mt-4" type="primary">
          Сохранить
        </Button>
      </Popconfirm>
    </>
  );
};
