import React, { FC, useEffect, useState } from 'react';
import { Filters } from 'components/Filters';
import { useMemoFilters } from 'hooks/useMemoFilters/useMemoFilters';
import { filtersSchema } from './filters';
import { Pagination } from 'components/Pagination';
import { useAppSelector } from 'hooks/useAppSelector.hook';
import { useAppDispatch } from 'hooks/useAppDispatch.hook';
import { FetchBankAccountsParams } from 'modules/bankAccounts/store/types/fetchBankAccountsParams.interface';
import { App, Button, Table } from 'antd';
import { deleteBankAccount, fetchBankAccounts } from 'modules/bankAccounts/store';
import { useBankAccountsColumns } from 'modules/bankAccounts/pages/list/hooks/useBankAccountsColumns.hook';
import { BankAccountsListPageCardsList } from 'modules/bankAccounts/pages/list/components/CardsList';
import { BankAccountsListCreateDrawer } from 'modules/bankAccounts/pages/list/components/CreateDrawer/BankAccountsListCreateDrawer';
import { BankAccount } from 'modules/bankAccounts/types/bankAccount.interface';
import { BankAccountsListUpdateDrawer } from 'modules/bankAccounts/pages/list/components/UpdateDrawer/BankAccountListPageUpdateDrawer';

export const BankAccountsListPage: FC = () => {
  const { memoFilters, setMemoFilters } = useMemoFilters<FetchBankAccountsParams>();

  const [bankAccountToEdit, setBankAccountToEdit] = useState<BankAccount>();

  const { message, modal } = App.useApp();

  const {
    accounts,
    isLoading,
    pagination: { page, perPage },
    pagination,
  } = useAppSelector((state) => state.bankAccountsModule);

  const dispatch = useAppDispatch();

  const columns = useBankAccountsColumns({
    onEditClick: (bankAccount) => setBankAccountToEdit(bankAccount),
    onDeleteClick: (bankAccount) => {
      const { destroy } = modal.error({
        title: 'Удаление счета',
        content: (
          <>
            Вы уверены, что хотите
            <br />
            удалить счет?
          </>
        ),
        closable: true,
        width: '280px',
        footer: () => (
          <>
            <Button onClick={destroy}>Отмена</Button>
            <Button
              type="primary"
              danger
              onClick={() => {
                destroy();
                deleteAccount(bankAccount);
              }}
            >
              Удалить
            </Button>
          </>
        ),
      });
    },
  });

  useEffect(() => {
    fetch(memoFilters);
  }, []);

  function handleFiltersChange(filters: FetchBankAccountsParams) {
    setMemoFilters(filters);
    fetch(filters);
  }

  function handlePaginationChange(page: number, perPage: number): void {
    fetch(memoFilters, page, perPage);
  }

  async function fetch(filters: FetchBankAccountsParams, requestPage = page, requestPerPage = perPage) {
    try {
      await dispatch(fetchBankAccounts({ ...filters, page: requestPage, perPage: requestPerPage })).unwrap();
    } catch {
      message.error('Не удалось получить список счетов');
    }
  }

  async function deleteAccount(bankAccount: BankAccount) {
    try {
      const { bankAccountId } = bankAccount!;

      await dispatch(deleteBankAccount({ bankAccountId })).unwrap();

      message.success('Счет успешно удалён');
    } catch (error) {
      message.error('Не удалось удалить счёт');
    }
  }

  return (
    <>
      <BankAccountsListUpdateDrawer bankAccount={bankAccountToEdit} onClose={() => setBankAccountToEdit(undefined)} />
      <Filters
        headerTitle="Счета и карты"
        filters={filtersSchema}
        value={memoFilters}
        onSubmit={handleFiltersChange}
        extraHeaderButtons={() => <BankAccountsListCreateDrawer />}
      />
      <Table
        columns={columns}
        dataSource={accounts}
        loading={isLoading}
        pagination={false}
        rowKey={(record) => record.bankAccountId}
        expandable={{
          expandedRowRender: ({ bankAccountId }) => {
            return <BankAccountsListPageCardsList bankAccountId={bankAccountId} />;
          },
        }}
      />
      <Pagination pagination={pagination} isLoading={isLoading} onPaginationChange={handlePaginationChange} />
    </>
  );
};
