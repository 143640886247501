import React, { Suspense, useState } from 'react';

import { Button, App } from 'antd';

import { OrdersPageEditModal } from './components/EditModal';
import { OrdersPageTable } from './components/Table';
import { OrdersPageCreateDrawer } from './components/CreateDrawer';

import api from 'utils/axios';
import { useMemoFilters } from 'hooks/useMemoFilters/useMemoFilters';
import { filtersSchema } from './filters';

import instance from 'utils/axios';

import { ProtectedComponent } from 'policies/components/ProtectedComponent';
import { permissions } from 'policies/permissions';
import { DownloadOutlined } from '@ant-design/icons';
import { OrdersPageStatistics } from './components/Statistics';

import { useAppDispatch } from 'hooks/useAppDispatch.hook';
import { useAppSelector } from 'hooks/useAppSelector.hook';

import 'pusher-js';
import { sanitizeRequestParams } from 'utils/sanitizeRequestParams';
import { FetchOrdersParams } from 'modules/orders/store/orders/interfaces/fetchOrdersParams.interface';
import { fetchOrders, setIsLoadingOrders } from 'modules/orders/store/orders/actions';
import { Order } from 'modules/orders/enums/order.interface';
import { Filters } from 'components/Filters';
import { Pagination } from 'components/Pagination';

export const OrdersPage: React.FC = () => {
  const [selectedForEdit, setSelectedForEdit] = useState<Order>();

  const [isLoadingStatistics, setIsLoadingStatistics] = useState<boolean>(false);
  const [stats, setStats] = useState({ count: '-', sum: '-' });

  const [isAddDrawerOpened, setIsAddDrawerOpened] = useState<boolean>(false);

  const { notification, message } = App.useApp();

  const {
    orders,
    isLoading,
    pagination,
    pagination: { page, perPage },
  } = useAppSelector((state) => state.orders);

  const dispatch = useAppDispatch();

  const { memoFilters, setMemoFilters } = useMemoFilters<FetchOrdersParams>();

  function handleFiltersSubmit(filters: FetchOrdersParams) {
    setMemoFilters(filters);
    dispatch<any>(fetchOrders({ ...filters, page, perPage }));
  }

  function handlePageChange(page: number, perPage: number) {
    dispatch<any>(fetchOrders({ ...memoFilters, page, perPage }));
  }

  function cleanEmptyArrays(params: any): Record<string, any> {
    return Object.fromEntries(
      Object.entries(params).filter(([_, value]) => !(Array.isArray(value) && value.length === 0))
    );
  }

  async function exportInExcel(params: FetchOrdersParams, isExtended: boolean = false) {
    const cleanedParams = cleanEmptyArrays(params);

    try {
      setMemoFilters(cleanedParams);

      if (!params.from || !params.to) {
        message.warning('Укажите как минимум дату от и до для экспорта таблицы!');
        return;
      }

      dispatch<any>(setIsLoadingOrders(true));

      const url = isExtended ? '/orders/extended-export' : '/orders/export';
      await instance.post(url, sanitizeRequestParams(cleanedParams));

      notification.success({
        message: 'Отчет формируется и будет доступен во вкладке "Отчеты"',
        duration: 3,
      });
    } catch {
      message.error('Не удалось сформировать отчет');
    } finally {
      dispatch<any>(setIsLoadingOrders());
    }
  }

  async function fetchStats(params: FetchOrdersParams): Promise<void> {
    const cleanedParams = cleanEmptyArrays(params);

    setIsLoadingStatistics(true);
    setMemoFilters(cleanedParams);

    try {
      const {
        data: { data },
      } = await api.get('/orders/statistic', {
        params: { ...cleanedParams },
      });

      setStats(data);
    } catch {
      message.error('Не удалось запросить статистику');
    } finally {
      setIsLoadingStatistics(false);
    }
  }

  async function exportOperatorsStatistics(params: FetchOrdersParams): Promise<void> {
    const cleanedParams = cleanEmptyArrays(params);

    try {
      setMemoFilters(cleanedParams);

      if (!params.from || !params.to) {
        message.warning('Укажите как минимум дату от и до для экспорта таблицы!');
        return;
      }

      dispatch<any>(setIsLoadingOrders(true));

      await instance.put('/orders/export-bindings', {
        from: cleanedParams.from,
        to: cleanedParams.to,
        usersIds: cleanedParams.operatorsIds,
      });

      notification.success({
        message: 'Отчет формируется и будет доступен во вкладке "Отчеты"',
        duration: 3,
      });
    } catch {
      message.error('Не удалось сформировать отчет');
    } finally {
      dispatch<any>(setIsLoadingOrders());
    }
  }

  async function resendCallback(order: Order): Promise<void> {
    try {
      await instance.put('/orders/' + order.id + '/resend-callback');

      message.success('Изменение статуса успешно переотправлено клиенту!');
    } catch {
      message.error('Не удалось переотправить изменение статуса клиенту!');
    }
  }

  return (
    <>
      <OrdersPageEditModal
        order={selectedForEdit}
        onClose={() => {
          setSelectedForEdit(undefined);
        }}
      />
      <OrdersPageCreateDrawer isShown={isAddDrawerOpened} onClose={() => setIsAddDrawerOpened(false)} />
      <Suspense fallback={null}>
        <Filters
          headerTitle="Транзакции"
          filters={filtersSchema}
          value={memoFilters}
          onSubmit={handleFiltersSubmit}
          submitButtonText="Запросить транзакции"
          extraHeaderButtons={() => (
            <ProtectedComponent requiredPermissions={[permissions.order.create]}>
              <Button onClick={() => setIsAddDrawerOpened(true)} type="primary" shape="round">
                Добавить
              </Button>
            </ProtectedComponent>
          )}
          customButtons={(getFilters) => (
            <>
              <Button
                className="bg-green-600 border-green-600 hover:bg-green-500"
                type="default"
                icon={<DownloadOutlined />}
                onClick={() => exportInExcel(getFilters())}
                style={{ color: 'white', border: 'none' }}
              >
                Скачать в .xlsx
              </Button>
              <Button
                className="bg-green-600 border-green-600 hover:bg-green-500"
                type="default"
                icon={<DownloadOutlined />}
                onClick={() => exportInExcel(getFilters(), true)}
                style={{ color: 'white', border: 'none' }}
              >
                Сверка
              </Button>
              <Button
                className="bg-green-600 border-green-600 hover:bg-green-500"
                type="default"
                icon={<DownloadOutlined />}
                onClick={() => exportOperatorsStatistics(getFilters())}
                style={{ color: 'white', border: 'none' }}
              >
                Cтатистика по операторам
              </Button>
              <Button type="primary" onClick={() => fetchStats(getFilters())}>
                Запросить статистику
              </Button>
              <OrdersPageStatistics isLoading={isLoadingStatistics} stats={stats} />
            </>
          )}
        />
        <OrdersPageTable
          isLoading={isLoading}
          orders={orders}
          onEditPress={(orders) => setSelectedForEdit(orders)}
          onResendCallback={resendCallback}
        />
        <Pagination
          customStyle="mt-2"
          pagination={pagination}
          isLoading={isLoading}
          onPaginationChange={handlePageChange}
        />
      </Suspense>
    </>
  );
};
