import React, { FC, useState } from 'react';
import { Form, Button, Spin, Tooltip, Switch, Image, App } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

import { Input } from 'components/Input';

import { IBank } from 'modules/banks/types/IBank';
import { IUpdateBankParams } from 'modules/banks/store/banks/types';
import { toggleBank, updateBank } from 'modules/banks/store/banks/actions';
import { useAppDispatch } from 'hooks/useAppDispatch.hook';

interface Props {
  bank: IBank;
  onClose: () => void;
}

export const BanksPageEditBankForm: FC<Props> = ({ bank, onClose }) => {
  const [form] = useForm<IUpdateBankParams>();

  const [bankEdit, setBankEdit] = useState<IBank>(bank);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedColors, setSelectedColors] = useState<[string, string]>(bankEdit.backgroundColors ?? ['', '']);

  const dispatch = useAppDispatch();

  const { message } = App.useApp();

  const wrapperFormItem: string = 'flex items-center';
  const styleLabel: string = 'w-48 mr-2';

  function handleColorChange(index: number, e: React.ChangeEvent<HTMLInputElement>) {
    const newColor = e.target.value;
    const updatedColors: [string, string] = [...selectedColors];
    updatedColors[index] = newColor;
    setSelectedColors(updatedColors);
    setBankEdit((prevBankEdit) => ({ ...prevBankEdit, backgroundColors: updatedColors }));
  }

  async function handleSubmit(): Promise<void> {
    setIsLoading(true);

    const values = bankEdit;

    try {
      await dispatch(updateBank(values));

      setIsLoading(false);
      message.success('Банк успешно изменён!');
      onClose();
    } catch {
      message.error('Не удалось обновить банк!');
    }
  }

  async function handleChangeCheckSwitch() {
    const params = {
      id: bankEdit.id,
      enabled: bankEdit.enabled,
    };

    try {
      await dispatch(toggleBank(params));
    } catch {
      message.error('Не удалось обновить включение');
    }

    setBankEdit((prevBankEdit) => ({ ...prevBankEdit, enabled: !bankEdit.enabled }));
  }

  return (
    <Spin spinning={isLoading}>
      <Form name="edit-bank" form={form}>
        <Form.Item name="id" initialValue={bankEdit.id}>
          <div className={wrapperFormItem}>
            <label className={styleLabel}>Id банка:</label>
            <Input defaultValue={bankEdit.id} size="middle" className="w-full" disabled />
          </div>
        </Form.Item>
        <Form.Item name="name" initialValue={bankEdit.name}>
          <div className={wrapperFormItem}>
            <label className={styleLabel}>Название банка</label>
            <Input
              defaultValue={bankEdit.name}
              size="middle"
              className="w-full"
              onChange={(e) => {
                setBankEdit((prevBankEdit) => ({ ...prevBankEdit, name: e.target.value }));
              }}
            />
          </div>
        </Form.Item>
        <Form.Item name="nameEn" initialValue={bankEdit.nameEn}>
          <div className={wrapperFormItem}>
            <label className={styleLabel}>Название банка (англ.)</label>
            <Input
              defaultValue={bankEdit.nameEn}
              size="middle"
              className="w-full"
              onChange={(e) => {
                setBankEdit((prevBankEdit) => ({ ...prevBankEdit, nameEn: e.target.value }));
              }}
            />
          </div>
        </Form.Item>
        <Form.Item name="internal" initialValue={bankEdit.internal}>
          <div className={wrapperFormItem}>
            <label className={styleLabel}>Внешний / внутренний банк</label>
            <div className="w-full pl-3 pr-3">
              {bankEdit.internal ? (
                <Tooltip title="Внутренний банк">
                  <CheckOutlined className="text-green-700" />
                </Tooltip>
              ) : (
                <Tooltip title="Внешний банк">
                  <CloseOutlined className="text-red-700" />
                </Tooltip>
              )}
            </div>
          </div>
        </Form.Item>
        <Form.Item name="enabled" initialValue={bankEdit.enabled}>
          <div className={wrapperFormItem}>
            <label className={styleLabel}>Включение / отключение банка</label>
            <div className="w-full pl-3 pr-3">
              <Switch checked={bankEdit.enabled} onChange={handleChangeCheckSwitch} />
            </div>
          </div>
        </Form.Item>
        <Form.Item name="countryCode" initialValue={bankEdit.countryCode}>
          <div className={wrapperFormItem}>
            <label className={styleLabel}>Код страны</label>
            <Input
              defaultValue={bankEdit.countryCode}
              size="middle"
              className="w-full"
              onChange={(e) => {
                setBankEdit((prevBankEdit) => ({ ...prevBankEdit, countryCode: e.target.value }));
              }}
            />
          </div>
        </Form.Item>
        <Form.Item name="currencyCode" initialValue={bankEdit.currencyCode}>
          <div className={wrapperFormItem}>
            <label className={styleLabel}>Код валюты</label>
            <Input
              defaultValue={bankEdit.currencyCode}
              size="middle"
              className="w-full"
              onChange={(e) => {
                setBankEdit((prevBankEdit) => ({ ...prevBankEdit, currencyCode: e.target.value }));
              }}
            />
          </div>
        </Form.Item>
        <Form.Item name="imageUrl" initialValue={bankEdit.imageUrl}>
          <div className={wrapperFormItem}>
            <label className={styleLabel}>Главная картинка</label>
            <div className="w-full flex justify-between">
              <Input
                defaultValue={bankEdit.imageUrl}
                size="middle"
                className="mr-2"
                onChange={(e) => {
                  const newImageUrl = e.target.value;
                  setBankEdit((prevBankEdit) => ({ ...prevBankEdit, imageUrl: newImageUrl }));
                }}
              />
              {bankEdit.imageUrl && <Image src={bankEdit.imageUrl} width={60} alt={bankEdit.name} preview={false} />}
            </div>
          </div>
        </Form.Item>
        <Form.Item name="logoSmallSizeUrl" initialValue={bankEdit.logoSmallSizeUrl}>
          <div className={wrapperFormItem}>
            <label className={styleLabel}>Маленький логотип</label>
            <div className="w-full flex justify-between">
              <Input
                defaultValue={bankEdit.logoSmallSizeUrl}
                size="middle"
                className="mr-2"
                onChange={(e) => {
                  setBankEdit((prevBankEdit) => ({
                    ...prevBankEdit,
                    logoSmallSizeUrl: e.target.value,
                  }));
                }}
              />
              {bankEdit.logoSmallSizeUrl && (
                <Image src={bankEdit.logoSmallSizeUrl} width={60} alt={bankEdit.name} preview={false} />
              )}
            </div>
          </div>
        </Form.Item>
        <Form.Item name="logoBigSizeUrl" initialValue={bankEdit.logoBigSizeUrl}>
          <div className={wrapperFormItem}>
            <label className={styleLabel}>Крупный логотип</label>
            <div className="w-full flex justify-between">
              <Input
                defaultValue={bankEdit.logoBigSizeUrl}
                size="middle"
                className="mr-2"
                onChange={(e) => {
                  setBankEdit((prevBankEdit) => ({ ...prevBankEdit, logoBigSizeUrl: e.target.value }));
                }}
              />
              {bankEdit.logoBigSizeUrl && (
                <Image src={bankEdit.logoBigSizeUrl} width={60} alt={bankEdit.name} preview={false} />
              )}
            </div>
          </div>
        </Form.Item>
        <Form.Item name="logoInvertBigSizeUrl" initialValue={bankEdit.logoInvertBigSizeUrl}>
          <div className={wrapperFormItem}>
            <label className={styleLabel}>Инвертированный большой логотип</label>
            <div className="w-full flex justify-between">
              <Input
                defaultValue={bankEdit.logoInvertBigSizeUrl}
                size="middle"
                className="mr-2"
                onChange={(e) => {
                  setBankEdit((prevBankEdit) => ({
                    ...prevBankEdit,
                    logoInvertBigSizeUrl: e.target.value,
                  }));
                }}
              />
              {bankEdit.logoInvertBigSizeUrl && (
                <Image
                  src={bankEdit.logoInvertBigSizeUrl}
                  width={60}
                  className="bg-black flex items-center"
                  alt={bankEdit.name}
                  preview={false}
                />
              )}
            </div>
          </div>
        </Form.Item>
        <Form.Item name="logoInvertSmallSizeUrl" initialValue={bankEdit.logoInvertSmallSizeUrl}>
          <div className={wrapperFormItem}>
            <label className={styleLabel}>Инвертированный маленький логотип</label>
            <div className="w-full flex justify-between">
              <Input
                defaultValue={bankEdit.logoInvertSmallSizeUrl}
                size="middle"
                className="mr-2"
                onChange={(e) => {
                  setBankEdit((prevBankEdit) => ({
                    ...prevBankEdit,
                    logoInvertSmallSizeUrl: e.target.value,
                  }));
                }}
              />
              {bankEdit.logoInvertSmallSizeUrl && (
                <Image
                  src={bankEdit.logoInvertSmallSizeUrl}
                  width={60}
                  className="bg-black flex items-center"
                  alt={bankEdit.name}
                  preview={false}
                />
              )}
            </div>
          </div>
        </Form.Item>
        <Form.Item name="backgroundColors" initialValue={bankEdit.backgroundColors}>
          <div className={wrapperFormItem}>
            <label className={styleLabel}>Цвета фона</label>
            <ul className="w-full">
              {selectedColors.map((color, index) => (
                <li key={index} className="mb-2 flex">
                  <Input
                    value={color}
                    size="middle"
                    className="w-20 max-h-10 min-h-10 mr-1"
                    onChange={(e) => handleColorChange(index, e)}
                  />
                  <Input
                    type="color"
                    value={color}
                    size="middle"
                    className="w-20 min-h-10 max-h-10 mr-1"
                    onChange={(e) => handleColorChange(index, e)}
                  />
                </li>
              ))}
            </ul>
          </div>
        </Form.Item>
        <Button type="primary" onClick={handleSubmit}>
          Сохранить
        </Button>
      </Form>
    </Spin>
  );
};
