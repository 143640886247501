import React from 'react';
import { Tooltip } from 'antd';
import CIcon from '@coreui/icons-react';
import {
  cilArrowCircleLeft,
  cilCheck,
  cilHistory,
  cilSync,
  cilWarning,
  cilXCircle,
  cilDelete,
  cilBank,
} from '@coreui/icons';
import { cilAvTimer } from '@coreui/icons/js/free';

import { LoadingOutlined } from '@ant-design/icons';
import { OrderStatusEnum } from 'modules/orders/enums/orderStatus.enum';
import { Order } from 'modules/orders/enums/order.interface';

interface Props {
  order: Order;
}

export const OrdersPageTableIconCellStatus: React.FC<Props> = ({ order }) => {
  switch (order.status.id) {
    case OrderStatusEnum.IN_PROGRESS:
      return <CIcon className="text-gray-500" icon={cilSync} style={{ width: '50%', margin: 'auto' }} />;
    case OrderStatusEnum.AWAITING:
      return <CIcon className="text-gray-500" icon={cilHistory} style={{ width: '50%', margin: 'auto' }} />;
    case OrderStatusEnum.ENTERED:
    case OrderStatusEnum.EXECUTED:
      return <CIcon className="text-green-500" icon={cilCheck} style={{ width: '50%', margin: 'auto' }} />;
    case OrderStatusEnum.NOT_ENTERED:
    case OrderStatusEnum.NOT_EXECUTED:
      return <CIcon className="text-red-500" icon={cilXCircle} style={{ width: '50%', margin: 'auto' }} />;
    case OrderStatusEnum.UNKNOWN_ENTERED:
      return <CIcon className="text-yellow-500" icon={cilWarning} style={{ width: '50%', margin: 'auto' }} />;
    case OrderStatusEnum.WAITING_FOR_REFUND:
      return (
        <Tooltip title="Ожидает возврат">
          <CIcon className="text-yellow-500" icon={cilAvTimer} style={{ width: '50%', margin: 'auto' }} />
        </Tooltip>
      );
    case OrderStatusEnum.REFUNDED:
      return (
        <Tooltip title="Оформлен возврат">
          <CIcon className="text-green-500" icon={cilArrowCircleLeft} style={{ width: '50%', margin: 'auto' }} />
        </Tooltip>
      );
    case OrderStatusEnum.CANCELED_BY_CLIENT:
      return (
        <Tooltip title="Отменена клиентом">
          <CIcon className="text-yellow-500" icon={cilDelete} style={{ width: '50%', margin: 'auto' }} />
        </Tooltip>
      );
    case OrderStatusEnum.WAITING_FOR_AUTO_ASSIGN_OWNER:
      return (
        <Tooltip title="Ожидает автоматической привязки владельца">
          <LoadingOutlined />
        </Tooltip>
      );
    case OrderStatusEnum.WAITING_CHOOSE_BANK:
      return (
        <Tooltip title="Ожидает выбора банка">
          <CIcon className="text-yellow-500" icon={cilBank} style={{ width: '50%', margin: 'auto' }} />
        </Tooltip>
      );
    case OrderStatusEnum.EXECUTED_PARTLY:
      return (
        <Tooltip title="Частично выполнена">
          <CIcon className="text-yellow-500" icon={cilCheck} style={{ width: '50%', margin: 'auto' }} />
        </Tooltip>
      );
    default:
      return <React.Fragment>Неизвестный статус</React.Fragment>;
  }
};
