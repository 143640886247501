import React, { useMemo, useState } from 'react';
import { App, Button, Checkbox, Drawer, Space } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { CheckOutlined } from '@ant-design/icons';

import { useAppDispatch } from 'hooks/useAppDispatch.hook';
import api from 'utils/axios';
import { OrderPayment, OrderPaymentStatus } from 'modules/orders/enums/order.interface';
import { ChangeStatusOfActiveOrderParamTransferred } from 'modules/orders/store/activeOrders/interfaces/changeStatusOfActiveOrderParamTransferred.interface';
import { ChangeStatusOfActiveOrderStatusesEnum } from 'modules/orders/enums/changeStatusOfActiveOrderStatuses.enum';
import { changeStatusOfActiveOrder } from 'modules/orders/store/activeOrders/actions';
import { ActiveOrder } from 'modules/orders/store/activeOrders/interfaces/activeOrder.interface';
import { Tooltip } from 'components/Tooltip';
import { InputNumber } from 'components/Input';

interface Props {
  order: ActiveOrder | undefined;
  onClose: () => void;
}

export const ConfirmOutcomingDrawer: React.FC<Props> = ({ onClose, order }) => {
  const [providerCommissions, setProviderCommissions] = useState<{ [key: number]: number | null }>({});
  const [ownersShouldTransfer, setOwnersShouldTransfer] = useState<{ [key: number]: boolean }>({});

  const { modal } = App.useApp();
  const dispatch = useAppDispatch();

  const isMultipleMode = useMemo(() => order?.sumTransferred?.length !== 1, [order]);

  const sumToSend = useMemo(() => {
    if (!order?.sumTransferred) return 0;

    if (!isMultipleMode) {
      return order.sumTransferred[0].sum;
    }

    return order.sumTransferred
      .filter((o) => {
        const isCompleted = o.status === OrderPaymentStatus.COMPLETED;

        return isCompleted || ownersShouldTransfer[o.ownerId];
      })
      .reduce((p, n) => p + n.sum, 0);
  }, [ownersShouldTransfer, order]);

  const isPartly = useMemo(() => {
    return sumToSend !== order?.amount && isMultipleMode;
  }, [sumToSend, order, isMultipleMode]);

  async function confirm(skipConfirmationsCheck: boolean): Promise<void> {
    const {
      data: { data },
    } = await api.post('/orders/confirmations', { orderId: order!.id });

    const response = data as {
      ownerId: number;
      isReceiptChecked: boolean;
      hasSms: boolean;
    }[];

    const hasConfirmationsToWait = response.some(
      (confirmation) => !confirmation.isReceiptChecked || !confirmation.hasSms
    );

    if (hasConfirmationsToWait && !skipConfirmationsCheck) {
      let content = 'У владельцев отсутствуют подтверждения:\n\n';
      response.forEach((confirmation) => {
        const receipt = !confirmation.isReceiptChecked ? 'чеком' : '';
        const sms = !confirmation.hasSms ? 'смс' : '';
        const confirmationTitles = [receipt, sms].filter(Boolean);

        content += `Платеж владельца №${confirmation.ownerId}  не подтвержден: ${confirmationTitles.join(', ')} \n`;
      });

      modal.confirm({
        title: 'Вы уверены, что хотите подтвердить заявку? ',
        content: content,
        onOk: async () => await confirm(true),
        okText: 'Подтвердить',
        cancelText: 'Отмена',
      });

      return;
    }

    const result = order?.sumTransferred
      ?.filter((o) => {
        if (!isMultipleMode) {
          return true;
        }

        if (o.status === OrderPaymentStatus.COMPLETED) return true;

        return ownersShouldTransfer[o.ownerId] ?? false;
      })
      .map((o) => {
        const defaultCommission =
          order?.sumTransferred?.find((defaultOwner) => {
            return o.ownerId === defaultOwner.ownerId;
          })?.providerCommission ?? null;

        const currentCommission = providerCommissions[o.ownerId] ?? null;

        return { ...o, providerCommission: currentCommission ?? defaultCommission ?? null };
      }) as OrderPayment[];

    send(result);

    clearInputs();
    onClose();
  }

  function clearInputs(): void {
    setProviderCommissions({});
    setOwnersShouldTransfer({});
  }

  function ownersProviderCommissions(sumToTransfer: ChangeStatusOfActiveOrderParamTransferred[]): {
    [ownerId: number]: number;
  } {
    const result: { [ownerId: number]: number } = {};
    sumToTransfer.forEach((owner) => (result[owner.ownerId] = owner.providerCommission!));

    return result;
  }

  function send(sumToTransfer: ChangeStatusOfActiveOrderParamTransferred[]): void {
    if (!order) return;

    // привести данные на беке к единообразию, чтобы не отправлять разные структуры
    const owners = isPartly
      ? { transferred: sumToTransfer }
      : { ownersProviderCommissions: ownersProviderCommissions(sumToTransfer) };

    const status = isPartly
      ? ChangeStatusOfActiveOrderStatusesEnum.DONE_PARTLY
      : ChangeStatusOfActiveOrderStatusesEnum.DONE;

    const params = { orderId: order.id, status, ...owners };

    dispatch(changeStatusOfActiveOrder(params));
  }

  return (
    <Drawer
      open={!!order}
      onClose={() => {
        onClose();
        clearInputs();
      }}
      title={`Подтвердите отправку владельцев по заявке №${order?.id}`}
    >
      <Space direction="vertical">
        {order?.sumTransferred?.length &&
          order.sumTransferred.map(({ ownerId, status, sum, providerCommission }) => (
            <div key={ownerId} className="mb-4">
              <div className="mt-1 mb-1 ">
                <span className="font-bold">Владелец № </span> {ownerId}
              </div>
              <div className="mt-1 mb-1">
                <span className="font-bold">Сумма: </span> {sum.toLocaleString('ru-RU')}
              </div>
              {OrderPaymentStatus.COMPLETED === status ? (
                <div className="mt-1 mb-1">
                  <CheckOutlined className="text-green-500" />
                  <span className="text-green-500">Перевод совершен</span>
                </div>
              ) : (
                isMultipleMode && (
                  <Checkbox
                    defaultChecked={false}
                    key={'checkbox_' + ownerId}
                    checked={ownersShouldTransfer[ownerId] ?? false}
                    onChange={(e: CheckboxChangeEvent) =>
                      setOwnersShouldTransfer({ ...ownersShouldTransfer, [ownerId]: e.target.checked })
                    }
                  >
                    Отправлено
                  </Checkbox>
                )
              )}
              <div></div>
              <Tooltip title="Комиссия банка">
                <InputNumber
                  addonBefore="%"
                  key={'input_' + ownerId}
                  disabled={
                    isMultipleMode &&
                    OrderPaymentStatus.COMPLETED !== status &&
                    !(ownersShouldTransfer[ownerId] ?? false)
                  }
                  isAmountInput
                  value={providerCommissions[ownerId] ?? providerCommission ?? undefined}
                  onChange={(value) =>
                    setProviderCommissions({
                      ...providerCommissions,
                      [ownerId]: value as number,
                    })
                  }
                />
              </Tooltip>
            </div>
          ))}
        <div className="mt-1">
          <span className="font-bold">Итого к отправке:</span> {sumToSend.toLocaleString('ru-RU')}
        </div>
        <Button
          className="mt-4"
          type="primary"
          disabled={order?.sumTransferred?.length === 0}
          onClick={() => confirm(false)}
        >
          {isPartly ? 'Подтвердить частично' : 'Подтвердить'}
        </Button>
      </Space>
    </Drawer>
  );
};
