import { AccountTypeEnum } from 'modules/owners/types/accountType.enum';
import { BankAccount } from 'modules/owners/store/bankAccount/interfaces/bankAccount.interface';
import { ReducerAction } from 'types/reducerAction.interface';

export interface IBankAccountState {
  accounts: BankAccount[];
  isLoading: boolean;
}

export interface CreateBankAccountParams {
  ownerId: number;
  bankId: number;
  accountType: string;
  accountNumber: string;
  bic: string;
}

export interface DeleteBankAccountParams {
  id: number;
}

export interface EditBankAccountParams {
  bankAccountId: number;
  ownerId: number;
  bankId: number;
  accountType: string;
  accountNumber: string;
  bic: string;
}

export interface AccountType {
  id: string;
  name: AccountTypeEnum;
}

export interface BankAccountStatusParams {
  bankAccountId: number;
  status: string;
}

export interface BankAccountInEnabledParams {
  bankAccountId: number;
  inEnabled: boolean;
}

export interface BankAccountOutEnabledParams {
  bankAccountId: number;
  outEnabled: boolean;
}

export type CreateBankAccountReducerAction = ReducerAction<BankAccount, BankAccountActionsTypes.CREATE_BANK_ACCOUNT>;

export type DeleteBankAccountReducerAction = ReducerAction<number, BankAccountActionsTypes.DELETE_BANK_ACCOUNT>;

export type UpdateBankAccountReducerAction = ReducerAction<BankAccount, BankAccountActionsTypes.UPDATE_BANK_ACCOUNT>;

export type FetchBankAccountsReducerAction = ReducerAction<
  IBankAccountState,
  BankAccountActionsTypes.FETCH_BANK_ACCOUNTS
>;

export type SetBankAccountStatusReducerAction = ReducerAction<
  BankAccount,
  BankAccountActionsTypes.SET_BANK_ACCOUNT_STATUS
>;

export type SetBankAccountInEnabledReducerAction = ReducerAction<
  BankAccount,
  BankAccountActionsTypes.SET_BANK_ACCOUNT_IN_ENABLED
>;

export type SetBankAccountOutEnabledReducerAction = ReducerAction<
  BankAccount,
  BankAccountActionsTypes.SET_BANK_ACCOUNT_OUT_ENABLED
>;

export type SetLoadingReducerAction = ReducerAction<boolean, BankAccountActionsTypes.SET_LOADING>;

export type BankAccountReducersActions =
  | CreateBankAccountReducerAction
  | DeleteBankAccountReducerAction
  | UpdateBankAccountReducerAction
  | FetchBankAccountsReducerAction
  | SetBankAccountStatusReducerAction
  | SetBankAccountInEnabledReducerAction
  | SetBankAccountOutEnabledReducerAction
  | SetLoadingReducerAction;

export enum BankAccountActionsTypes {
  CREATE_BANK_ACCOUNT = 'CREATE_BANK_ACCOUNT',
  DELETE_BANK_ACCOUNT = 'DELETE_BANK_ACCOUNT',
  UPDATE_BANK_ACCOUNT = 'UPDATE_BANK_ACCOUNT',
  FETCH_BANK_ACCOUNTS = 'FETCH_BANK_ACCOUNTS',
  SET_BANK_ACCOUNT_STATUS = 'SET_BANK_ACCOUNT_STATUS',
  SET_BANK_ACCOUNT_IN_ENABLED = 'SET_BANK_ACCOUNT_IN_ENABLED',
  SET_BANK_ACCOUNT_OUT_ENABLED = 'SET_BANK_ACCOUNT_OUT_ENABLED',
  SET_LOADING = 'SET_LOADING',
}
