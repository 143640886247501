import React from 'react';
import { ColumnType } from 'antd/lib/table';
import { Image } from 'antd';
import { IOwnerCard } from 'modules/owners/store/ownersCards/types';

import { Tooltip } from 'components/Tooltip';

/**
 * Генератор колонок для таблицы карт владельца
 *
 * @returns ColumnType<IOwnerCard>[] массив объектов колонок
 *
 * @author Горбунова Анна <telegram: @im_onyaa>
 */
export function makeColumns(): ColumnType<IOwnerCard>[] {
  return [
    {
      title: 'Владелец',
      key: 'owner',
      render: (_, card) => (
        <span className={card.isDeleted ? 'text-gray-400' : ''}>{`${card.ownerFio}, №${card.ownerId}`}</span>
      ),
    },
    {
      title: 'Провайдер',
      key: 'provider',
      dataIndex: ['bank', 'name'],
      render: (_, card) => (
        <Tooltip title={card.bank.name}>
          <Image
            className={card.isDeleted ? 'filter grayscale' : ''}
            src={card.bank.images.image}
            width={60}
            preview={false}
            alt={card.bank.name}
          />
        </Tooltip>
      ),
      width: 100,
    },
    {
      title: 'Номер карты',
      dataIndex: 'cardNumber',
      key: 'cardNumber',
      render: (_, card) => <span className={card.isDeleted ? 'text-gray-400' : ''}>{card.cardNumber}</span>,
    },
  ];
}
