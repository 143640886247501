import { Dispatch } from 'redux';

import {
  IFetchUsersParams,
  IFetchUsersReducerAction,
  UsersActionsTypes,
  ISetIsLoadingReducerAction,
  ISetPaginationReducerAction,
  IUpdateUsersReducerAction,
  IUpdateUserParams,
  ICreateUserParams,
  ICreateUserReducerAction,
} from 'modules/users/store/users/types';

import instance from 'utils/axios';
import { RequestParamsWithPagination } from 'types/requestParamsWithPagination.interface';

export function fetchUsers(params: IFetchUsersParams & RequestParamsWithPagination) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch<any>(setIsLoading(true));

      const {
        data: { data, meta },
      } = await instance.get('/users', { params });

      dispatch<IFetchUsersReducerAction>({
        type: UsersActionsTypes.FETCH_USERS,
        payload: {
          users: data,
          pagination: meta,
        },
      });
    } finally {
      dispatch<any>(setIsLoading(false));
    }
  };
}

export function updateUser(id: number, params: IUpdateUserParams) {
  return async (dispatch: Dispatch) => {
    const {
      data: { data },
    } = await instance.post('/users/' + id + '/update', {
      name: params.name,
      email: params.email,
      newPassword: params.newPassword,
      roles: params.roles,
      telegramUsername: params.telegramUsername,
    });

    dispatch<IUpdateUsersReducerAction>({
      type: UsersActionsTypes.UPDATE_USER,
      payload: data,
    });
  };
}

export function createUser(params: ICreateUserParams) {
  return async (dispatch: Dispatch) => {
    const {
      data: { data },
    } = await instance.post('/users/create', {
      name: params.name,
      email: params.email,
      password: params.password,
      roles: params.roles,
      telegramUsername: params.telegramUsername,
      agentId: params.agentId,
    });

    dispatch<ICreateUserReducerAction>({
      type: UsersActionsTypes.CREATE_USER,
      payload: data,
    });
  };
}

export function setIsLoading(value: boolean) {
  return (dispatch: Dispatch) => {
    dispatch<ISetIsLoadingReducerAction>({
      type: UsersActionsTypes.SET_IS_LOADING,
      payload: value,
    });
  };
}

export function setPagination(page: number, perPage: number) {
  return (dispatch: Dispatch) => {
    dispatch<ISetPaginationReducerAction>({
      type: UsersActionsTypes.SET_PAGINATION,
      payload: { page, perPage },
    });
  };
}
