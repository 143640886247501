import React, { useEffect, useState } from 'react';

import api from 'utils/axios';

import { useForm } from 'antd/es/form/Form';
import { PlusOutlined } from '@ant-design/icons';
import { App, Button, Drawer, Form, Input, Space, Spin } from 'antd';

import { IUser } from 'modules/users/types/IUser';
import { IAgent } from 'modules/agents/types/IAgent';
import { BackendResponse } from 'types/BackendResponse';
import { IUpdateAgentParams } from 'modules/agents/store/agents/interfaces/updateAgentParams.interface';

import { AgentsPageEditAgentModalUserCard } from 'modules/agents/pages/list/components/EditAgentModal/components/UserCard';
import { UsersPageEditDrawer } from 'modules/users/pages/list/components/EditDrawer';
import { UsersPageCreateDrawer } from 'modules/users/pages/list/components/CreateDrawer';

import { updateAgentThunk } from 'modules/agents/store/agents';
import { useAppDispatch } from 'hooks/useAppDispatch.hook';

interface Props {
  isShown: boolean;
  agent: IAgent;
  onCancel: () => void;
  isLoading: boolean;
}

export const AgentsPageEditAgentModal: React.FC<Props> = ({ isShown, agent, onCancel, isLoading }) => {
  const [form] = useForm<IUpdateAgentParams>();

  const [agentUsers, setAgentUsers] = useState([] as IUser[]);
  const [params, setParams] = useState<IUpdateAgentParams>({} as IUpdateAgentParams);
  const [isUsersLoading, setUsersIsLoading] = useState<boolean>(false);
  const [userToEdit, setUserToEdit] = useState<IUser>();
  const [isCreateUserDrawerShown, setIsCreateUserDrawerShown] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  const { message } = App.useApp();

  function handleUserEditPress(user: IUser): void {
    setUserToEdit(user);
  }

  function handleEditUserDrawerClose(isSubmitted?: boolean): void {
    setUserToEdit(undefined);
    isSubmitted && fetchAgentUsersData();
  }

  function handleCreateUserDrawerClose(isSubmitted?: boolean): void {
    setIsCreateUserDrawerShown(false);
    isSubmitted && fetchAgentUsersData();
  }

  async function handleSubmitPress(): Promise<void> {
    setUsersIsLoading(true);

    const data = {
      id: agent.id,
      name: params.name,
      parentId: agent.parentId,
    } as IUpdateAgentParams;

    const isUpdated = await dispatch(updateAgentThunk(data));

    setUsersIsLoading(false);

    if (isUpdated) {
      message.success('Агент успешно обновлен');
      form.resetFields();
      onCancel();

      return;
    }

    message.error('Не удалось обновить агента.');
  }

  async function fetchAgentUsersData() {
    setUsersIsLoading(true);

    const {
      data: { data },
    } = await api.get<BackendResponse<IUser[]>>('/users', { params: { agentId: agent.id } });

    setAgentUsers(data);
    setUsersIsLoading(false);
  }

  useEffect(() => {
    setParams({ ...params, name: agent.name, parentId: agent.parentId });

    if (!agent.id) return;

    fetchAgentUsersData();

    form.setFieldsValue({ ...agent });
  }, [agent.id]);

  function handleClose() {
    form.resetFields();
    onCancel();
  }

  return (
    <>
      <Drawer
        title={agent.id ? 'Редактирование агента №' + agent.id : 'Редактирование агента'}
        open={isShown}
        onClose={handleClose}
      >
        <Spin tip={isUsersLoading ? '' : 'Сохранение изменений'} size="large" spinning={isLoading || isUsersLoading}>
          <Form
            form={form}
            component={false}
            onValuesChange={(_, values) => setParams(values)}
            initialValues={{ name: agent.name }}
          >
            <Space className="w-full" direction="vertical" size="large">
              <Form.Item className="mb-0" name="name">
                <Input placeholder="Имя" addonBefore="Имя" className="w-full" />
              </Form.Item>
              <div className="py-2 text-center text-lg font-semibold">Пользователи</div>
              <div className="flex flex-col gap-y-2">
                {!isUsersLoading &&
                  agentUsers?.map((user) => (
                    <AgentsPageEditAgentModalUserCard key={user.id} user={user} onUserEditPress={handleUserEditPress} />
                  ))}
              </div>
              <div className="w-full flex justify-center">
                <Button
                  type="dashed"
                  onClick={() => setIsCreateUserDrawerShown(true)}
                  style={{ width: '100%' }}
                  icon={<PlusOutlined />}
                >
                  Добавить пользователя
                </Button>
              </div>
              <Button onClick={handleSubmitPress} className="w-full" type="primary">
                Сохранить изменения
              </Button>
            </Space>
          </Form>
        </Spin>
      </Drawer>
      <UsersPageEditDrawer
        isRolesEditable={false}
        user={userToEdit}
        onClose={(isSubmitted) => handleEditUserDrawerClose(isSubmitted)}
      />
      <UsersPageCreateDrawer
        agentId={agent.id}
        isRolesEditable={false}
        isShown={isCreateUserDrawerShown}
        onClose={(isSubmitted) => handleCreateUserDrawerClose(isSubmitted)}
      />
    </>
  );
};
