import { asyncThunkCreator, buildCreateSlice, PayloadAction } from '@reduxjs/toolkit';
import instance from 'utils/axios';
import { sanitizeRequestParams } from 'utils/sanitizeRequestParams';
import { RequestParamsWithPagination } from 'types/requestParamsWithPagination.interface';
import { BankAccountsState } from 'modules/bankAccounts/store/types/bankAccountsState.interface';
import { FetchBankAccountsParams } from 'modules/bankAccounts/store/types/fetchBankAccountsParams.interface';
import { AccountOutEnabledParams } from 'modules/bankAccounts/store/types/bankAccountOutEnabledParams.interface';
import { AccountInEnabledParams } from 'modules/bankAccounts/store/types/bankAccountInEnabledParams.interface';
import { CreateBankAccountParams } from 'modules/bankAccounts/store/types/createBankAccountParams.interface';
import { UpdateBankAccountParams } from 'modules/bankAccounts/store/types/updateBankAccountParams.interface';

const createSlice = buildCreateSlice({ creators: { asyncThunk: asyncThunkCreator } });

const bankAccountsSlice = createSlice({
  name: 'bankAccounts',
  initialState: {
    accounts: [],
    isLoading: false,
    pagination: {
      page: 1,
      pages: 0,
      perPage: 20,
      total: 0,
    },
  } as BankAccountsState,
  reducers: (create) => ({
    fetchBankAccounts: create.asyncThunk(
      async (params: FetchBankAccountsParams & RequestParamsWithPagination) => {
        const { data } = await instance.get('bank-accounts', { params: sanitizeRequestParams(params) });

        return data;
      },
      {
        fulfilled: (state, action) => {
          state.isLoading = false;
          state.accounts = action.payload.data;
          state.pagination = action.payload.meta;
        },
        rejected: (state) => {
          state.isLoading = false;
        },
        pending: (state) => {
          state.isLoading = true;
        },
      }
    ),
    setBankAccountInEnabled: create.asyncThunk(
      async ({ bankAccountId, inEnabled }: AccountInEnabledParams) => {
        const url = inEnabled ? 'enable-incoming' : 'disable-incoming';

        const {
          data: { data },
        } = await instance.post(`/bank-accounts/${url}`, { bankAccountId });

        return data;
      },
      {
        fulfilled: (state, action) => {
          state.isLoading = false;

          const changeBankInEnabled = action.payload;

          delete changeBankInEnabled.cardCount;
          delete changeBankInEnabled.agentId;

          state.accounts = state.accounts.map((account) => {
            return account.bankAccountId === changeBankInEnabled.bankAccountId
              ? { ...account, ...changeBankInEnabled }
              : account;
          });
        },
        rejected: (state) => {
          state.isLoading = false;
        },
        pending: (state) => {
          state.isLoading = true;
        },
      }
    ),
    setBankAccountOutEnabled: create.asyncThunk(
      async ({ bankAccountId, outEnabled }: AccountOutEnabledParams) => {
        const url = outEnabled ? 'enable-outcoming' : 'disable-outcoming';

        const {
          data: { data },
        } = await instance.post(`/bank-accounts/${url}`, { bankAccountId });

        return data;
      },
      {
        fulfilled: (state, action) => {
          state.isLoading = false;

          const changeBankOutEnabled = action.payload;

          delete changeBankOutEnabled.agentId;
          delete changeBankOutEnabled.cardCount;

          state.accounts = state.accounts.map((account) => {
            return account.bankAccountId === changeBankOutEnabled.bankAccountId
              ? { ...account, ...changeBankOutEnabled }
              : account;
          });
        },
        rejected: (state) => {
          state.isLoading = false;
        },
        pending: (state) => {
          state.isLoading = true;
        },
      }
    ),
    setBankAccountStatus: create.asyncThunk(
      async ({ bankAccountId, status }) => {
        const url = status === 'active' ? 'activate' : 'block';

        const {
          data: { data },
        } = await instance.post(`/bank-accounts/${url}`, { bankAccountId });

        return data;
      },
      {
        fulfilled: (state, action) => {
          state.isLoading = false;
          const changeBankAccountStatus = action.payload;

          delete changeBankAccountStatus.cardCount;
          delete changeBankAccountStatus.agentId;

          state.accounts = state.accounts.map((account) => {
            return account.bankAccountId === changeBankAccountStatus.bankAccountId
              ? { ...account, ...changeBankAccountStatus }
              : account;
          });
        },
        rejected: (state) => {
          state.isLoading = false;
        },
        pending: (state) => {
          state.isLoading = true;
        },
      }
    ),
    updateBankAccount: create.asyncThunk(
      async (params: UpdateBankAccountParams) => {
        const {
          data: { data },
        } = await instance.post('/bank-accounts/update', params);

        return data;
      },
      {
        fulfilled: (state, action) => {
          state.isLoading = false;

          const updateBankAccount = action.payload;

          delete updateBankAccount.agentId;
          delete updateBankAccount.cardCount;

          state.accounts = state.accounts.map((account) => {
            return account.bankAccountId === updateBankAccount.bankAccountId
              ? { ...account, ...updateBankAccount }
              : account;
          });
        },
        rejected: (state) => {
          state.isLoading = false;
        },
        pending: (state) => {
          state.isLoading = true;
        },
      }
    ),
    updateCardCount: create.reducer(
      (state, { payload }: PayloadAction<{ type: 'increment' | 'decrement'; bankAccountId: number }>) => {
        const account = state.accounts.find((account) => account.bankAccountId === payload.bankAccountId);
        if (account) {
          account.cardCount = payload.type === 'increment' ? account.cardCount + 1 : account.cardCount - 1;
        }
      }
    ),
    deleteBankAccount: create.asyncThunk(
      async ({ bankAccountId }) => {
        await instance.post('/bank-accounts/delete', { id: bankAccountId });

        return bankAccountId;
      },
      {
        fulfilled: (state, action) => {
          state.isLoading = false;

          const accountIdToDelete = action.payload;

          state.accounts = state.accounts.filter((account) => account.bankAccountId !== accountIdToDelete);
        },
        rejected: (state) => {
          state.isLoading = false;
        },
        pending: (state) => {
          state.isLoading = true;
        },
      }
    ),
    createBankAccount: create.asyncThunk(
      async (params: CreateBankAccountParams) => {
        const bankAccountParams = {
          ownerId: params.ownerId,
          bic: params.bic,
          accountType: params.accountType,
          accountNumber: params.accountNumber,
          bankId: params.bankId,
        };

        const {
          data: { data },
        } = await instance.post('bank-accounts/create', bankAccountParams);

        return data;
      },
      {
        fulfilled: (state, { payload }) => {
          state.isLoading = false;
          state.accounts.unshift({ ...payload, cardCount: 0 });
        },
        rejected: (state) => {
          state.isLoading = false;
        },
        pending: (state) => {
          state.isLoading = true;
        },
      }
    ),
  }),
});

export const bankAccountsReducer = bankAccountsSlice.reducer;

export const {
  fetchBankAccounts,
  setBankAccountInEnabled,
  setBankAccountOutEnabled,
  setBankAccountStatus,
  createBankAccount,
  updateBankAccount,
  updateCardCount,
  deleteBankAccount,
} = bankAccountsSlice.actions;
