import React from 'react';
import { Switch } from 'antd';

interface Props {
  checked: boolean;
  onChange: (status: boolean) => void;
}

export const BankAccountsListPageCardsListStatusColumn: React.FC<Props> = ({ checked, onChange }) => {
  return (
    <>
      <Switch checked={checked} onClick={onChange} />
    </>
  );
};
