import React, { useEffect, useState } from 'react';
import { Drawer, Form, Button, Spin, App } from 'antd';
import { useForm } from 'antd/lib/form/Form';

import { IUser } from 'modules/users/types/IUser';

import { updateUser } from 'modules/users/store/users/actions';
import { IUpdateUserParams } from 'modules/users/store/users/types';

import { useRolesFromServer } from 'hooks/useDataFromServer';
import { useAppDispatch } from 'hooks/useAppDispatch.hook';
import { Input } from 'components/Input';
import { LazySelect } from 'components/LazySelect';

interface Props {
  user: IUser | undefined;
  onClose: (isSubmitted?: boolean) => void;
  isRolesEditable?: boolean;
}

export const UsersPageEditDrawer: React.FC<Props> = ({ user, onClose, isRolesEditable = true }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [form] = useForm<IUpdateUserParams>();

  const { message } = App.useApp();

  const dispatch = useAppDispatch();

  useEffect(() => {
    user && form.setFieldsValue({ ...user, roles: user.roles.map((role) => role.id) });
  }, [user]);

  async function handleSubmit() {
    if (!user) return;

    const values = form.getFieldsValue();

    setIsLoading(true);

    try {
      await dispatch(updateUser(user.id, values));
    } catch {
      message.error('Не удалось обновить пользователя');
    }

    setIsLoading(false);

    message.success('Пользователь успешно обновлен!');

    onClose(true);
  }

  function handleClose() {
    form.resetFields();
    onClose();
  }

  if (!user) return null;

  return (
    <Drawer open={!!user} onClose={handleClose} title={`Редактирование пользователя №${user.id}`}>
      <Spin spinning={isLoading} tip="Идет обновление пользователя...">
        <Form form={form} initialValues={user ?? {}}>
          <Input className="mb-3" name="name" addonBefore="Имя" />
          <Input className="mb-3" name="email" addonBefore="Почта" />
          <Input
            className="mb-3"
            name="telegramUsername"
            addonBefore="Ник Telegram"
            type="text"
            autoComplete="one-time-code"
          />
          <Input className="mb-3" name="newPassword" addonBefore="Новый пароль" type="password" />
          <Form.Item hidden={!isRolesEditable} name="roles" label="Роли">
            <LazySelect mode="multiple" useDataHook={useRolesFromServer} fetchIfNoCache cacheKey="roles-select" />
          </Form.Item>
        </Form>
        <Button type="primary" onClick={handleSubmit}>
          Сохранить
        </Button>
      </Spin>
    </Drawer>
  );
};
