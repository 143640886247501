import React from 'react';
import Echo from 'laravel-echo';
import 'regenerator-runtime/runtime';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { App as AntdApp } from 'antd';
import { useNavigationType, createRoutesFromChildren, matchRoutes, useLocation } from 'react-router-dom';
import api from 'utils/axios';

import { PRESERVED_USER_TOKEN_KEY } from 'modules/auth/store/auth/reducers';

import { AppRouter } from 'navigation';

import './styles.scss';
import '../theme/customTheme.scss';
import { Spin } from 'antd';

import { ThemeProvider } from 'providers';
import { SWRConfig } from 'swr';

export const echo = new Echo({
  broadcaster: 'pusher',
  key: import.meta.env.VITE_PUSHER_APP_KEY,
  wsHost: import.meta.env.VITE_WEBSOCKET_HOST,
  wssHost: import.meta.env.VITE_WEBSOCKET_HOST,
  wsPort: import.meta.env.VITE_WEBSOCKET_PORT,
  wssPort: import.meta.env.VITE_WEBSOCKET_PORT,
  encrypted: import.meta.env.VITE_WEBSOCKET_ENCRYPTED === 'true',
  disableStats: false,
  forceTLS: import.meta.env.VITE_WEBSOCKET_TLS === 'true',
  enabledTransports: ['ws', 'wss'],
  authEndpoint: `${import.meta.env.VITE_API_URL}/api/broadcasting/auth`,
  auth: {
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem(PRESERVED_USER_TOKEN_KEY),
    },
  },
});

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      ),
    }),
  ],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 0.2,
});

const App = () => {
  return (
    <React.Suspense fallback={<Spin />}>
      <ThemeProvider>
        <AntdApp>
          <SWRConfig value={{ fetcher: (url) => api.get(url) }}>
            <AppRouter />
          </SWRConfig>
        </AntdApp>
      </ThemeProvider>
    </React.Suspense>
  );
};

export default App;
