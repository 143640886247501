import { IFilterSchema } from 'components/Filters/types/filterSchema.interface';
import {
  useBanksFromServer,
  useMerchantsFromServer,
  useOwnerStatusesFromServer,
  useAgentsFromServer,
} from 'hooks/useDataFromServer';

export const filtersSchema: IFilterSchema[] = [
  {
    title: 'ФИО владельца',
    name: 'fio',
    type: 'input',
    allowClear: true,
    width: 300,
  },
  {
    title: 'ID владельца',
    name: 'ownerId',
    type: 'input-number',
  },
  {
    title: 'Банк',
    name: 'bankId',
    type: 'lazy-select',
    allowClear: true,
    useDataHook: useBanksFromServer,
    cacheKey: 'provider-filter',
  },
  {
    title: 'Статус владельца',
    name: 'statuses',
    type: 'lazy-select',
    mode: 'multiple',
    allowClear: true,
    useDataHook: useOwnerStatusesFromServer,
    cacheKey: 'owner-status-filter',
  },
  {
    title: 'Клиент',
    name: 'customerId',
    type: 'lazy-select',
    allowClear: true,
    useDataHook: useMerchantsFromServer,
    cacheKey: 'customer-select',
  },
  {
    title: 'Работа с СБП',
    name: 'isSbp',
    type: 'select',
    allowClear: true,
    options: [
      {
        label: 'Любое',
        value: '',
      },
      {
        label: 'Да',
        value: 1,
      },
      {
        label: 'Нет',
        value: 0,
      },
    ],
  },
  {
    title: 'Баланс ОТ',
    name: 'balanceFrom',
    type: 'input-number',
    isAmountInput: true,
  },
  {
    title: 'Баланс ДО',
    name: 'balanceTo',
    type: 'input-number',
    isAmountInput: true,
  },
  {
    title: 'Тип баланса',
    name: 'isFilterByActualBalance',
    type: 'select',
    allowClear: true,
    options: [
      {
        label: 'Расчетный баланс',
        value: 0,
      },
      {
        label: 'Фактический баланс',
        value: 1,
      },
    ],
  },
  {
    title: 'Агент',
    name: 'agentIds',
    type: 'lazy-select',
    mode: 'multiple',
    allowClear: true,
    useDataHook: useAgentsFromServer,
    cacheKey: 'agents-filter',
    width: 200,
  },
];
