import React from 'react';
import { Space } from 'antd';

interface Props {}

/**
 * Фильтры для страницы "отчеты"
 *
 * @author Рябущиц Иван <telegram: @ivangeli0n>
 */
export const ReportsPageFilters: React.FC<Props> = () => {
  return <Space className="w-full" direction="vertical"></Space>;
};
