import { IFilterSchema } from 'components/Filters/types/filterSchema.interface';
import { useAgentsFromServer, useBanksFromServer } from 'hooks/useDataFromServer';
import { BankAccountStatusEnum } from 'modules/bankAccounts/enums/bankAccountStatus.enum';

export const filtersSchema: IFilterSchema[] = [
  {
    title: 'Номер счёта',
    name: 'accountNumber',
    type: 'input',
    allowClear: true,
    width: 200,
  },
  {
    title: 'Номер карты',
    name: 'cardNumber',
    type: 'input',
    allowClear: true,
    width: 200,
  },
  {
    title: 'Статус',
    name: 'status',
    type: 'select',
    allowClear: true,
    options: [
      { value: BankAccountStatusEnum.ACTIVE, label: 'Активный' },
      { value: BankAccountStatusEnum.BLOCKED, label: 'Заблокирован' },
    ],
  },
  {
    title: 'Владелец',
    name: 'ownerId',
    type: 'input-number',
  },
  {
    title: 'Агент',
    name: 'agentId',
    type: 'lazy-select',
    allowClear: true,
    useDataHook: useAgentsFromServer,
    cacheKey: 'agent-filter',
  },
  {
    title: 'Банк',
    name: 'bankId',
    type: 'lazy-select',
    allowClear: true,
    useDataHook: useBanksFromServer,
    cacheKey: 'bank-filter',
  },
];
