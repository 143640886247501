import React from 'react';

import { App, Button, Form, Modal } from 'antd';
import { InputNumber } from 'components/Input/InputNumber';

import { useForm } from 'antd/lib/form/Form';
import { BankAccountCard } from 'modules/bankAccounts/types/bankAccountCard.interface';

interface Props {
  card: BankAccountCard;
  onClose: () => void;
  onChange: (card: BankAccountCard) => void;
}

export const BankAccountPageEditCardModal: React.FC<Props> = ({ card, onClose, onChange }) => {
  const [form] = useForm<{ cardNumber: string }>();
  const { message } = App.useApp();

  async function handleSubmitPress() {
    const cardNumber = form.getFieldValue('cardNumber');

    if (cardNumber) {
      onChange({ ...card, cardNumber });
      onClose();
    } else {
      message.error('Номер карты не может быть пустым');
    }
  }

  return (
    <Modal open={true} onCancel={onClose} footer={null} width={300} closeIcon={false}>
      <p className="text-base">Номер карты</p>
      <Form form={form}>
        <Form.Item
          name="cardNumber"
          initialValue={card.cardNumber}
          rules={[
            {
              pattern: /^[a-zA-Z0-9]+$/,
              message: 'Номер карты может содержать только цифры',
            },
          ]}
        >
          <InputNumber value={card.cardNumber} className="w-full mt-2" />
        </Form.Item>
      </Form>
      <div className="flex justify-between">
        <Button onClick={onClose} className="w-[115px]">
          Отмена
        </Button>
        <Button onClick={handleSubmitPress} type="primary" className="w-[115px]">
          Сохранить
        </Button>
      </div>
    </Modal>
  );
};
