import { IFilterSchema } from 'components/Filters/types/filterSchema.interface';
import { useBanksFromServer, useMerchantsFromServer } from 'hooks/useDataFromServer';
import { OrderTypes } from 'modules/orders/enums/orderTypes.enum';

export const filtersSchema: IFilterSchema[] = [
  {
    title: 'ID заявки',
    name: 'transactionId',
    type: 'input-number',
  },
  {
    title: 'ID заявки клиента',
    name: 'customerTransactionId',
    type: 'input',
    allowClear: true,
    width: 200,
  },
  {
    title: 'Провайдеры',
    name: 'providersIds',
    type: 'lazy-select',
    mode: 'multiple',
    allowClear: true,
    useDataHook: useBanksFromServer,
    cacheKey: 'providers-filter',
    width: 200,
  },
  {
    title: 'Исключить провайдеров',
    name: 'excludeProvidersIds',
    type: 'lazy-select',
    mode: 'multiple',
    allowClear: true,
    useDataHook: useBanksFromServer,
    cacheKey: 'providers-filter',
    width: 200,
  },
  {
    title: 'Клиенты',
    name: 'customersIds',
    type: 'lazy-select',
    mode: 'multiple',
    allowClear: true,
    useDataHook: useMerchantsFromServer,
    cacheKey: 'customers-filter',
    width: 200,
  },
  {
    title: 'Тип заявки',
    name: 'type',
    type: 'select',
    allowClear: true,
    options: [
      {
        label: 'Любой',
        value: '',
      },
      {
        label: 'Входящие',
        value: OrderTypes.INCOMING,
      },
      {
        label: 'Исходящие',
        value: OrderTypes.OUTCOMING,
      },
    ],
  },
  {
    title: 'Доступна ли привязка',
    name: 'assignable',
    type: 'select',
    allowClear: true,
    options: [
      {
        label: 'Любое значение',
        value: '',
      },
      {
        label: 'Доступна',
        value: 1,
      },
      {
        label: 'Недоступна',
        value: 0,
      },
    ],
  },
  {
    title: 'Время заявки',
    name: 'onlyExpired',
    type: 'select',
    allowClear: true,
    options: [
      {
        label: 'Все заявки',
        value: '',
      },
      {
        label: 'Просроченные',
        value: 1,
      },
    ],
  },
  {
    title: 'Тип клиента',
    name: 'isHighRiskMerchant',
    type: 'select',
    allowClear: true,
    options: [
      {
        label: 'Любой',
        value: '',
      },
      {
        label: 'High risk',
        value: 1,
      },
      {
        label: 'Обменник',
        value: 0,
      },
    ],
  },
];
