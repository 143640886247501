import { IFilterSchema } from 'components/Filters/types/filterSchema.interface';
import { useAgentsFromServer, useBanksFromServer, useMerchantsFromServer } from 'hooks/useDataFromServer';
import { PaymentDirectionEnum } from 'modules/payments/enums/paymentDirection.enum';
import { PaymentStatusEnum } from 'modules/payments/enums/paymentStatus.enum';

export const paymentsFilters: IFilterSchema[] = [
  {
    title: 'ID заявки',
    name: 'orderId',
    type: 'input-number',
  },
  {
    title: 'ID платежа',
    name: 'id',
    type: 'input-number',
  },
  {
    title: 'Клиент',
    name: 'merchantId',
    type: 'lazy-select',
    allowClear: true,
    useDataHook: useMerchantsFromServer,
    cacheKey: 'customer-select',
  },
  {
    title: 'ID владельца',
    name: 'ownerId',
    type: 'input-number',
  },
  {
    title: 'Агент',
    name: 'agentId',
    type: 'lazy-select',
    allowClear: true,
    useDataHook: useAgentsFromServer,
    cacheKey: 'agents-filter',
    width: 200,
  },
  {
    title: 'Статус',
    name: 'status',
    type: 'select',
    allowClear: true,
    options: [
      {
        label: 'В обработке',
        value: PaymentStatusEnum.IN_PROGRESS,
      },
      {
        label: 'Выполнен',
        value: PaymentStatusEnum.COMPLETED,
      },
      {
        label: 'Не выполнен',
        value: PaymentStatusEnum.CANCELED,
      },
    ],
  },
  {
    title: 'Тип платежа',
    name: 'direction',
    type: 'select',
    allowClear: true,
    options: [
      {
        label: 'Входящие',
        value: PaymentDirectionEnum.IN,
      },
      {
        label: 'Исходящие',
        value: PaymentDirectionEnum.OUT,
      },
    ],
  },
  {
    title: 'Банк',
    name: 'bankId',
    type: 'lazy-select',
    allowClear: true,
    useDataHook: useBanksFromServer,
    cacheKey: 'provider-filter',
  },
  {
    title: 'Даты',
    name: 'range',
    type: 'date-picker-showtime',
    allowClear: true,
    rangeFields: ['from', 'to'],
  },
  {
    title: 'Тип даты',
    name: 'dateType',
    type: 'select',
    allowClear: true,
    width: 200,
    options: [
      {
        label: 'По дате создания',
        value: 'createdAt',
      },
      {
        label: 'По дате выполнения',
        value: 'paidAt',
      },
    ],
  },
];
