import { IMetaPagination } from 'types/IMetaPagination';
import { ReducerAction } from 'types/reducerAction.interface';

export interface IReportsState {
  reports: IReport[];
  newReportsCount: number;
  isLoading: boolean;
  pagination: IMetaPagination;
}

export interface IReport {
  id: number;
  filename: string;
  type: string;
  url: string;
  requestUserId: number;
  isSeen: boolean;
  createdAt: string;
  new?: boolean;
}

export interface IGetReportsReducerActionPayload {
  reports: IReport[];
  pagination: IMetaPagination;
}

export interface IGetReportParams {
  perPage: number;
  page: number;
}

export type IGetReportsReducerAction = ReducerAction<IGetReportsReducerActionPayload, ReportsActionTypes.GET_REPORTS>;

export type IAddReportReducerAction = ReducerAction<IReport, ReportsActionTypes.ADD_REPORT>;

export type ISetIsLoadingReportsReducerAction = ReducerAction<boolean, ReportsActionTypes.SET_IS_LOADING>;

export type IDeleteReportReducerAction = ReducerAction<number, ReportsActionTypes.DELETE_REPORT>;

export type ISetNewReportsCountReducerAction = ReducerAction<number, ReportsActionTypes.SET_NEW_REPORTS_COUNT>;

export interface IChangePaginationReportsPayload {
  page: number;
  perPage: number;
}

export type IChangePaginationReportsReducerActions = ReducerAction<
  IChangePaginationReportsPayload,
  ReportsActionTypes.CHANGE_PAGINATION
>;

export type ReportsReducerActions =
  | IGetReportsReducerAction
  | IAddReportReducerAction
  | ISetNewReportsCountReducerAction
  | IDeleteReportReducerAction
  | IChangePaginationReportsReducerActions
  | ISetIsLoadingReportsReducerAction;

export enum ReportsActionTypes {
  GET_REPORTS = 'GET_REPORTS',
  ADD_REPORT = 'ADD_REPORT',
  DELETE_REPORT = 'DELETE_REPORT',
  SET_IS_LOADING = 'SET_IS_LOADING',
  CHANGE_PAGINATION = 'CHANGE_PAGINATION',
  SET_NEW_REPORTS_COUNT = 'SET_NEW_REPORTS_COUNT',
}
