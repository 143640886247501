import React, { createContext, useContext, useState, type PropsWithChildren } from 'react';
import { darkTheme } from '../theme/themeDark';
import { lightTheme } from '../theme/themeLight';

interface IThemeContext {
  isDarkMode: boolean;
  currentTheme: any;
  choose: <T>(darkTheme: T, lightTheme: T) => T;
  setIsDarkMode: (value: boolean) => void;
}

const ThemeContext = createContext<IThemeContext>(null!);

const LOCAL_STORAGE_THEME_KEY = 'LOCAL_STORAGE_THEME_KEY';

function getThemeValueFromStorage(): boolean {
  try {
    const value = localStorage.getItem(LOCAL_STORAGE_THEME_KEY);

    if (!value) return false;

    return JSON.parse(value);
  } catch {
    return false;
  }
}

export const AppThemeProvider = ({ children }: PropsWithChildren) => {
  const [isDarkMode, setIsDarkMode] = useState<boolean>(getThemeValueFromStorage());

  function changeTheme() {
    const newValue = !isDarkMode;

    setIsDarkMode(newValue);

    localStorage.setItem(LOCAL_STORAGE_THEME_KEY, JSON.stringify(newValue));
  }

  const currentTheme = isDarkMode ? darkTheme : lightTheme;

  function choose<T>(darkTheme: T, lightTheme: T): T {
    return isDarkMode ? darkTheme : lightTheme;
  }

  return (
    <ThemeContext.Provider value={{ choose, currentTheme, isDarkMode, setIsDarkMode: changeTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

export const useTheme = () => useContext(ThemeContext);
