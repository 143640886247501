import { EditOutlined } from '@ant-design/icons';
import { Button, Space } from 'antd';
import React from 'react';

import { ProtectedComponent } from 'policies/components/ProtectedComponent';

import { Tooltip } from 'components/Tooltip';
import { permissions } from 'policies/permissions';

interface Props {
  onEditPress: () => void;
}

export const AgentsPageTableRowActions: React.FC<Props> = ({ onEditPress }) => {
  return (
    <Space size="small">
      <ProtectedComponent requiredPermissions={[permissions.agent.update]}>
        <Tooltip title="Редактировать агента">
          <Button type="primary" shape="circle" icon={<EditOutlined />} onClick={onEditPress} />
        </Tooltip>
      </ProtectedComponent>
    </Space>
  );
};
