import React, { useEffect } from 'react';

import { App, Button, notification, Table } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';

import { paymentsFilters } from './filters';

import { FetchPaymentsParams } from 'modules/payments/store/types/fetchPaymentsParams.interface';

import { useMemoFilters } from 'hooks/useMemoFilters/useMemoFilters';
import { useAppSelector } from 'hooks/useAppSelector.hook';
import { useAppDispatch } from 'hooks/useAppDispatch.hook';
import { usePaymentsColumns } from './hooks/usePaymentsColumns.hook';

import { fetchPayments, setIsLoadingPayments } from 'modules/payments/store';

import instance from 'utils/axios';
import { sanitizeRequestParams } from 'utils/sanitizeRequestParams';

import { Filters } from 'components/Filters';
import { Pagination } from 'components/Pagination';
import { PaymentsPageStatistic } from './components/Statistic';

type RawFetchPaymentsParams = Omit<FetchPaymentsParams, 'ownersIds'> & { ownerId?: number };

export const PaymentsPage: React.FC = () => {
  const { memoFilters, setMemoFilters } = useMemoFilters<RawFetchPaymentsParams>(true);

  const { message } = App.useApp();

  const {
    payments,
    isLoading,
    pagination: { page, perPage },
    pagination,
  } = useAppSelector((state) => state.payments);

  const dispatch = useAppDispatch();

  const columns = usePaymentsColumns();

  useEffect(() => {
    fetch(memoFilters);
  }, []);

  function handleFiltersChange(filters: RawFetchPaymentsParams) {
    setMemoFilters(filters);
    fetch(filters);
  }

  function handlePaginationChange(page: number, perPage: number): void {
    fetch(memoFilters, page, perPage);
  }

  async function fetch(filters: RawFetchPaymentsParams, requestPage = page, requestPerPage = perPage) {
    const params: FetchPaymentsParams & RawFetchPaymentsParams = { ...filters };

    const ownerId = params.ownerId;
    delete params.ownerId;

    if (ownerId) {
      params.ownersIds = [ownerId];
    }

    try {
      await dispatch(fetchPayments({ ...params, page: requestPage, perPage: requestPerPage })).unwrap();
    } catch {
      message.error('Не удалось получить список платежей');
    }
  }

  async function exportInExcel(filters: RawFetchPaymentsParams) {
    const params: RawFetchPaymentsParams & FetchPaymentsParams = { ...filters };

    const ownerId = params.ownerId;
    delete params.ownerId;

    if (ownerId) {
      params.ownersIds = [ownerId];
    }

    try {
      dispatch<any>(setIsLoadingPayments(true));

      await instance.get('/payment/report', {
        params: sanitizeRequestParams(params),
      });

      notification.success({
        message: 'Отчет формируется и будет доступен во вкладке "Отчеты"',
        duration: 3,
      });
    } catch {
      message.error('Не удалось сформировать отчет');
    } finally {
      dispatch<any>(setIsLoadingPayments(false));
    }
  }

  return (
    <>
      <Filters
        headerTitle="Платежи"
        filters={paymentsFilters}
        value={memoFilters}
        onSubmit={handleFiltersChange}
        customButtons={(getFilters) => (
          <>
            <Button
              className="bg-green-600 border-green-600 hover:bg-green-500"
              type="default"
              icon={<DownloadOutlined />}
              onClick={() => exportInExcel(getFilters())}
              style={{ color: 'white', border: 'none' }}
            >
              Скачать .xlsx
            </Button>
          </>
        )}
      />
      <PaymentsPageStatistic filters={memoFilters} isPaymentsLoading={isLoading} />
      <Table
        columns={columns}
        dataSource={payments}
        loading={isLoading}
        pagination={false}
        rowKey={(record) => record.id}
      />
      <Pagination pagination={pagination} isLoading={isLoading} onPaginationChange={handlePaginationChange} />
    </>
  );
};
