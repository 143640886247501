import React from 'react';

interface Props {
  backgroundColor: string;
}

export const FontColorWrapper: React.FC<Props> = ({ backgroundColor }) => {
  const calculateBrightness = (color: string) => {
    const r = parseInt(color.slice(1, 3), 16);
    const g = parseInt(color.slice(3, 5), 16);
    const b = parseInt(color.slice(5, 7), 16);
    const brightness = (r * 299 + g * 587 + b * 114) / 1000;
    return brightness;
  };

  const fontColor = calculateBrightness(backgroundColor) > 125 ? '#000000' : '#ffffff';

  return (
    <div style={{ backgroundColor, color: fontColor }} className="w-24 flex justify-center text-center mx-auto">
      <span>{backgroundColor}</span>
    </div>
  );
};
