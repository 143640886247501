import { IAgent } from 'modules/agents/types/IAgent';
import React from 'react';
import { AgentsPageTableRowActions } from 'modules/agents/pages/list/components/RowActions';
import { Table } from 'antd';
import { CrownOutlined } from '@ant-design/icons';

interface IMakeColumnsConfig {
  onEditPress: (agent: IAgent) => void;
}

export function makeColumns({ onEditPress }: IMakeColumnsConfig) {
  return [
    {
      title: 'ID',
      key: 'id',
      dataIndex: 'id',
      align: 'center',
    },
    {
      title: 'Имя',
      key: 'name',
      dataIndex: 'name',
      align: 'center',
    },
    Table.EXPAND_COLUMN,
    {
      title: 'Рефералы',
      dataIndex: 'referralsCount',
      key: 'referralsCount',
      align: 'left',
    },
    {
      title: 'Владельцы',
      key: 'parentId',
      align: 'center',
      render: (_: any, agent: IAgent) => {
        const count = agent.ownersCount ?? 0;

        const content = (
          <>
            <CrownOutlined /> Владельцы ({count})
          </>
        );

        if (count > 0) {
          return (
            <a className="text-primaryColor" href={`/owners?agentIds=%5B${agent.id}%5D`}>
              {content}
            </a>
          );
        }

        return <p className="text-gray-500">{content}</p>;
      },
    },
    {
      title: 'Действия',
      dataIndex: 'actions',
      align: 'center',
      render: (_: any, agent: IAgent) => <AgentsPageTableRowActions onEditPress={() => onEditPress(agent)} />,
    },
  ];
}
