import React, { useEffect, useState } from 'react';

import { Button, App } from 'antd';

import { PlusOutlined } from '@ant-design/icons';

import { UsersPageTable } from 'modules/users/pages/list/components/Table';
import { UsersPageEditDrawer } from 'modules/users/pages/list/components/EditDrawer';
import { UsersPageCreateDrawer } from 'modules/users/pages/list/components/CreateDrawer';

import { useMemoFilters } from 'hooks/useMemoFilters/useMemoFilters';

import { filtersSchema } from 'modules/users/pages/list/filters';

import { IFetchUsersParams } from 'modules/users/store/users/types';
import { fetchUsers, setPagination } from 'modules/users/store/users/actions';

import { IUser } from 'modules/users/types/IUser';
import { ProtectedComponent } from 'policies/components/ProtectedComponent';
import { permissions } from 'policies/permissions';
import { useAppDispatch } from 'hooks/useAppDispatch.hook';
import { useAppSelector } from 'hooks/useAppSelector.hook';
import { Pagination } from 'components/Pagination';
import { Filters } from 'components/Filters';

export const UsersPage: React.FC = () => {
  const { memoFilters, setMemoFilters } = useMemoFilters<IFetchUsersParams>();

  const [userToEdit, setUserToEdit] = useState<IUser>();
  const [isCreateDrawerShown, setIsCreateDrawerShown] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  const { message } = App.useApp();

  const {
    pagination,
    pagination: { perPage, page },
    isLoading,
  } = useAppSelector((state) => state.users);

  useEffect(() => {
    fetch();
  }, [memoFilters, page, perPage]);

  function handlePaginationChange(page: number, perPage: number) {
    dispatch(setPagination(page, perPage));
  }

  async function fetch() {
    try {
      await dispatch(fetchUsers({ ...memoFilters, page, perPage }));
    } catch {
      message.error('Не удалось получить список пользователей');
    }
  }

  function handleUserEditPress(user: IUser): void {
    setUserToEdit(user);
  }

  function handleEditDrawerClose(): void {
    setUserToEdit(undefined);
  }

  return (
    <>
      <UsersPageEditDrawer user={userToEdit} onClose={handleEditDrawerClose} />
      <UsersPageCreateDrawer isShown={isCreateDrawerShown} onClose={() => setIsCreateDrawerShown(false)} />
      <Filters
        headerTitle="Пользователи"
        filters={filtersSchema}
        value={memoFilters}
        onSubmit={setMemoFilters}
        extraHeaderButtons={() => (
          <ProtectedComponent requiredPermissions={[permissions.user.create]} fallbackComponent={<div />}>
            <Button icon={<PlusOutlined />} shape="round" type="primary" onClick={() => setIsCreateDrawerShown(true)}>
              Создать
            </Button>
          </ProtectedComponent>
        )}
      />
      <UsersPageTable onUserEditPress={handleUserEditPress} />
      <Pagination pagination={pagination} isLoading={isLoading} onPaginationChange={handlePaginationChange} />
    </>
  );
};
