export const permissions = {
  bank: {
    create: 'admin.bank.create',
    view: 'admin.bank.view',
    update: 'admin.bank.update',
    combine: 'admin.bank.combine',
    enable: 'admin.bank.enable',
    disable: 'admin.bank.disable',
  },
  owner: {
    view: 'admin.owner.view',
    viewAny: 'admin.owner.viewAny',
    create: 'admin.owner.create',
    update: 'admin.owner.update',
    updateField: {
      incomeStatus: 'admin.owner.income-status.update',
      outcomeStatus: 'admin.owner.outcome-status.update',
      status: 'admin.owner.status.update',
    },
    delete: 'admin.owner.delete',
    status: {
      update: 'admin.owner.status.update',
    },
    incomeStatus: {
      update: 'admin.owner.income-status.update',
    },
    outcomeStatus: {
      update: 'admin.owner.outcome-status.update',
    },
    balance: {
      view: 'admin.owner.balance.view',
      create: 'admin.owner.balance.create',
      update: 'admin.owner.balance.update',
    },
    merchantLink: {
      view: 'admin.owner.merchant-link.view',
      update: 'admin.owner.merchant-link.update',
    },
  },
  card: {
    view: 'admin.card.view',
    create: 'admin.card.create',
    update: 'admin.card.update',
    delete: 'admin.card.delete',
    restore: 'admin.card.restore',
  },
  bankAccount: {
    view: 'admin.bank-account.view',
    viewAny: 'admin.bank-account.viewAny',
    create: 'admin.bank-account.create',
    block: 'admin.bank-account.block',
    activate: 'admin.bank-account.activate',
    update: 'admin.bank-account.update',
    delete: 'admin.bank-account.delete',
    inOutEnabled: {
      update: 'admin.bank-account.in-out-enabled.update',
    },
  },
  sms: {
    view: 'admin.sms.view',
    export: 'admin.sms.export',
  },
  report: {
    view: 'admin.report.view',
    download: 'admin.report.download',
  },
  dispute: {
    view: 'admin.dispute.view',
    update: 'admin.dispute.update',
  },
  order: {
    view: 'admin.order.view',
    viewOwn: 'admin.order.viewOwn',
    export: 'admin.order.export',
    update: 'admin.order.update',
    smallAmount: {
      update: 'admin.order.small-amount.update',
    },
    owner: {
      update: 'admin.order.owner.update',
    },
    final: {
      update: 'admin.order.final.update',
    },
    bindings: {
      view: 'admin.order.bindings.view',
      export: 'admin.order.bindings.export',
    },
    create: 'admin.order.create',
  },
  processingOrder: {
    view: 'admin.processing-order.view',
    cancel: 'admin.processing-order.cancel',
    update: 'admin.processing-order.update',
  },
  activeOrder: {
    view: 'admin.active-order.view',
    owner: {
      update: 'admin.active-order.owner.update',
    },
    outcommming: {
      update: 'admin.active-order.outcomming.update',
    },
  },
  merchant: {
    view: 'admin.merchant.view',
    create: 'admin.merchant.create',
    update: 'admin.merchant.update',
    disable: 'admin.merchant.disable',
    enable: 'admin.merchant.enable',
    balance: {
      view: 'admin.merchant.balance.view',
      update: 'admin.merchant.balance.update',
    },
    user: {
      view: 'admin.merchant.user.view',
      update: 'admin.merchant.user.update',
    },
    tariff: {
      view: 'admin.merchant.tariff.view',
      update: 'admin.merchant.tariff.update',
    },
  },
  user: {
    view: 'admin.user.view',
    create: 'admin.user.create',
    update: 'admin.user.update',
    enable: 'admin.user.enable',
    disable: 'admin.user.disable',
  },
  config: {
    view: 'admin.config.view',
    update: 'admin.config.update',
  },
  goip: {
    view: 'admin.goip.view',
  },
  agent: {
    view: 'admin.agent.view',
    create: 'admin.agent.create',
    update: 'admin.agent.update',
    enable: 'admin.agent.enable',
    disable: 'admin.agent.disable',
  },
  partner: {
    view: 'admin.partner.view',
    update: 'admin.partner.update',
    enable: 'admin.partner.enable',
    disable: 'admin.partner.disable',
    merchants: {
      view: 'admin.partner.merchants.view',
      update: 'admin.partner.merchants.update',
    },
  },
  payment: {
    view: 'admin.payment.view',
  },
  device: {
    view: 'admin.device.view',
    assign: 'admin.device.assign',
    logout: 'admin.device.logout',
  },
  bankTransactions: {
    view: 'admin.transaction.view',
    report: 'admin.transaction.report',
  },
  module: {
    agent: 'admin.module.agent.access',
    bank: 'admin.module.bank.access',
    goip: 'admin.module.goip.access',
    owner: 'admin.module.owner.access',
    order: 'admin.module.order.access',
    processingOrder: 'admin.module.processing-order.access',
    merchant: 'admin.module.merchant.access',
    config: 'admin.module.config.access',
    report: 'admin.module.report.access',
    user: 'admin.module.user.access',
    ownerBalanceHistory: 'admin.module.owner-balance-history.access',
    card: 'admin.module.card.access',
    dispute: 'admin.module.dispute.access',
    sms: 'admin.module.sms.access',
    merchantBalance: 'admin.module.merchant-balance.access',
    partner: 'admin.module.partner.access',
    payment: 'admin.module.payment.access',
    account: 'admin.module.bank-account.access',
    device: 'admin.module.device.access',
    bankTransactions: 'admin.module.transaction.access',
  },
} as const;
