import { CustomThemeConfig } from './customThemeConfig.interface';

export const lightTheme: CustomThemeConfig = {
  token: {
    colorPrimary: '#008ecc',
    colorBgBody: '#f2f2f2',
    colorTextBase: 'rgba(0, 0, 0, 0.88)',
    colorBgBase: '#ffffff',
    colorBgContainer: '#ffffff',
    colorBgTableHeader: '#fafafa',
    colorBorderTable: '#f1f1f1',
    colorBgBlue: '#e7fcff',
    colorBorderInput: '#d9d9d9',
    colorBgLabelInput: '#fafafa',
    colorTextLight: '#000000d9',
    colorBgBalance: '#ebebeb',
  },
};
