import React, { useEffect, useState } from 'react';

import { Button, Card, Row, Typography, App } from 'antd';
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';

import { FetchPaymentsParams } from 'modules/payments/store/types/fetchPaymentsParams.interface';
import { PaymentsStatistic } from 'modules/payments/types/paymentsStatistic.interface';

import instance from 'utils/axios';

interface Props {
  filters: FetchPaymentsParams;
  isPaymentsLoading: boolean;
}

export const PaymentsPageStatistic: React.FC<Props> = ({ filters, isPaymentsLoading }) => {
  const { message } = App.useApp();

  const [statistic, setStatistic] = useState<PaymentsStatistic>();
  const [isStatisticVisible, setIsStatisticVisible] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setIsStatisticVisible(false);
  }, [filters]);

  async function fetchStatistic() {
    if (!('from' in filters) && !('to' in filters)) {
      message.error('Необходимо применить диапазон дат!');

      return;
    }

    try {
      setIsLoading(true);

      const {
        data: { data },
      } = await instance.get('/payment/statistic', {
        params: filters,
      });

      setStatistic(data);

      setIsStatisticVisible(true);
    } catch {
      message.error('Не удалось получить статистику');
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <div>
      <div hidden={!isStatisticVisible}>
        <Row className="flex mb-5">
          <Card className="mr-1">
            <div className="flex">
              <div className="mr-5">
                <Typography.Text className="text-customGray">Входящие</Typography.Text>
                <div>
                  <ArrowDownOutlined className="text-xl text-customGreen mr-2" />
                  <Typography.Text className="text-lg">
                    {statistic?.incomingCount.toLocaleString('ru-RU')}
                  </Typography.Text>
                </div>
              </div>
              <div>
                <Typography.Text className="text-customGray">Сумма</Typography.Text>
                <div>
                  <Typography.Text className="text-lg">
                    {statistic?.incomingSum.toLocaleString('ru-RU')} {'\u20BD'}
                  </Typography.Text>
                </div>
              </div>
            </div>
          </Card>
          <Card className="mr-1">
            <div className="flex">
              <div className="mr-5">
                <Typography.Text className="text-customGray">Исходящие</Typography.Text>
                <div>
                  <ArrowUpOutlined className="text-xl text-primaryColor mr-2" />
                  <Typography.Text className="text-lg">
                    {statistic?.outcomingCount.toLocaleString('ru-RU')}
                  </Typography.Text>
                </div>
              </div>
              <div>
                <Typography.Text className="text-customGray">Сумма</Typography.Text>
                <div>
                  <Typography.Text className="text-lg">
                    {statistic?.outcomingSum.toLocaleString('ru-RU')} {'\u20BD'}
                  </Typography.Text>
                </div>
              </div>
            </div>
          </Card>
        </Row>
      </div>
      <Button
        loading={isLoading}
        disabled={isPaymentsLoading}
        onClick={fetchStatistic}
        hidden={isStatisticVisible}
        type="primary"
      >
        Запросить статистику
      </Button>
    </div>
  );
};
