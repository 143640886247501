import React from 'react';
import { Space, Spin, Statistic, Tooltip } from 'antd';

import { QuestionCircleOutlined } from '@ant-design/icons';
import { Divider } from 'antd/es';
import isNumeric from 'antd/es/_util/isNumeric';
import { useTheme } from 'contexts';

interface Props {
  isLoading: boolean;
  stats: { count: number | string; sum: number | string };
}

/**
 * Статистика по транзакциям
 *
 * @author Рябущиц Иван <telegram: @ivangeli0n>
 */
export const OrdersPageStatistics: React.FC<Props> = ({ stats, isLoading }) => {
  const { isDarkMode } = useTheme();

  return (
    <Space direction="horizontal" className="h-12">
      <Tooltip title="Запрос статистики осуществялется в соответствии с заданными фильтрами">
        <QuestionCircleOutlined className="ml-2 mr-4" />
      </Tooltip>
      {
        <>
          <Spin spinning={isLoading}>
            <Space direction="horizontal" className="mb-2">
              <Statistic
                valueStyle={{
                  textAlign: 'center',
                  color: isDarkMode ? 'white' : 'black',
                  fontSize: '22px',
                }}
                title="Кол-во заявок"
                value={stats?.count}
                groupSeparator=" "
              />
              <Divider
                type="vertical"
                style={{
                  height: '50px',
                  background: isDarkMode ? 'white' : 'black',
                }}
              />
              <Statistic
                valueStyle={{
                  textAlign: 'center',
                  color: isDarkMode ? 'white' : 'black',
                  fontSize: '22px',
                }}
                title="Сумма заявок"
                value={isNumeric(stats.sum) ? Number(stats.sum).toFixed(0) : stats.sum}
                groupSeparator=" "
              />
            </Space>
          </Spin>
        </>
      }
    </Space>
  );
};
