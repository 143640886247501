import { OwnerLinkedByEnum } from 'modules/owners/types/IOwner';
import { PaymentRequisitesType } from 'enums/paymentRequisitesType.enum';
import { OrderStatusEnum } from 'modules/orders/enums/orderStatus.enum';
import { OrderTypes } from 'modules/orders/enums/orderTypes.enum';
import { OrderAssigningStatus } from 'modules/orders/enums/orderAssigningStatus.enum';

export interface Order {
  id: number;
  customerTransactionId: string;
  timeOrder: string;
  timeExecuteOrder: string;
  customer: {
    id: number;
    name: string;
    isHighRisk: boolean;
  };
  amount: number;
  provider?: OrderBank;
  status: {
    id: OrderStatusEnum;
    name: string;
  };
  type: OrderTypes;
  realSum: number;
  customerName?: string;
  customerCard: string;
  customerPhone: string;
  owners: OrderOwner[];
  createdAt: string;
  disputeIds: number[];
  assigningStatus: OrderAssigningStatus;
  linkedOperatorId?: number;
  isSbp: boolean;
  sumTransferred?: OrderPayment[];
  account?: string;
}

export interface OrderBank {
  id: number;
  name: string;
  currencyCode: string;
  image?: string;
}

export interface OrderOwner {
  ownerId: number;
  amount: number;
  cardNumber: string;
  cardId?: number;
  linkedBy: OwnerLinkedByEnum;
  providerCommission: number;
  fio?: string;
  receiptUrls: string[];
  bankAccountId: number;
  bankAccountNumber?: string;
  bic?: string;
  bankId: number;
  requisitesType?: PaymentRequisitesType;
  paidAt?: string;
  checkStatuses: {
    sms: boolean;
    receipt: ReceiptMatchStatus;
  };
}

export interface OrderPayment {
  ownerId: number;
  sum: number;
  status: OrderPaymentStatus;
  providerCommission: number;
}

export enum ReceiptMatchStatus {
  IN_PROGRESS = 1,
  MATCHED = 2,
  NOT_MATCHED = 3,
  UNRECOGNIZED = 4,
}

export enum OrderPaymentStatus {
  UNKNOWN = 0,
  IN_PROGRESS = 1,
  COMPLETED = 2,
  CANCELED = 3,
}

export const orderPaymentStatusTitles = {
  [OrderPaymentStatus.UNKNOWN]: 'статус неизвестен ❌',
  [OrderPaymentStatus.IN_PROGRESS]: 'ожидает перевода 🔄',
  [OrderPaymentStatus.COMPLETED]: 'переведено ✅',
  [OrderPaymentStatus.CANCELED]: 'отменен 🚫',
} as const;
