import { FetchMerchantsPayload } from './interfaces/fetchMerchantsPayload.interface';
import { Merchant } from 'modules/merchants/types/merchant.interface';
import { ReducerAction } from 'types/reducerAction.interface';

export interface IChangePagePayload {
  page: number;
  perPage: number;
}

export enum MerchantsActionTypes {
  FETCH_MERCHANTS = 'FETCH_MERCHANTS',
  CREATE_MERCHANT = 'CREATE_MERCHANT',
  DELETE_MERCHANT = 'DELETE_MERCHANT',
  SET_LOADING = 'SET_LOADING',
  CHANGE_PAGE = 'CHANGE_PAGE',
}

export type FetchMerchantsReducerAction = ReducerAction<FetchMerchantsPayload, MerchantsActionTypes.FETCH_MERCHANTS>;

export type DeleteMerchantReducerAction = ReducerAction<number, MerchantsActionTypes.DELETE_MERCHANT>;

export type CreateMerchantReducerAction = ReducerAction<Merchant, MerchantsActionTypes.CREATE_MERCHANT>;

export type SetLoadingReducerAction = ReducerAction<boolean, MerchantsActionTypes.SET_LOADING>;

export type ChangePageMerchantReducerAction = ReducerAction<IChangePagePayload, MerchantsActionTypes.CHANGE_PAGE>;

export type MerchantReducerActions =
  | FetchMerchantsReducerAction
  | CreateMerchantReducerAction
  | DeleteMerchantReducerAction
  | SetLoadingReducerAction
  | ChangePageMerchantReducerAction;
