import React from 'react';
import { ColumnType } from 'antd/lib/table';
import { useMemo } from 'react';
import { BankAccountBank, BankAccount } from 'modules/bankAccounts/types/bankAccount.interface';
import { Button, Image, Table, Tooltip } from 'antd';
import { permissions } from 'policies/permissions';

import { BankAccountsListPageInEnabledColumn } from '../components/InEnabledColumn';
import { BankAccountsListPageOutEnabled } from '../components/OutEnabledColumn';
import { BankAccountsListPageStatusColumn } from '../components/StatusColumn';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { useUserPermissions } from 'policies/hooks/useUserPermissions';
import { maskAccountNumber } from 'utils/maskAccountNumber.util';

interface Config {
  onEditClick: (bankAccount: BankAccount) => void;
  onDeleteClick: (bankAccount: BankAccount) => void;
}

export function useBankAccountsColumns({ onEditClick, onDeleteClick }: Config): ColumnType<BankAccount>[] {
  const { userCan } = useUserPermissions();

  return useMemo<ColumnType<BankAccount>[]>(
    () => [
      {
        title: 'Банк',
        key: 'bankId',
        dataIndex: 'bank',
        render: (bank: BankAccountBank) => (
          <Tooltip title={bank?.name}>
            <Image src={bank?.image} width={60} preview={false} alt={bank?.name} />
          </Tooltip>
        ),
      },
      {
        title: 'Номер счёта',
        key: 'accountNumber',
        dataIndex: 'accountNumber',
        render: (accountNumber: string) => maskAccountNumber(accountNumber),
      },
      Table.EXPAND_COLUMN,
      {
        title: 'Карты',
        key: 'cardCount',
        dataIndex: 'cardCount',
      },
      {
        title: 'Баланс',
        key: 'amount',
        dataIndex: 'amount',
        render: (amount: number) => amount?.toLocaleString('ru-RU') ?? '',
      },
      {
        title: 'Входящие заявки',
        key: 'inEnabled',
        dataIndex: 'inEnabled',
        align: 'center',
        render: (_: any, account: BankAccount) => <BankAccountsListPageInEnabledColumn account={account} />,
      },
      {
        title: 'Исходящие заявки',
        key: 'outEnabled',
        dataIndex: 'outEnabled',
        align: 'center',
        render: (_: any, account: BankAccount) => <BankAccountsListPageOutEnabled account={account} />,
      },
      {
        title: 'Статус',
        key: 'status',
        width: 120,
        render: (_: any, account: BankAccount) => <BankAccountsListPageStatusColumn account={account} />,
      },
      {
        title: 'ID владельца',
        key: 'ownerId',
        dataIndex: 'ownerId',
        align: 'center',
        render: (ownerId: number) => (
          <a className="text-primaryColor" href={`/owners?ownerId=${ownerId}`}>
            {ownerId}
          </a>
        ),
      },
      {
        title: 'Агент',
        key: 'agentId',
        dataIndex: 'agentId',
        render: (agentId) =>
          agentId && (
            <a className="text-primaryColor" href={`/agents?id=${agentId}`}>
              {agentId}
            </a>
          ),
      },
      {
        title: '',
        key: 'actions',
        dataIndex: 'actions',
        render: (_: any, account: BankAccount) => (
          <div className="flex items-center justify-center">
            <Tooltip title="Редактировать счёт">
              <Button
                onClick={() => onEditClick(account)}
                shape="circle"
                type="primary"
                icon={<EditOutlined />}
                disabled={!userCan(permissions.bankAccount.update)}
                className="mr-4"
              />
            </Tooltip>
            <Tooltip title="Удалить счёт">
              <Button
                onClick={() => onDeleteClick(account)}
                shape="circle"
                type="primary"
                danger
                icon={<DeleteOutlined />}
                disabled={!userCan(permissions.bankAccount.delete)}
              />
            </Tooltip>
          </div>
        ),
      },
    ],
    []
  );
}
