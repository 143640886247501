import React, { useEffect, useMemo } from 'react';

import { Link, useLocation, useNavigate } from 'react-router-dom';

import { refreshMe } from 'modules/auth/store/auth/actions';

import { privateRoutes } from 'navigation/routes/privateRoutes';

import { haveAccessTo } from 'policies/methods/haveAccessTo';

import { Menu, Space } from 'antd';
import { LayoutSidebarDisputesCounter } from './components/DisputesCounter';
import { ItemType, MenuItemGroupType } from 'antd/es/menu/hooks/useItems';

import { useAppDispatch } from 'hooks/useAppDispatch.hook';
import { useAppSelector } from 'hooks/useAppSelector.hook';

import SubMenu from 'antd/es/menu/SubMenu';

interface Props {}

interface AntMenuItemType {
  key: string;
  label: string;
  icon?: React.ReactNode;
  children?: AntMenuItemType[];
}

export const LayoutSidebar: React.FC<Props> = () => {
  const location = useLocation();
  const history = useNavigate();
  const dispatch = useAppDispatch();

  const { user } = useAppSelector((state) => state.auth);

  const activeItemId = location.pathname;

  const items = useMemo<ItemType[]>(() => {
    if (!user) return [];

    return (
      privateRoutes
        .map((route) => {
          if (route.showInSidebar === false) return null;

          const childrenRoutes =
            route.subroutes?.map((subroute) => {
              if (!haveAccessTo(subroute.permissions, user)) return null;

              return {
                label: subroute.title,
                key: route.path + subroute.path,
              };
            }) ?? [];

          const children = [
            haveAccessTo(route.permissions, user) && {
              label: route.title,
              key: route.path,
            },
            ...childrenRoutes,
          ].filter(Boolean);

          if (children.length === 0) {
            return null;
          }

          let icon = route.icon;

          if (route.path === '/order_disputes') {
            icon = (
              <Space>
                {route.icon}
                <LayoutSidebarDisputesCounter />
              </Space>
            );
          }

          return {
            label: route.title,
            key: route.subroutes ? route.title + route.path : route.path,
            children: route.subroutes ? children : undefined,
            icon,
          };
        })
        // Typescript по какой-то причине (баг?) не учитывает какие типы filter отфильтровывает
        // из массива и подолжает генерировать тип (SidebarMenuItem | null)[], так что приходится
        // вручную приводить к нужному типу
        .flat()
        .filter((item) => item !== null)
    );
  }, []);

  // TODO: Разобраться с типизацией в этом эффекте
  useEffect(() => {
    dispatch<any>(refreshMe());

    let titlePage: string;

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    titlePage = items.find((item) => item.key === location.pathname)?.label ?? '';

    if (!titlePage) {
      items.forEach((item) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const title = (item as MenuItemGroupType).children?.find((sub) => sub?.key?.endsWith(location.pathname))?.label;

        if (title !== undefined) {
          titlePage = title;
        }
      });
    }

    document.title = titlePage ?? 'Админ панель';
  }, [activeItemId, items]);

  function handleChangeMenu({ key }: { key: string }) {
    history(key);
  }

  // TODO: Разобраться с типизацией в этом компоненте, заменить кастомную типизацию на типизацию библиотеки
  const renderMenuItems = (items: AntMenuItemType[]) => {
    return items.map((item: AntMenuItemType) => {
      if (!item) {
        return null;
      }

      if (item.children) {
        return (
          <SubMenu key={item.key} title={item.label} icon={item.icon}>
            {renderMenuItems(item.children)}
          </SubMenu>
        );
      }

      return (
        <Menu.Item key={item.key} icon={item.icon}>
          <Link to={item.key}>{item.label}</Link>
        </Menu.Item>
      );
    });
  };

  return (
    <div style={{ width: 200 }}>
      <Menu
        defaultSelectedKeys={[activeItemId]}
        defaultOpenKeys={[]}
        mode="inline"
        theme="light"
        onClick={handleChangeMenu}
      >
        {renderMenuItems(items as AntMenuItemType[])}
      </Menu>
    </div>
  );
};
