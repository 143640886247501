import React from 'react';
import { useMemo } from 'react';
import { ColumnType } from 'antd/lib/table';
import { Device } from 'modules/devices/types/device.interface';
import { Button, Popconfirm, Space } from 'antd';
import { DisconnectOutlined, UserAddOutlined } from '@ant-design/icons';
import { Tooltip } from 'components/Tooltip';
import dayjs from 'dayjs';
import { DEFAULT_DATETIME_STRING_FORMAT } from 'constants/dates';
import Link from 'antd/lib/typography/Link';
import { useNavigate } from 'react-router-dom';
import { ProtectedComponent } from 'policies/components/ProtectedComponent';
import { permissions } from 'policies/permissions';

interface Config {
  onAssignOwnerPress: (device: Device) => void;
  onCloseSessionPress: (device: Device) => void;
}

export function useTableColumns({ onAssignOwnerPress, onCloseSessionPress }: Config) {
  const navigate = useNavigate();

  return useMemo<ColumnType<Device>[]>(() => {
    return [
      {
        dataIndex: 'id',
        key: 'id',
        title: 'ID',
      },
      {
        dataIndex: 'uuid',
        key: 'uuid',
        title: 'UUID',
      },
      {
        key: 'owner',
        render: (_, { owner }) => (
          <Link onClick={() => navigate('/owners?ownerId=' + owner?.id)} disabled={!owner}>
            {owner?.name ? `${owner.name} (${owner.id})` : 'Не привязан'}
          </Link>
        ),
        title: 'Владелец',
      },
      {
        key: 'bankAccounts',
        title: 'Счета',
        render: (_, { id }) => <Link onClick={() => navigate('/owners/bank-accounts?deviceId=' + id)}>Счета</Link>,
      },
      {
        key: 'agent',
        render: (_, { agent }) => (
          <Link onClick={() => navigate('/agents?id=' + agent?.id)} disabled={!agent}>
            {agent?.name ? `${agent.name} (${agent.id})` : 'Не привязан'}
          </Link>
        ),
        title: 'Агент',
      },
      {
        key: 'seenAt',
        dataIndex: 'seenAt',
        title: 'Последняя активность',
        render: (seenAt: string) => dayjs(seenAt).format(DEFAULT_DATETIME_STRING_FORMAT),
      },
      {
        key: 'actions',
        title: 'Действия',
        render: (_, device) => (
          <Space>
            <ProtectedComponent requiredPermissions={[permissions.device.assign]}>
              <Tooltip title="Привязать владельца">
                <Button
                  shape="circle"
                  type="primary"
                  icon={<UserAddOutlined />}
                  onClick={() => onAssignOwnerPress(device)}
                  disabled={!device.agent}
                />
              </Tooltip>
            </ProtectedComponent>
            <ProtectedComponent requiredPermissions={[permissions.device.logout]}>
              <Popconfirm
                title="Вы уверены, что хотите закончить сессию на устройстве владельца?"
                onConfirm={() => onCloseSessionPress(device)}
              >
                <Tooltip title="Закончить сессию">
                  <Button shape="circle" danger icon={<DisconnectOutlined />} />
                </Tooltip>
              </Popconfirm>
            </ProtectedComponent>
          </Space>
        ),
      },
    ];
  }, []);
}
