import React, { useCallback } from 'react';
import { Switch } from 'antd';

import { toggleBank } from 'modules/banks/store/banks/actions';

import { IBank } from 'modules/banks/types/IBank';
import { useAppDispatch } from 'hooks/useAppDispatch.hook';

interface Props {
  bank: IBank;
}

export const BanksPageTableCheckSwitchColumn: React.FC<Props> = ({ bank }) => {
  const dispatch = useAppDispatch();

  const handleChange = useCallback(() => {
    const params = {
      id: bank.id,
      enabled: bank.enabled,
    };
    dispatch(toggleBank(params));
  }, [bank]);

  return <Switch checked={bank.enabled} onChange={handleChange} />;
};
