import React from 'react';
import { ColumnType } from 'antd/lib/table';
import { useMemo } from 'react';
import { Payment } from 'modules/payments/types/payment.interface';
import { Image, Typography } from 'antd';
import { RequisiteTypeEnum, requisiteTypesToTitlesMap } from 'modules/payments/enums/paymentRequisiteType.enum';
import { ArrowDownOutlined, ArrowUpOutlined, CheckOutlined, CloseOutlined, AuditOutlined } from '@ant-design/icons';
import { PaymentStatusEnum } from 'modules/payments/enums/paymentStatus.enum';
import { PaymentDirectionEnum } from 'modules/payments/enums/paymentDirection.enum';
import TransactionOutlined from 'assets/svg/TransactionOutlined.svg';
import dayjs from 'dayjs';
import { Tooltip } from 'components/Tooltip';
import { DEFAULT_DATETIME_STRING_FORMAT } from 'constants/dates';
import { Link } from 'react-router-dom';

export function usePaymentsColumns(): ColumnType<Payment>[] {
  return useMemo(
    () => [
      {
        title: 'ID платежа',
        key: 'id',
        dataIndex: 'id',
        render: (id, { orderId }) => {
          return (
            <div className="flex items-center space-x-1">
              <p>{id}</p>
              <Tooltip title={`ID заявки: ${orderId}`}>
                <Link className="text-primaryColor" to={`/transactions_static?id=${orderId}`}>
                  <AuditOutlined />
                </Link>
              </Tooltip>
            </div>
          );
        },
      },
      {
        title: 'ID владельца',
        key: 'ownerId',
        dataIndex: 'ownerId',
      },
      {
        title: 'Сумма операции',
        key: 'amount',
        dataIndex: 'amount',
        render: (amount) => amount?.toLocaleString('ru-RU') ?? '',
      },
      {
        title: 'Тип',
        key: 'type',
        dataIndex: 'type',
        align: 'center',
        render: (type: PaymentDirectionEnum) => {
          switch (type) {
            case PaymentDirectionEnum.IN:
              return <ArrowDownOutlined className="text-xl text-customGreen" />;
            case PaymentDirectionEnum.OUT:
              return <ArrowUpOutlined className="text-xl text-primaryColor" />;
            default:
              return null;
          }
        },
      },
      {
        title: 'Статус',
        key: 'status',
        dataIndex: 'status',
        align: 'center',
        render: (status: PaymentStatusEnum) => {
          switch (status) {
            case PaymentStatusEnum.COMPLETED:
              return <CheckOutlined className="text-xl text-customGreen" />;
            case PaymentStatusEnum.CANCELED:
              return <CloseOutlined className="text-xl text-customRed" />;
            case PaymentStatusEnum.IN_PROGRESS:
              return <img src={TransactionOutlined} className="text-xl m-auto" alt="Transaction Icon" />;
          }
        },
      },
      {
        title: 'Создан',
        key: 'createdAt',
        dataIndex: 'createdAt',
        render: (value: string) => dayjs(value).format(DEFAULT_DATETIME_STRING_FORMAT),
      },
      {
        title: 'Выполнен',
        key: 'paidAt',
        dataIndex: 'paidAt',
        render: (paidAt: string) => {
          if (!paidAt) {
            return '';
          }
          return dayjs(paidAt).format(DEFAULT_DATETIME_STRING_FORMAT);
        },
      },
      {
        title: 'Банк',
        key: 'bankId',
        align: 'center',
        dataIndex: 'bank',
        render: (bank: { name: string; logo: string }) => (
          <Tooltip title={bank?.name}>
            <Image src={bank?.logo} width={60} preview={false} alt={bank?.name} />
          </Tooltip>
        ),
      },
      {
        title: 'Реквизиты',
        key: 'requisite',
        dataIndex: 'requisite',
      },
      {
        title: 'Тип реквизитов',
        key: 'requisiteType',
        dataIndex: 'requisiteType',
        render: (requisiteType: RequisiteTypeEnum) => {
          if (!requisiteType) {
            return '';
          }
          return <Typography.Text>{requisiteTypesToTitlesMap[requisiteType]}</Typography.Text>;
        },
      },
      {
        title: 'Агент',
        key: 'agent',
        dataIndex: 'agent',
        render: (agent) => {
          return (
            <a className="text-primaryColor" href={`/agents?name=${agent?.name}`}>
              {agent?.name}
            </a>
          );
        },
      },
    ],
    []
  );
}
